import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import RemoveItemModal from '../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  surveyId?: string
  surveyStatus?: string
  refetch?: any
}

interface ItemMetaData {
  statementCount: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({ color, id, surveyId,surveyStatus, refetch }: ItemCtaData) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteFactor, { loading}] = useMutation(DELETE_FACTOR, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      refetch()
      setIsOpen(false)
    }
  })

  const handleCloseRemoveFactor = () => {
    setIsOpen(false)
  }

  const handleRemoveFactor = (id: string) => {
    deleteFactor({
      variables:{
        id: id
      }
    })
  }

  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveFactor}
        handleConfirm={() => handleRemoveFactor(id)}
        loading={loading}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          {loading ? 'Removing Dimension...' : 'Remove Dimension' }
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/dimension/${id}`)}
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ statementCount }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">{statementCount}</p>
    </div>
  )
}

const DELETE_FACTOR = gql`
  mutation deleteFactor($id: String!) {
    deleteFactor(id: $id) {
      status
      message
    }
  }
`
