import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Color } from '../../../color.enum'
import { language } from '../../../common/i18n'
import { User } from '../../../common/types'
import Bar from '../../../components/Bar'
import Card, { CardVariant } from '../../../components/Card'
import Loader from '../../../components/Loader/Loader'
import Modal from '../../../components/Modal/Modal'
import Radar, { DimensionProps } from '../../../components/Radar'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import client from '../../../contentful'
import RichTextRenderer from '../../../modules/RichTextRenderer'
import { RESULTS_CONTENT_QUERY } from './contentful-queries'
import PDFDownload from './PDFReport'
import { testResults } from './results.api'
import { BarChart } from '../../../components/BarChart/BarChat'
import { BarSize } from '../../../components/pdf/Bar/Bar'

interface ResultsContainerProps extends RouteComponentProps {
  user?: User
  respondentId: string
  assignmentId: string
}

const AssignmentResults: React.FC<ResultsContainerProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(testResults.results[0])
  const [radarImageBuffer, setRadarImageBuffer] = useState<ArrayBuffer>()
  const [barchartImageBuffer, setBarChatImageBuffer] = useState<ArrayBuffer>()

  const { data: contentfulData } = useQuery(RESULTS_CONTENT_QUERY, {
    client,
    variables: { contentIdentifier: 'app-results-message-organisation' }
  })

  const { loading, data } = useQuery(GET_ASSIGNMENT_RESULTS, {
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const archetypeScore = (dimension: any) => {
    let score = 0
    dimension.map((f: any) => (score += f.score))
    return Math.round(score / dimension.length)
  }

  const archetypes = useMemo<Array<ArchetypeProps>>(() => {
    const a = []
    if (data) {
      for (const res of data.assignmentBasicResults.results) {
        const types = []
        types.push({
          name: res.factorType,
          description: res.factorTypeDescription ?? '',
          color: res.color as Color,
          dimensions: res.responses,
          score: archetypeScore(res.responses)
        })

        const dimensions = []
        for (const r of res.responses) {
          dimensions.push({
            score: r.score,
            name: r.factor.headline || r.factor.name,
            description: r.factor.description ?? ''
          })
        }
        a.push({
          types,
          dimensions,
          color: res.color as Color,
          name: res.factorType,
          description: res.factorTypeDescription,
          factorTypeReportDescription: res.factorTypeReportDescription
        })
      }
    }
    return a
  }, [data])

  if (loading) return <Loader color={Color.PURPLE} />
  if (!data) return null

  const onClick = (activeItem: any) => {
    setIsOpen(true)
    setActiveItem(activeItem)
  }

  const { headline, content } = contentfulData?.contentCollection?.items?.[0] ?? {}

  return (
    <>
      <main>
        <div className="page-container-lg">
          <Helmet>
            <title>{language.en.orgOwner.assignment.results.title}</title>
            <meta name="description" content={language.en.orgOwner.assignment.results.description} />
            <meta name="robots" content="noindex" data-react-helmet="true" />
          </Helmet>
          <Card
            variant={CardVariant.STANDARD}
            subtitle={props?.user?.organization?.name}
            headline={headline}
            media={<Radar setImageBuffer={setRadarImageBuffer} archetypes={archetypes.reverse()} />}
          >
            {content ? (
              <div className="mt-10 prose">
                <RichTextRenderer content={content} />
              </div>
            ) : null}
            {archetypes.map((a, index) => (
              <FactorResult
                className={cn('mt-10', { 'mb-6': index === data.assignmentBasicResults.results.length - 1 })}
                type={a.name}
                description={a.description}
                dimensions={a.types ?? []}
                color={a.color}
                onClick={onClick}
              />
            ))}
            {radarImageBuffer && (
              <PDFDownload
                key="org-report"
                assignmentId={props.assignmentId}
                radarImage={radarImageBuffer}
                archetypes={archetypes.reverse()}
                statementCount={data.assignmentBasicResults.statementCount}
                isIndividual={false}
                reportName={data?.assignmentBasicResults?.assignmentName || undefined}
                barChartImage={barchartImageBuffer}
                userRole={props?.user?.role}
              />
            )}
          </Card>
          <Card
            media={
              <div className="bg-brand-blue-light hide-element">
                <BarChart setImageBuffer={setBarChatImageBuffer} archetypes={archetypes} />
              </div>
            }
          ></Card>
        </div>
      </main>

      <Modal isModalOpen={isOpen} title={activeItem?.name ?? ''} onClose={() => setIsOpen(false)}>
        {activeItem?.description ? <div className="prose">{activeItem.description}</div> : null}
        <div className="my-4">
          {activeItem.dimensions?.map((dim) => {
            return (
              <>
                <Bar
                  key={dim.name}
                  headline={dim?.factor?.headline ?? ''}
                  scorePercentage={dim.score}
                  color={activeItem.color as Color}
                  className="mb-6"
                  hoverText={null}
                  size={BarSize.SMALL}
                />
              </>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

interface FactorResultProps {
  type: string
  description?: string
  color: Color
  dimensions?: DimensionProps[]
  className?: string
  onClick: (dim: DimensionProps) => void
}

const FactorResult = (props: FactorResultProps) => {
  return (
    <div className={props.className}>
      <div className="my-4">
        {props?.dimensions?.map((dim) => {
          return (
            <>
              <Bar
                key={dim.name}
                headline={dim.name}
                scorePercentage={dim.score}
                color={props.color}
                className="mb-6"
                onClick={() => props.onClick(dim)}
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default AssignmentResults

const GET_ASSIGNMENT_RESULTS = gql`
  query AssignmentResults($assignmentId: String!) {
    assignmentBasicResults(assignmentId: $assignmentId) {
      assignmentName
      results {
        factorType
        factorTypeDescription
        factorTypeReportDescription
        color: factorTypeColor
        responses {
          factor {
            name
            description
            headline
          }
          score
        }
      }
      statementCount
    }
  }
`
