import React, { useEffect } from 'react'
import { Table, Box } from '@plusplusminus/plusplusdash'
import { useSort } from '../../hooks/useSort'
import { useNavigate } from '@reach/router'
import TablePagination from '../../components/TablePagination'
import { usePagination } from '../../hooks/usePagaination'
import Button from '../../components/Button'
import { Color } from '../../color.enum'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import { Search } from '../../components/Search/Search'
import { language } from '../../common/i18n'
import { useFormatRoles } from '../../hooks/useFormatRole'

const searchParams = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    options: []
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    options: []
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    options: []
  },
  {
    name: 'role',
    label: 'Role',
    type: 'select',
    options: [
      {
        id: 'admin',
        name: 'Admin'
      },
      {
        id: 'peer',
        name: 'Peer'
      },
      {
        id: 'organization_owner',
        name: 'Organisation owner'
      },
      {
        id: 'respondent',
        name: 'Respondent'
      }
    ]
  },
  {
    name: 'organization',
    label: 'Organisation',
    type: 'text',
    options: []
  }
]

export interface Items {
  id: string
  firstName: string
  lastName: string
  email: string
  organization: any
  organizations: any
  role: string
  isSuperAdmin: boolean
  isPeer:boolean
  isOrgLeader: boolean
}

interface PaginationArgs {
  currentPage: number
  totalPages: number
  hasNextPage: boolean
  hasPrevPage: boolean
}

interface ListItems {
  items: Array<Items>
  refetch: (value: any) => void
  pagination?: PaginationArgs
  loading: boolean
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'firstName', isSortable: true },
  { label: 'Email Address', key: 'email', isSortable: true },
  { label: 'Organisation', key: 'organization', isSortable: true },
  { label: 'Role', key: 'role', isSortable: true },
  { label: '', key: 'id', isSortable: false }
]

const UserList: React.FC<ListItems> = ({ items, refetch, pagination }) => {
  const [sortField, direction, sortCallback] = useSort()
  const [page, offset, limit, nextPage, prevPage] = usePagination(25)

  const [formattedRole] = useFormatRoles()

  useEffect(() => {
    refetch({ pagination: { limit, sort: 'ACS', page } })
  }, [page])

  useEffect(() => {
    if (sortField && direction) {
      refetch({ sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') } })
    }
  }, [sortField, direction])

  const onSearch = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === '') {
        delete data[key]
      }
    })

    if (sortField && direction) {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') },
        input: data
      })
    } else {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        input: data
      })
    }
  }

  const onClear = () => {
    refetch({ pagination: { limit, sort: 'ACS', page: 1 }, input: {} })
  }

  const getOrg = (item: any) => {
    if (item?.organization) {
      return item.organization.name
    } else if (item?.organizations.length) {
      return item.organizations[0].name
    } else {
      return '-'
    }
  }

  const navigate = useNavigate()

  return (
    <Box>
      <Search onSearch={onSearch} searchParams={searchParams} onClear={onClear} />
      <Table
        shape={TABLE_SHAPE}
        activeSort={direction === 'desc' ? sortField?.slice(1) : sortField}
        sortDirection={direction}
        sortCallback={sortCallback}
      >
        {items?.map((item) => {
          return (
            <Table.Row
              key={item.id}
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/organisation/users/${item.id}`)}
            >
              <Table.Cell className="text-gray-900">
                {item.firstName} {item.lastName}
              </Table.Cell>
              <Table.Cell className="text-gray-900">{item.email}</Table.Cell>
              <Table.Cell className="text-gray-900">{getOrg(item)}</Table.Cell>
              <Table.Cell className="text-gray-900">{formattedRole(item)}</Table.Cell>              
              <Table.Cell className="text-gray-900">
                <div className="flex space-x-3">
                  {item?.id && (
                    <Button
                      variant={ButtonVariant.PLAIN}
                      color={Color.ORANGE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(`/dashboard/organisation/users/${item.id}`)}
                      iconRight="arrowRight"
                    >
                      {language.en.admin.dashBoard.buttonText.viewUser}
                    </Button>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      {pagination?.currentPage && (
        <TablePagination
          page={pagination?.currentPage}
          limit={limit}
          nextPage={nextPage}
          prevPage={prevPage}
          totalPages={pagination?.totalPages}
          hasPrevPage={pagination?.hasPrevPage}
          hasNextPage={pagination?.hasNextPage}
        />
      )}
    </Box>
  )
}

export default UserList
