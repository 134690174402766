import { gql } from '@apollo/client'

export const USER_QUERY = gql`
  query user {
    me {
      id
      organization {
        id
        name
      }
      peer {
        id
        name
      }
    }
  }
`
export const GET_PEER_ORGANISATIONS = gql`
  query organisationsByName($peerId: String, $name: String) {
    organizationsByName(peerId: $peerId, name: $name) {
      id
      name
      createdAt
      owner {
        id
        firstName
        lastName
        email
      }
      assignment {
        id
      }
    }
  }
`

export const GET_ORG_BY_ID = gql`
  query getOneOrg($id: String!) {
    organization(id: $id) {
      internalId
      country
      foundedDate
      id
      name
      createdAt
      owner {
        id
        firstName
        lastName
        email
      }
      assignment {
        id
      }
      peer {
        name
      }
    }
  }
`

export const GET_A_PEER = gql`
  query GetPeer($id: String!) {
    getPeer(id: $id) {
      id
      createdAt
      updatedAt
      name
      country
      user {
        firstName
        lastName
        organization {
          name
        }
        role
        email
      }
    }
  }
`

export const GET_PEER_OWNER_DETAILS = gql`
  query GetPeerOwner($peerId: String!) {
    getPeerOwner(peerId: $peerId) {
      firstName
      lastName
      organization {
        name
      }
      role
      email
      peer {
        id
        createdAt
        updatedAt
        name
        country
      }
      organization {
        name
      }
    }
  }
`
