import React, { useState } from 'react'
import { RouteComponentProps, useLocation, useNavigate, useParams } from '@reach/router'
import { AddPeerForm } from './AddPeerForm'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useFormatApolloError } from '../../../hooks/useFormatApolloError'
import { User } from '../../../common/types'

interface AddPeersProps extends RouteComponentProps {
  user?: User
}

const AddPeers: React.FC<AddPeersProps> = (props) => {
  const { pathname } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [formatError] = useFormatApolloError()
  const [error, setError] = useState({ message: '', action: '' })
  const [success, setSuccess] = useState({ message: '', action: '' })
  const [register, { loading }] = useMutation(CREATE_PEER)

  const [updatePeer, { loading: updateLoading }] = useMutation(UPDATE_PEER, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      navigate(`/dashboard/admin/peers/${id}`)
    }
  })

  const type = pathname.endsWith('edit-peer') ? 'edit' : 'create'
  const addAdminAsPeer = pathname.endsWith('add-admin-as-peer') ? true : false
  const onCreatePeer = (submission: any) => {
    setError({
      message: '',
      action: ''
    })
    setSuccess({
      message: '',
      action: ''
    })

    const input: any = {
      firstName: submission.firstName.trim(),
      lastName: submission.lastName.trim(),
      peerName: submission.peerName.trim(),
      country: submission.country.trim(),
      customMessage: submission?.message
    }

    if (!!submission.email) {
      input.email = submission.email.toLowerCase().trim()
    }

    if (type === 'create' || addAdminAsPeer) {
      register({
        variables: {
          input,
          addAdminAsPeer
        }
      })
        .then(({ data }) => {
          if (data) {
            const type = pathname.endsWith('add-admin-as-peer') ? true : false
            if (type) {
              navigate(`/dashboard/peer/organisations`)
            } else {
              setSuccess({
                message: 'New peer added successfully.',
                action: 'no action'
              })
              //reset()
              //e.target.reset()
              navigate(`/dashboard/admin/peers/`)
            }
          }
        })
        .catch((err) => {
          formatError(err)
          setError({
            message: formatError(err),
            action: 'no action'
          })
        })
    } else {
      const payload = {
        country: submission.country,
        name: submission.peerName
      }
      updatePeer({
        variables: {
          id,
          input: payload
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
      <AddPeerForm
        onSubmit={onCreatePeer}
        loading={loading || updateLoading}
        success={success}
        userId={id}
        formType={type}
        error={error.message}
        user={props.user}
      />
    </div>
  )
}

export default AddPeers

export const CREATE_PEER = gql`
  mutation registerPeer($input: RegisterPeerInput!, $addAdminAsPeer: Boolean) {
    registerPeer(input: $input, addAdminAsPeer: $addAdminAsPeer) {
      email
      firstName
      lastName
    }
  }
`

export const UPDATE_PEER = gql`
  mutation updatePeer($id: String!, $input: UpdatePeerInput!) {
    updatePeer(id: $id, input: $input) {
      id
    }
  }
`
