import React from 'react'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import { ButtonSize, ButtonVariant } from '../Button/types'
import { IconType } from '../Icon/Icon.options'
import { Color } from '../../color.enum'
import { COLOR_MAPS } from '../Notice'

export interface ButtonNoticeProps {
  /** What color is the componet? */
  color: Color
  /** What icon shoule be displayed */
  icon?: IconType
  /** Emphasised text */
  headline?: string
  /** Text to show on notice */
  description?: string
  /** Label to show on Button */
  buttonLabel?: string
  /** Icon to show on Button */
  buttonIcon?: IconType
  /** Button to be displayed */
  onClick?: () => void
  /** Aditional Styles for the container */
  className?: string
}
interface NoticeVariantProps {
  bg: string
  text: string
  btn: Color
  btnRightBg: Color
  icon: string
}

export const NOTICE_COLOR_MAPS: Record<Color, NoticeVariantProps> = {
  [Color.BLUE]: {
    bg: 'bg-brand-blue-light',
    text: 'text-white',
    btn: Color.BLUE,
    btnRightBg: Color.PURPLE,
    icon: 'text-brand-red'
  },
  [Color.PURPLE]: {
    bg: 'bg-brand-purple',
    text: 'text-white',
    btn: Color.PURPLE,
    btnRightBg: Color.PURPLE,
    icon: 'text-brand-red'
  },
  [Color.GRAY]: {
    bg: 'bg-gray-50',
    text: 'text-gray-500',
    btn: Color.GRAY,
    btnRightBg: Color.PURPLE,
    icon: 'text-brand-red'
  },
  [Color.ORANGE]: {
    bg: 'bg-brand-orange',
    text: 'text-white',
    btn: Color.ORANGE,
    btnRightBg: Color.ORANGE,
    icon: 'text-brand-red'
  },
  [Color.RED]: {
    bg: 'bg-brand-red',
    text: 'text-white',
    btn: Color.RED,
    btnRightBg: Color.PURPLE,
    icon: 'text-white'
  }
}

/**
 *  Component to show an animated ButtonNotice
 */

const ButtonNotice: React.FC<ButtonNoticeProps> = ({
  className,
  color,
  buttonLabel,
  buttonIcon,
  icon,
  headline,
  description,
  onClick
}: ButtonNoticeProps) => {
  
  return (
    <div className={classNames(className, 'ButtonNotice')}>
      <div className={`rounded-full p-1 flex flex-row justify-between items-center ${NOTICE_COLOR_MAPS[color]?.bg}`}>
        {headline && description && (
          <div className="mx-3">
            <span className={`m-0 p-0 text-xs flex ${NOTICE_COLOR_MAPS[color]?.text}`}>
              {icon && <Icon name={icon} className={`mr-2 ${NOTICE_COLOR_MAPS[color]?.icon}`} />}
              {headline && <span className="font-medium mr-1">{headline}</span>}
              {description}
            </span>
          </div>
        )}

        {buttonLabel && (
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            iconRight={buttonIcon && buttonIcon}
            color={NOTICE_COLOR_MAPS[color]?.btnRightBg}
            onClick={onClick}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  )
}

ButtonNotice.defaultProps = {
  color: Color.BLUE,
  headline: '',
  description: '',
  buttonLabel: ''
}

export default ButtonNotice
