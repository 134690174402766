import Button from '../Button/Button'
import { ButtonSize, ButtonVariant } from '../Button/types'
import parse from 'html-react-parser'
import { Color } from '../../color.enum'

export const api = {
  headline1: 'Understanding Your Capacity is Key to the Growth and Longevity of Your Organisation',
  headline2: '',
  description: parse(
    '<p class="text-white">How strong is your ministry’s foundation? Are you confident that you have the resources in place to support future growth? Do you understand the levels of capacity you need to advance your mission?</p>'
  ),
  children: (
    <div>
      <p className="text-white">
        <em>
          Capacity, in a ministry organisation, is defined as “the resources and skills used in order to successfully
          pursue its mission over time.”
        </em>
      </p>
      <p className='text-white'>
        The <strong className='text-brand-orange'>Mission Capacity Inventory (MCI)</strong> delivers a comprehensive assessment of the eight
        critically important Capacity Factors needed to advance your mission. You will complete this exercise with a
        clear and confident sense of the capacity strengths and weaknesses of your ministry, and the data needed to
        prioritize and design initiatives to strengthen your organisational foundation.
      </p>
      <Button variant={ButtonVariant.PRIMARY} color={Color.ORANGE} size={ButtonSize.LARGE} className="mx-2">
        Get started
      </Button>
      <a href="#more" title="">
        <Button
          variant={ButtonVariant.SECONDARY}
          color={Color.ORANGE}
          size={ButtonSize.LARGE}
          iconRight="arrowDown"
          className="mx-2"
        >
          Learn more
        </Button>
      </a>
    </div>
  )
}
