import { useState } from 'react'
import { FileInput } from '../../../../../components/Input/FileInput'
import { uploadFactorImageFile } from '../../../../../services/files'

interface UploadInterface {
  factorId: string
  refetch: () => void
}

export const ImageUpload: React.FC<UploadInterface> = ({ factorId, refetch }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (file: any) => {
    setLoading(true)
    const uploadSuccess = await uploadFactorImageFile(file[0], factorId)
    setLoading(false)
    if (uploadSuccess) {
      setSuccess(true)
      refetch()
    } else {
      setError(true)
    }
  }

  return (
    <div className="max-w-md">
      <FileInput
        onSubmit={(file) => {
          setSuccess(false)
          setError(false)
          onSubmit(file)
        }}
        loading={loading}
        success={success}
      />
      {error && <div>Something went wrong</div>}
      {success && <div>Upload successful</div>}
    </div>
  )
}
