import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface HeadingPageCenterProps {
  /** Headline of the component */
  headline?: string
  /** Descriptive text to tshow below the headline */
  description?: string | React.ReactNode
  /** Add a children to the component */
  children?: (color: Color) => JSX.Element
  /** Aditional Styles for the container */
  className?: string
  /** What color is the componet? */
  color: Color
}

interface HeadingPageCenterColorProps {
  text: string
}

export const HEADING_PAGE_COLOR_MAPS: Record<Color, HeadingPageCenterColorProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  },
  [Color.GRAY]: {
    text: 'text-gray-50'
  },
  [Color.RED]: {
    text: 'text-brand-red'
  },
  [Color.ORANGE]: {
    text: 'text-brand-orange'
  },
}

/**
 *  Component to show a Heading for a Page with optional actions
 */

const HeadingPageCenter: React.FC<HeadingPageCenterProps> = ({
  className,
  color,
  headline,
  description,
  children
}: HeadingPageCenterProps) => {
  return (
    <div className={classNames('HeadingPageCenter flex flex-col items-center mx-auto mb-8', className)}>
      <div className="flex-1 min-w-0 max-w-3xl">
        {headline && (
          <h2
            className={`font-normal leading-none text-3xl md:text-5xl text-center ${HEADING_PAGE_COLOR_MAPS[color].text}`}
          >
            {headline}
          </h2>
        )}
        {description && <p className={classNames(`prose mt-2 md:text-lg text-center mx-auto  ${HEADING_PAGE_COLOR_MAPS[color].text}`)}>{description}</p>}
      </div>
      {children && <div className="flex text-center mx-auto">{children(color)}</div>}
    </div>
  )
}

HeadingPageCenter.defaultProps = {
  color: Color.BLUE
}

export default HeadingPageCenter
