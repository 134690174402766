import { useState } from 'react'

type PaginationTuple = [number, number, number, () => void, () => void]

export const usePagination = (limit = 10): PaginationTuple => {
  const [page, setPage] = useState(1)

  const nextPage = () =>{
      setPage(page + 1)
  } 
  const prevPage = () => {
          setPage(page - 1);
  }; 

  return [page, (page) * limit, limit, nextPage, prevPage]
}
