import List from '../../../../../components/List'
import { ListItemCta } from './demographicQuestionsOptionsListItems'
import ListItem from '../../../../../components/ListItem'
import { Color } from '../../../../../color.enum'
import Alert, { AlertVariant, AlertSize } from '../../../../../components/Alert'

interface DemographicQuestionOptions {
  id: string
  text: string
}
interface DemographicItemProps {
  options: DemographicQuestionOptions[]
  surveyId: string
  type: string
  questionId: string
  demoId: string
  surveyStatus?: string
  refetch?: any
}

const DemographicQuestionOptionsList: React.FC<DemographicItemProps> = ({
  options,
  surveyId,
  type,
  questionId,
  demoId,
  surveyStatus,
  refetch
}) => {
  return options && options.length ? (
    <>
      <List color={Color.BLUE} className="mb-10">
        {(): JSX.Element => {
          return (
            <>
              {options.map((option: any) => (
                <ListItem
                  color={Color.BLUE}
                  headline={option.text}
                  renderCta={() => (
                    <ListItemCta
                      color={Color.BLUE}
                      id={option.id}
                      surveyId={surveyId}
                      type={type}
                      demoId={demoId}
                      questionId={questionId}
                      refetch={refetch}
                      surveyStatus={surveyStatus}
                    />
                  )}
                />
              ))}
            </>
          )
        }}
      </List>
    </>
  ) : (
    <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="my-4">
      No Options found for this Question. Please add new Option.
    </Alert>
  )
}

export default DemographicQuestionOptionsList
