import { RouteComponentProps, useNavigate, useParams } from '@reach/router'

import HeadingPage from '../../../../components/HeadingPage'
import { Color } from '../../../../color.enum'
import Button from '../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { gql, useQuery } from '@apollo/client'
import Loader from '../../../../components/Loader/Loader'
import { useBreadcrumbs } from '../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'

interface SinglePostSurveyQuestionOptionInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  code: string
}

const Details: React.FC<DetailsInterface> = ({ code }) => {
  return (
    <div className="mt-5 space-y-1">
      {code && (
        <div>
          <span className="inline text-white font-extrabold">Code:</span>
          <span className="ml-1">{code}</span>
        </div>
      )}
    </div>
  )
}

const SinglePostSurveyQuestionOption: React.FC<SinglePostSurveyQuestionOptionInterface> = () => {

  const [getBreadCrumbs] = useBreadcrumbs()
  const navigate = useNavigate()
  const { surveyId, postSurveyId, questionId, optionId } = useParams()
  const { loading, data } = useQuery(GET_SINGLE_POST_SURVEY_QUESTION_OPTION, {
    fetchPolicy: 'no-cache',
    variables: { id: optionId }
  })
  

  if (loading) return <Loader color={Color.PURPLE} />

  const bc = getBreadCrumbs('demographicQuestionOption', surveyId, '', 'Participant Questionaires', postSurveyId, questionId)

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.option.title}</title>
        <meta name="description" content={language.en.admin.option.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <BreadCrumbs pages={bc} className="py-0 pb-10" />
        <HeadingPage
          headline={data?.postSurveyQuestionOption?.text}
          color={Color.GRAY}
          description={<Details code={data?.postSurveyQuestionOption?.code} />}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    navigate(
                      `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/option/${optionId}/edit`,
                      {
                        state: { postSurveyQuestionOption: data?.postSurveyQuestionOption }
                      }
                    )
                  }}
                >
                  Edit Option
                </Button>
              </div>
            )
          }}
        />
      </main>
    </div>
  )
}

export default SinglePostSurveyQuestionOption

const GET_SINGLE_POST_SURVEY_QUESTION_OPTION = gql`
  query postSurveyQuestionOption($id: String!) {
    postSurveyQuestionOption(id: $id) {
      text
      code
    }
  }
`
