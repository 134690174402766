import { Helmet } from 'react-helmet'
import HeadingSection from '../components/HeadingSection'
import { Color } from '../color.enum'
import HeadingPage from '../components/HeadingPage'
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs'

interface PageProps {
  headline: string
  description?: string
  sectionHeading: string
  renderCta?: () => JSX.Element
  metaTitle?: string
  metaDesc?: string
  breadCrumbs?: Array<{ name: string; path: string; current: boolean }>
}

export const PageWrapper: React.FC<PageProps> = ({
  headline,
  description,
  sectionHeading,
  renderCta,
  metaDesc,
  metaTitle,
  children,
  breadCrumbs
}) => {

  return (
    <div className="page-container-lg">
      <header>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDesc} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        {breadCrumbs && <BreadCrumbs pages={breadCrumbs} className="pl-0 pb-10" />}
        <HeadingPage headline={headline} className="" color={Color.ORANGE} description={description} />
        <HeadingSection headline={sectionHeading} color={Color.ORANGE} children={renderCta} />
        {children}
      </header>
    </div>
  )
}
