import { Image, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { PropsWithChildren, useMemo } from 'react'
import { backgroundColor } from 'tailwindcss/defaultTheme'
import { Color } from '../../../color.enum'
import { ReportQuery } from '../../../generated'
import theme from '../../../theme'
import draftToPdf from '../../../utils/draftjs-to-pdf'
import { ArchetypeProps } from '../../Radar/Radar.helpers'
import Bar from '../Bar'
import { BarSize } from '../Bar/Bar'
import PDFRichText, { RichTextProps } from '../RichText'
import styles from '../styles'

interface ResultsProps {
  headline?: string
  content?: RichTextProps['content']
  styles: Record<string, Style>
  radarImage?: any
  barChartImage?: any
  archetypes?: Array<ArchetypeProps>
  data?: ReportQuery['report']
  statementCount?: number
  isIndividual?: boolean
}

const ResultsWrapper: React.FC<ResultsProps> = (props) => {
  const { headline, content, styles, radarImage, archetypes, data, statementCount, barChartImage, isIndividual } = props
  const participants = useMemo(() => {
    return data?.overview.averages.map((val) => val.participantId) ?? []
  }, [data?.overview])

  return (
    <View>
      {barChartImage && (
        <View
          style={{
            border: 1,
            borderColor: '#aeaeae',
            marginBottom: 20,
            marginTop: 20,
            paddingVertical: 20,
            paddingHorizontal: 20,
            backgroundColor: '#172956'
          }}
        >
          {barChartImage && <Image src={barChartImage.src} />}
        </View>
      )}

      <Text style={styles.heading} break={!isIndividual}>
        {headline}
      </Text>
      {content && <PDFRichText content={content} />}
      <View>{radarImage && <Image src={Buffer.from(radarImage)} />}</View>
      <View>
        {archetypes?.map((arch, idx) => (
          <View break>
            <Text style={{ fontSize: 12, fontWeight: 'medium', marginBottom: 20 }}>{arch.name}</Text>
            <Text style={{ fontSize: 10, fontWeight: 'normal', marginBottom: 10 }}>
              {arch.factorTypeReportDescription && draftToPdf(JSON.parse(arch.factorTypeReportDescription))}
            </Text>
            <View
              style={{
                border: 1,
                borderColor: '#aeaeae',
                marginBottom: 20,
                marginTop: 20,
                paddingVertical: 20,
                paddingHorizontal: 20
              }}
            >
              <Bar
                color={arch.color}
                headline={arch.name}
                scorePercentage={arch?.types ? arch?.types[0]?.score : 0}
                size={BarSize.BASE}
              />
              {arch.dimensions.map((dim) => (
                <View style={{ paddingVertical: 10 }}>
                  <Bar color={arch.color} headline={dim.name} scorePercentage={dim.score} size={BarSize.SMALL} />
                </View>
              ))}
            </View>
            {data ? (
              <StatementsScore
                color={arch.color}
                data={data.detailedResults}
                name={arch.name}
                participants={participants}
              />
            ) : null}
          </View>
        ))}
      </View>
    </View>
  )
}

const StatementsScore = (props: {
  data: ReportQuery['report']['detailedResults']
  participants: Array<string>
  name: string
  color: Color
}) => {
  const { data, participants, name, color } = props
  const isIndividual = participants.length === 1

  return (
    <View style={styles.table} break>
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCol, width: '20%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Dimension</Text>
        </View>
        <View style={{ ...styles.tableCol, width: '55%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Sub-Dimension</Text>
        </View>
        <View style={{ ...styles.tableCol, width: '25%' }}>
          <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Score</Text>
        </View>
      </View>
      {data.map((arch) => {
        if (arch.factorType === name) {
          return arch.factors.map((f) => {
            return (
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '20%' }}>
                  <Text style={styles.tableCell}>{f.headline}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '55%' }}>
                  {f.statements.map((s, idx) => {
                    return (
                      <Text style={{ ...styles.tableCell, overflow: 'hidden', paddingBottom: 4, paddingTop: 10 }}>
                        {s.subDimension}
                      </Text>
                    )
                  })}
                </View>
                <View style={{ ...styles.tableCol, width: '25%' }}>
                  {f.statements.map((s, idx) => {
                    return (
                      <View style={{ marginBottom: 6, paddingHorizontal: 3 }}>
                        <Bar color={color} scorePercentage={s.average} size={BarSize.SMALL} />
                      </View>
                    )
                  })}
                </View>
              </View>
            )
          })
        }
      })}
    </View>
  )
}

const Row = (props: PropsWithChildren<{ style?: Style }>) => {
  return (
    <View style={{ ...styles.row, ...(props.style || {}) }} wrap={false}>
      {props.children}
    </View>
  )
}

export default ResultsWrapper
