import React, { useEffect, useMemo, useRef } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ArchetypeProps } from '../Radar/Radar.helpers'
import {canvasToBuffer} from './blob'


interface BarChartProps {
    archetypes: ArchetypeProps[],
    setImageBuffer: (buffer: any) => void
}

interface BarChartData {
    labels: string[],
    datasets: Dataset[]
}

type Dataset = {
    label?: string,
    backgroundColor: string[],
    data: number[]

}


ChartJS.register(CategoryScale, LinearScale, BarElement, Title)
ChartJS.defaults.color = 'gray'
export const options = {
  scales: {
   
    y: {
       title: {
        display: true,
        text: '% per Archetype'
      },
      grid: {
        color: 'gray'
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  animation: {
    duration: 0
  },
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Archetype Average score'
    }
  }
}


export const BarChart: React.FC<BarChartProps> = ({ archetypes, setImageBuffer }) => {
  const chartRef = useRef<ChartJS>(null)
  useEffect(() => {
    const getBlob = async () => {
      const chart = chartRef.current
      if (chart) {
        canvasToBuffer(chart.canvas, setImageBuffer)
      }
    }

    getBlob()
  }, [chartRef.current])
    const results = useMemo<BarChartData>(() => {
        const data: BarChartData = {
          labels: [],
          datasets: [
            {
              backgroundColor: [],
              data: []
            }
          ]
        }

        if (archetypes) {
          archetypes.map((a) => {
            a.types?.map((t) => {
              data.labels.push(t.name)
              data.datasets[0].backgroundColor.push(t.color ?? '')
              data.datasets[0].data.push(t.score)
            })
          })
        }
        return data

    }, [archetypes])
    

  return <Bar ref={chartRef as any} options={options} data={results} height="250" width="500"/>
}