import { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Color } from '../../../../color.enum'
import { User } from '../../../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import OrgScanList from './OrgScanList'
import { useForm } from 'react-hook-form'
import Loader from '../../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { language } from '../../../../common/i18n'
import Modal from '../../../../components/Modal/Modal'
import { Box } from '@plusplusminus/plusplusdash'
import { Input } from '../../../../components/Input/Input'
import { PageWrapper } from '../../../../modules/PageWrapper'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const OrgScansContainer: React.FC<OwnerProps> = (props) => {
  return <OrgScans {...props} />
}

interface ModalProps extends RouteComponentProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  setShowCreateButton: (showModal: boolean) => void
}

const CreateOrgModal: React.FC<ModalProps> = ({ showModal, setShowModal, setShowCreateButton }) => {
  const {
    register,
    handleSubmit
  } = useForm()

  const [createOrg, { loading }] = useMutation(CREATE_ORG)

  const onRegister = async (data: any) => {
    createOrg({
      variables: {
        name: data.org
      }
    }).then(() => {
      setShowCreateButton(false)
      setShowModal(false)
    })
  }

  return (
    <Modal isModalOpen={showModal} title="Add new Organisation" onClose={() => setShowModal(false)}>
      <div className="flex-1  flex flex-col">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8"
        >
          <Box className="col-span-2 sm:col-span-2" key="org">
            <label htmlFor="org" className="text-base font-medium text-brand-purple my-1 inline-block">
              Organisation name
            </label>
            <Input as="input" width="full" {...register('org')} />
          </Box>

          <div className="col-span-2">
            <Button
              className="justify-center mb-2 mt-3"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              color={Color.ORANGE}
              type="submit"
              iconRight="arrowRight"
            >
              {loading ? 'Loading' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const OrgScans: React.FC<OwnerProps> = (props) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showCreateOrgButton, setShowCreateOrgButton] = useState(false)

  const { loading } = useQuery(GET_MY_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })

  const {
    loading: orgLoading,
    data: orgAssignments
  } = useQuery(GET_ORG_ASSIGNMENTS, { fetchPolicy: 'no-cache', variables: { orgId: props?.user?.organization?.id } })

  useEffect(() => {
    if (!props?.user?.organization?.id) {
      setShowCreateOrgButton(true)
    }
  }, [])

  if (loading || orgLoading) return <Loader color={Color.PURPLE} />

  return (
    <PageWrapper
      metaDesc={language.en.orgOwner.dashboard.description}
      metaTitle={language.en.orgOwner.dashboard.title}
      headline="Organisational Scans"
      sectionHeading="All Current Organisational Scans"     
    >
      {orgAssignments?.organizationAssignments.length > 0 ? (
        <OrgScanList
          assignments={orgAssignments?.organizationAssignments}
          loading={loading}
          organizationId={props?.user?.organization?.id}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            You do not have any scans assigned to you.
          </Alert>
        </div>
      )}
      <CreateOrgModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        setShowCreateButton={setShowCreateOrgButton}
      />
    </PageWrapper>
  )
}

const GET_ORG_ASSIGNMENTS = gql`
  query OrganizationAssignments($orgId: String!) {
    organizationAssignments(orgId: $orgId) {
      id
      dueDate
      status
      name
      respondentCount
      questionnaireStatus
      organization {
        name
      }
      preSurveyStatus
      respondentProfile {
        id
        surveyStatus
      }
    }
  }
`
const CREATE_ORG = gql`
  mutation createOrg($name: String!, $country: String!) {
    createOrg(name: $name, country: $country) {
      id
    }
  }
`
const GET_MY_ASSIGNMENTS = gql`
  query _getAllUserAssignments {
    myAssignments {
      id
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      isOwner
      respondentProfile {
        id
        surveyStatus
      }
      organization {
        id
      }
    }
  }
`
