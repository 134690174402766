import { useState} from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useMutation, gql } from '@apollo/client'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { Input } from '../../../components/Input/Input'

interface ScanConfirmProps extends RouteComponentProps {
  user?: User
  scanId: string
}

export const ScanConfirmation: React.FC<ScanConfirmProps & any> = ({ scanId }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [submitScan, { loading }] = useMutation(COMPLETE_SCAN, {
    onCompleted: () => navigate('/dashboard/organisation')
  })
  return (
    <div className="page-container-lg">
      <div className="section-container-sm">
        <HeadingPageCenter headline="Scan Confirmation" color={Color.GRAY} />
        <div>
          <p className="text-md text-white mb-2">
            Please ensure that all scan details are correct before you submit. You will not be able to make any changes
            to the scan details after clicking 'Submit', you will however, be able to edit participants. Once you click
            'Submit' the scan will be live and an invitation email will be sent to the Organisation’s Representative.
          </p>

          <FormLabel className="text-base font-medium text-white my-1 inline-block">
            An email will be sent to each participant inviting them to start the Inventory. Your custom message will be
            added to their invitation.
          </FormLabel>

          <Input as="textarea" width="full" name="customMessage" onChange={(event) => setMessage(event.target.value)} />
          <div className="flex space-x-2  mt-3">
            <Button
              className="flex-1 text-center justify-center outline-none"
              variant={ButtonVariant.PLAIN}
              color={Color.ORANGE}
              size={ButtonSize.LARGE}
              onClick={() => navigate('/dashboard/organisation')}
            >
              Cancel
            </Button>
            <Button
              className="flex-1 text-center justify-center"
              variant={ButtonVariant.PRIMARY}
              color={Color.ORANGE}
              size={ButtonSize.LARGE}
              onClick={() =>
                submitScan({
                  variables: {
                    assignmentId: scanId,
                    message
                  }
                })
              }
            >
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const COMPLETE_SCAN = gql`
  mutation submitScan($message: String!, $assignmentId: String!) {
    submitScan(message: $message, assignmentId: $assignmentId)
  }
`
