import { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { closestCenter, DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import draftToHtml from 'draftjs-to-html'
import parse from 'html-react-parser'
import List from '../../../../components/List'
import { ListItemCta } from './statementListItems'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import Alert, { AlertVariant, AlertSize } from '../../../../components/Alert'
import { useHandleDrag } from '../../../../hooks/useHandleDrag'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from '../factors/SortableItem'

interface Statement {
  createdAt: Date
  id: string
  name: string
  statement: string,
  code: string
}
interface StatementItemProps {
  statements: Statement[]
  surveyStatus?: string
  refetch?: any
}

const StatementList: React.FC<StatementItemProps> = ({ statements, surveyStatus, refetch }) => {
  const indices = statements.map((f: any, index: any) => String(index + 1))
  const [updateStatementOrder] = useMutation(UPDATE_STATEMENT_ORDER)

  const [orderHasChanged, sensors, handleDragEnd, items] = useHandleDrag(indices)

  useEffect(() => {
    if (orderHasChanged) {
      const newOrder = calculateStatementOrder()

      updateStatementOrder({
        variables: {
          orderInput: newOrder
        }
      })
    }
  }, [items])
  const convertWYSIWYGstatementData = (statement: any) => {
    try {
      return parse(`${draftToHtml(JSON.parse(statement))}`)
    } catch (e) {
      return statement
    }
  }

  const getStatement = (index: any) => {
    const statement = statements[index - 1]
    if (statement) {
      return (
        <ListItem
          code={statement?.code}
          type='statement'
          color={Color.BLUE}
          headline={convertWYSIWYGstatementData(statement?.statement)}
          renderCta={() => <ListItemCta color={Color.BLUE} id={statement?.id} refetch={refetch} surveyStatus={surveyStatus}/>}
        />
      )
    } else {
      return null
    }
  }

  const calculateStatementOrder = () => {
    return items
      .map((item: any, index: number) => {
        const statement = statements[item - 1]
        if (statement) {
          return { id: statement.id, position: index + 1 }
        }
      })
      .filter((a: any) => a)
  }

  return statements && statements.length ? (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <List color={Color.BLUE} className="mb-10">
            {(): JSX.Element => {
              return (
                <>
                  {items.map((id: any) => (
                    <SortableItem key={id} id={id}>
                      <div>{getStatement(id)}</div>
                    </SortableItem>
                  ))}
                </>
              )
            }}
          </List>
        </SortableContext>
      </DndContext>
    </>
  ) : (
    <Alert size={AlertSize.LARGE} variant={AlertVariant.INFO} className="my-4">
      No Statements found for this Dimension. Please add new Statement.
    </Alert>
  )
}

export default StatementList

const UPDATE_STATEMENT_ORDER = gql`
  mutation updateStatementOrder($orderInput: [OrderInput!]!) {
    updateStatementOrder(orderInput: $orderInput)
  }
`
