import { gql } from '@apollo/client'

export const WELCOME_CONTENT_QUERY = gql`
  query WelcomeContent {
    contentCollection(where: { identifier: "app-welcome-message" }) {
      items {
        headline
        content {
          json
        }
        videoUrl
      }
    }
  }
`

export const LOGIN_CONTENT_QUERY = gql`
  query LoginContent {
    contentCollection(where: { identifier: "login-greater-than-content" }) {
      items {
        headline
        content {
          json
        }
        image {
          url
        }
      }
    }
  }
`
