import React from 'react'
import { Color } from '../../color.enum'
import { Content as ContentTypes } from '../../common/types'
import RichTextRenderer from '../../modules/RichTextRenderer'
import { classNames } from '../../utils/class-names'
import Content from '../Content'

export interface TestimonialProps {
  /** Content of the Testimonial */
  headline?: React.ReactElement | React.ReactNode
  /** Descriptive text to support the cite */
  description?: string
  /** Who should this be attributed to? */
  cite?: string
  /** Aditional Styles for the container */
  className?: string
  /** Image to be displayed as backgroud element */
  image?: React.ReactElement
  /** What color is the componet? */
  color: Color
  /** Id to identify specific component as provided by the CMS */
  id?: string
  content?: ContentTypes
}

interface TestimonialColorProps {
  text: string
  bg: string
  description: string
}

export const TESTIMONIAL_COLOR_MAPS: Record<Color, TestimonialColorProps> = {
  [Color.GRAY]: {
    text: 'text-gray-200',
    description: 'text-gray-300',
    bg: 'bg-gray-50'
  },

  [Color.BLUE]: {
    text: 'text-gray-200',
    description: 'text-gray-300',
    bg: 'bg-brand-blue-light'
  },
  [Color.PURPLE]: {
    text: 'text-gray-200',
    description: 'text-gray-300',
    bg: 'bg-brand-purple'
  },
  [Color.RED]: {
    text: 'text-gray-200',
    description: 'text-gray-300',
    bg: 'bg-brand-red'
  },
  [Color.ORANGE]: {
    text: 'text-gray-200',
    description: 'text-gray-300',
    bg: 'bg-brand-orange'
  }
}

/**
 *  Component to show a Testimonial - typically at the top of the page
 */

const Testimonial: React.FC<TestimonialProps> = ({
  className,
  color,
  headline,
  description,
  image,
  cite,
  content,
  ...rest
}: TestimonialProps) => {
  return (
    <div className={classNames(className, 'Testimonial', 'mb-16')} {...rest}>
      <div className={`relative px-8 max-w-7xl mx-auto`}>
        <div
          className={`relative rounded-xl py-12 px-8 md:py-24 shadow-2xl overflow-hidden md:px-16 md:grid ${
            image ? 'md:grid-cols-2' : 'md:grid-cols-1'
          } md:gap-x-8 ${TESTIMONIAL_COLOR_MAPS[color].bg}`}
        >
          {image && <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">{image}</div>}
          <div className="relative lg:col-span-1">
            <blockquote className={`mt-6 ${TESTIMONIAL_COLOR_MAPS[color].text} w-full`}>
              {content && (
                <div className="text-white prose max-w-7xl font-base text-xl">
                  <RichTextRenderer content={content} />
                </div>
              )}
              {description || cite ? (
                <footer className="mt-6">
                  <p className="flex flex-col font-medium">
                    {cite && <span className={`${TESTIMONIAL_COLOR_MAPS[Color.ORANGE]} text-brand-orange`}>{cite}</span>}

                    {description && (
                      <span className={`${TESTIMONIAL_COLOR_MAPS[Color.ORANGE]} text-brand-orange`}>{description}</span>
                    )}
                  </p>
                </footer>
              ) : null}
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial.defaultProps = {
  color: Color.BLUE
}

export default Testimonial
