import Steps from '../../../components/Steps'
import Progress from '../../../components/Progress'
import { Color } from '../../../color.enum'
import Slider from '../../../components/InputSlider/slider'

interface QuestionProps {
  error: string | null
  activeDimension: number
  questionNumber: number
  totalQuestions: number
  descriptor: string
  steps: Array<StepsProps>
  renderCta?: JSX.Element
  handleOnChange: (response: any) => void
  value: number | undefined
  scale: [
    {
      id: string
      code: string
      value: number
      title: string
      name: string
    }
  ]
}
export interface StepsProps {
  title: string
  isComplete: boolean
  color: Color
}
const Question: React.FC<QuestionProps> = ({
  activeDimension,
  questionNumber,
  totalQuestions,
  descriptor,
  steps,
  renderCta,
  value,
  handleOnChange,
}) => {
  return (
    <main>
      <div className="survey-container py-32 px-3 md:px-0">
        <div className="mb-6">
          <Steps headline={'Section:'} steps={steps} activeDimension={activeDimension} />
        </div>
        <div className="mb-2">
          <Progress headline="Question:" questionNumber={questionNumber} totalQuestions={totalQuestions} />
        </div>

        <h2 className="text-3xl text-white">{descriptor}</h2>
        <div className="py-12">
          <p className="mb-4 font-medium text-white">To what extent does this statement represent your organisation?</p>
          <Slider handleChange={handleOnChange} value={value} questionNumber={questionNumber} />
        </div>
        {renderCta}
      </div>
    </main>
  )
}

export default Question
