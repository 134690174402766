import { useState } from "react"
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'

export const useHandleDrag: any = (indices: any) => {
    const [orderHasChanged, setOrderHasChanged] = useState(false)    
    const [items, setItems] = useState(indices)

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates
        })
      )

    function handleDragEnd(event: any) {
        setOrderHasChanged(true)
        const { active, over } = event
    
        if (active.id !== over.id) {
          setItems((itemsIndex: any) => {
            const oldIndex = itemsIndex.indexOf(active.id)
            const newIndex = itemsIndex.indexOf(over.id)
    
            return arrayMove(itemsIndex, oldIndex, newIndex)
          })
        }
      }

    return [orderHasChanged, sensors, handleDragEnd, items]
}
