import resolveConfig from 'tailwindcss/resolveConfig'
import { TailwindColorGroup, TailwindConfig } from 'tailwindcss/tailwind-config'
import tailwindConfig from '../tailwind.config.js'

import flatMap from 'array.prototype.flatmap'

flatMap.shim()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default resolveConfig(tailwindConfig).theme as TailwindConfig['theme'] & {
  colors: TailwindColorGroup & { [key in keyof Colors]: string }
}
