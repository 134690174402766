import React from 'react'
import { classNames } from '../../utils/class-names'
import Logo from '../../../public/osp-footer.png'

export interface FooterProps {
  /** Legal text to be added to the footer */
  legalText: string
  /** Navigation to be added to the footer */
  renderNavigation?: React.ReactElement
  /** Aditional Styles for the container */
  className?: string
}

/**
 *  Component to show the Footer of the App
 */
const Footer: React.FC<FooterProps> = ({ className, renderNavigation, legalText }: FooterProps) => {
  return (
    <footer className={classNames(className, 'bg-brand-blue')}>
      <div className="bg-gradient-to-r from-brand-purple via-brand-red to-brand-orange h-1"></div>
      <div className="flex justify-between">
        <div className="max-w-7xl py-12 px-4 sm:px-6 xl:flex items-start xl:items-left flex-col">
          {legalText && (
            <p className="tracking-widest text-center xl:text-left text-xs text-white uppercase m-0 pt-2">
              {legalText}
            </p>
          )}
          {renderNavigation && renderNavigation}
          <p className="tracking-widest text-center xl:text-left text-xs text-white uppercase ">
            Created by{' '}
            <a
              href="http://www.plusplusminus.co.za/?utm_source=MCI&utm_medium=Footer&utm_campaign=Credit"
              target="_blank"
            >
              PlusPlusMinus
            </a>
          </p>
        </div>
        <div className='py-12 pr-12'>
          <img src={Logo} alt="osp-footer" />
        </div>
      </div>
    </footer>
  )
}

export default Footer
