import List from '../../../../components/List'
import { ListItemCta, ListItemMeta } from './demographicListItems'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'


interface DemoGraphicItem {
  headline: string
  color?: Color
  renderMeta?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

interface DemoGraphicItemProps {
  survey: any
  type: string
  deleteSurvey: () => void
  surveyId: string
  surveyStatus?:string
  refetch?: any
}

const DemoGraphicList: React.FC<DemoGraphicItemProps> = ({ survey, type, deleteSurvey, surveyId,surveyStatus, refetch }) => {
  const item: DemoGraphicItem = {
    headline: survey.name,
    renderMeta: <ListItemMeta questionCount={`${survey?.questions?.length} Questions`} />,
    renderCta: (color: Color): JSX.Element => {
      return <ListItemCta color={color} id={survey.id} type={type} deleteSurvey={deleteSurvey} surveyId={surveyId} surveyStatus={surveyStatus} refetch={refetch}/>
    }
  }
  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return <ListItem color={color} {...item} />
    }
  }

  return (
    <>
    <List {...items} className="mb-10" />
    </>
  
  )
}

export default DemoGraphicList
