import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import { styles, BUTTON_COLOR_MAPS, BUTTON_VARIANT_MAPS, BUTTON_SIZE_MAPS } from './styles'
import { IconType } from '../Icon/Icon.options'
import { classNames } from '../../utils/class-names'
import { ButtonVariant, ButtonSize } from './types'
import { Color } from '../../color.enum'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  variant: ButtonVariant
  color: Color
  size: ButtonSize
  isDisabled?: boolean
  iconLeft?: IconType
  iconRight?: IconType
  className?: string
}

/**
 * Primary UI component for user interaction
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, className, color, size, variant, isDisabled, ...rest } = props

  const mergedClassName = classNames(
    styles.default,
    className
    // {[styles.disabled]: isDisabled}
  )

  return (
    <button
      ref={ref}
      className={classNames(
        mergedClassName,
        BUTTON_COLOR_MAPS[color][variant],
        BUTTON_SIZE_MAPS[size],
        BUTTON_VARIANT_MAPS[variant]
      )}
      disabled={isDisabled}
      {...rest}
    >
      {props.iconLeft && <Icon name={props.iconLeft} className="mr-2" />}
      {children}
      {props.iconRight && <Icon name={props.iconRight} className="ml-2" />}
    </button>
  )
})

Button.defaultProps = {
  color: Color.ORANGE,
  variant: ButtonVariant.PRIMARY,
  size: ButtonSize.MEDIUM
}

export default Button
