import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateDemographicSurveyForm } from './createDemographicSurveyFormParticipant'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { User } from '../../../../common/types'

interface CreateDemographicSurveyInterface extends RouteComponentProps<any> {
  location: any
  user?: User
}

const CreateDemographicSurvey: React.FC<CreateDemographicSurveyInterface> = (props) => {
  const navigate = useNavigate()
  const { surveyId, postSurveyId } = useParams()
  const { pathname } = useLocation()

  const [createPostSurvey, { loading }] = useMutation(CREATE_POST_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${data?.createPostSurvey?.id}/participant`)
    }
  })

  const [updatePostSurvey, { loading: updateLoading }] = useMutation(UPDATE_POST_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${data?.updatePostSurvey?.id}/participant`)
    }
  })

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  const onCreatePostSurvey = async (data: any, type: string) => {    
    if (type === 'create') {
      createPostSurvey({
        variables: {
          input: { ...data, channelId: props?.user?.channel.id, surveyId: surveyId }
        }
      })
    } else {
      updatePostSurvey({
        variables: {
          id: postSurveyId,
          input: { name: data.name, description: data.description, headline: data.headline, localeId: data.localeId }
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={pathname.endsWith('edit') ? 'Edit Demographic Questionnaire' : 'Create Demographic Questionaire'}
          color={Color.ORANGE}
        />
        <div className="section-container-sm">
          <CreateDemographicSurveyForm
            loading={loading || updateLoading}
            onSubmit={onCreatePostSurvey}
            formType={type}
            postSurveyId={postSurveyId}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateDemographicSurvey

const CREATE_POST_SURVEY = gql`
  mutation createPostSurvey($input: CreatePostSurveyInput!) {
    createPostSurvey(input: $input) {
      name
      id
      description
      headline
    }
  }
`
export const UPDATE_POST_SURVEY = gql`
  mutation updatePostSurvey($input: UpdatePostSurveyInput!, $id: String!) {
    updatePostSurvey(input: $input, id: $id) {
      id
      name
    }
  }
`
