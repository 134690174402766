import React from 'react'
import gql from 'graphql-tag'
import { Content, HeadingPage } from '../components'
import { Color } from '../color.enum'
import RichTextRenderer from './RichTextRenderer'
import { BLOCKQUOTE_FIELDS } from './BlockquoteWrapper'
import { useQuery } from '@apollo/client'
import client from '../contentful'

export interface LayoutContentProps {
  id: string
}

interface ContentData {
  content: {
    headline?: string
    content?: any
    children?: React.ReactElement
  }
}

const LayoutContentWrapper: React.FC<LayoutContentProps> = (contentProps) => {
  const { id } = contentProps
  const { data, loading } = useQuery<ContentData>(CONTENT_QUERY, { variables: { id }, client })

  if (loading || !data?.content) return null

  const { headline, content } = data.content

  return (
    <div className={'page-container-lg'}>
      {headline && <HeadingPage color={Color.GRAY} headline={headline} />}
      {content && (
        <Content>
          <RichTextRenderer content={content} />
        </Content>
      )}
    </div>
  )
}

export default LayoutContentWrapper

export const LAYOUT_CONTENT_FIELDS = gql`
  ${BLOCKQUOTE_FIELDS}
  fragment LayoutContentFields on Content {
    headline
    content {
      json
      links {
        entries {
          inline {
            sys {
              id
            }
          }
          block {
            sys {
              id
            }
            __typename
            ... on LayoutBlockquote {
              ...BlockquoteFields
            }
          }
        }
      }
    }
  }
`

const CONTENT_QUERY = gql`
  query ContentQuery($id: String!) {
    content(id: $id) {
      ...LayoutContentFields
    }
  }
  ${LAYOUT_CONTENT_FIELDS}
`
