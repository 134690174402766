import { ApolloClient, InMemoryCache } from '@apollo/client'

import * as dotenv from 'dotenv'
dotenv.config({ path: __dirname + `/.env.${process.env.NODE_ENV}` })

export const activeEnv = process.env.REACT_APP_ACTIVE_ENV || process.env.NODE_ENV || 'preview'
// const activeEnv = "preview"

// Check if Preview is active to pass to useQuery eg on Page.tsx
export const isPreview = activeEnv === 'preview' ? true : false

enum EnvTypes {
  production = 'production',
  development = 'development',
  preview = 'preview',
  staging = 'staging'
}
interface ConfigTypes {
  spaceId: string | undefined
  accessToken: string | undefined
  host: string | undefined
  environment?: string | undefined
}

const CONFIG: Record<EnvTypes, ConfigTypes> = {
  production: {
    spaceId: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: ''
  },
  development: {
    spaceId: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: ''
  },
  preview: {
    spaceId: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN_PREVIEW,
    host: process.env.REACT_APP_CONTENTFUL_HOST_PREVIEW,
    environment: ''
  },
  staging: {
    spaceId: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: '/environments/staging'
  }
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${CONFIG[activeEnv as keyof typeof EnvTypes].accessToken}`
  },
  uri: `https://graphql.contentful.com/content/v1/spaces/${CONFIG[activeEnv as keyof typeof EnvTypes].spaceId}${
    CONFIG[activeEnv as keyof typeof EnvTypes].environment
  }`
})

export default client
