export const testResults = {
  results: [
    {
      color: 'orange',
      name: 'People Practices',
      description: 'Some description',
      dimensions: [
        {
          factor: {
            name: 'Leadership',
            description: 'Some valid description',
            headline: 'headline',
            content: 'Some content description'
          },

          score: 80
        },
        {
          name: 'Mission',
          description: 'Some valid description',
          headline: 'headline',
          content: 'Some content description',
          score: 66
        },
        {
          name: 'Talent',
          description: 'Some valid description',
          headline: 'headline',
          content: 'Some content description',
          score: 33
        },
        {
          name: 'Culture/Values',
          description: 'Some valid description',
          headline: 'headline',
          content: 'Some content description',
          score: 65
        }
      ]
    }
  ]
}
