import React, { Fragment } from 'react'
import { classNames } from '../../utils/class-names'
import { Disclosure, Transition } from '@headlessui/react'
import Button from '../Button/Button'
import { ButtonSize, ButtonVariant } from '../Button/types'
import { Color } from '../../color.enum'

export interface HeaderProps {
  /** Links to be added to the main bar - visible on mobile */
  renderQuickLinks?: React.ReactElement | JSX.Element
  /** Logo for the Nav bar */
  renderLogo?: React.ReactElement
  /** Navigation items for the Nav bar */
  renderNavigation?: React.ReactElement | React.ReactNode
  /** Aditional Styles for the container */
  className?: string
}

const Header: React.FC<HeaderProps> = ({ className, renderLogo, renderNavigation, renderQuickLinks }: HeaderProps) => {
  return (
    <header className={classNames(className, 'Header fixed top-0 w-full z-40')}>
      <Disclosure as="header" className="bg-brand-blue drop-shadow-lg">
        {({ open }) => (
          <>
            <div
              className={classNames(
                `max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 ${renderNavigation && 'lg:divide-y lg:divide-gray-200'}`
              )}
            >
              <div className="relative h-16 md:h-20 flex justify-between">
                <div className="relative z-10  pr-10 flex lg:pr-0">
                  <div className="flex-shrink-0 flex items-center">{renderLogo && renderLogo}</div>
                </div>

                {renderNavigation || renderQuickLinks ? (
                  <div className="relative z-10 flex items-center lg:hidden">
                    <Disclosure.Button>
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <Button
                          variant={ButtonVariant.PLAIN}
                          iconRight="close"
                          color={Color.ORANGE}
                          size={ButtonSize.SMALL}
                        >
                          Close
                        </Button>
                      ) : (
                        <Button
                          variant={ButtonVariant.PLAIN}
                          iconRight="menu"
                          color={Color.ORANGE}
                          size={ButtonSize.SMALL}
                        >
                          Menu
                        </Button>
                      )}
                    </Disclosure.Button>
                  </div>
                ) : null}
                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center space-x-2">
                  {renderQuickLinks && renderQuickLinks}
                </div>
              </div>
              {renderNavigation && (
                <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                  {renderNavigation}
                </nav>
              )}
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition duration-200 ease-out"
              enterFrom="transform  opacity-0"
              enterTo="transform  opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform  opacity-100"
              leaveTo="transform  opacity-0"
            >
              <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                {renderNavigation && <div className="pt-2 pb-3 px-2 space-y-4 flex flex-col">{renderNavigation}</div>}
                {renderQuickLinks && (
                  <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="px-2 flex items-center space-x-2">{renderQuickLinks}</div>
                  </div>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </header>
  )
}

export default Header
