export const USER_ROLES = {
  ADMIN: 'admin',
  ORG_OWNER: 'organization_owner',
  RESPONDENT: 'respondent',
  PEER: 'peer'
}

export const CONTENT_TYPES = {
  HERO: 'LayoutHero',
  SECTION_CENTER: 'LayoutSectionCenter',
  SECTION_LEFT: 'LayoutSectionLeft',
  TESTIMONIAL: 'LayoutTestimonial',
  CTA: 'LayoutCallToAction',
  BLOCKQUOTE: 'LayoutBlockquote',
  LAYOUT_CONTENT: 'Content',
  RESULTS: 'Results'
}

export const SURVEY_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  CLOSED: 'CLOSED'
}

export const ASSIGNMENT_STATUS = {
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED'
}

export enum FormTypes {
  create,
  edit
}
