import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { navigate } from '@reach/router'
import { Color } from '../../../color.enum'
import { SURVEY_STATUS } from '../../../common/constants'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import Icon from '../../../components/Icon/Icon'
import { Badge } from '@plusplusminus/plusplusdash'
import { formatDate } from '../../../services/formatDate'
import RemoveItemModal from './components/RemoveItemModal'


interface ItemCtaData {
  color: Color
  id: string,
  status?: string,
  refetch?: any
}

interface ItemMetaData {
  createdAt: Date
  status?: string
  locale?: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({ color, id, status, refetch}: ItemCtaData) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteSurvey, { loading }] = useMutation( DELETE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      refetch()
      setIsOpen(false)
    }
  })

  const handleCloseRemoveQuestion = () => {
    setIsOpen(false)
  }

  const handleRemoveQuestion = (id: string) => {
      deleteSurvey({
        variables:{
          id: id
        }
      })
  }

  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveQuestion}
        handleConfirm={() => handleRemoveQuestion(id)}
        loading={loading}
      />
      {status !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          Remove Survey
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() => navigate(`/dashboard/admin/survey/${id}`)}
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ createdAt, status, locale }: ItemMetaData) => {
  return (
    <div className="space-y-1">
      <p className="text-sm text-gray-900">
        Date Created: <time dateTime={'2020-01-07'}>{formatDate(createdAt)}</time>
      </p>
      <p className="flex items-center text-sm text-gray-500">
        <Badge color={status === SURVEY_STATUS.PUBLISHED ? 'green' : status === SURVEY_STATUS.CLOSED ? 'red' : 'blue'}>
          {status === SURVEY_STATUS.PUBLISHED && <Icon name="checkSolid" className="mr-2" />} {status}
        </Badge>
      </p>
      <p className="text-sm text-gray-900">Locale: {locale}</p>
    </div>
  )
}

const  DELETE_SURVEY = gql`
  mutation deleteSurvey($id: String!) {
    deleteSurvey(id: $id) {
      status
      message
    }
  }
`
