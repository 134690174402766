export class InputValidator {
  input: string
  constructor(payload: string) {
    this.input = payload.toLowerCase().trim()
  }

  validateEmail(): boolean {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.input)
  }
  validateAlphaNumericNoSpace(): boolean {
    return /^([a-zA-Z0-9_-]){3,100}$/.test(this.input)
  }

  validateAlphaNumericWithSpace(): boolean {
    return /^[a-zA-Z0-9_ '.-]+$/i.test(this.input)
  }
  validateOtp(): boolean {
    return /^([a-zA-Z0-9_-]){6,6}$/.test(this.input)
  }
}

