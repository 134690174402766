import List from '../../../components/List'
import { ListItemCta, ListItemMeta } from './ListItems'
import ListItem from '../../../components/ListItem'
import { Color } from '../../../color.enum'

interface SurveyItem {
  headline: string
  color?: Color
  renderMeta?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

interface AssignmentItemProps {
  assignments: any
}

const RespondentAssignmentList: React.FC<AssignmentItemProps> = ({ assignments }) => {
  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return (
        <>
          {assignments?.map((respondent: any) => {
            const { assignment, surveyStatus, id } = respondent
            const item: SurveyItem = {
              headline: assignment.name,
              renderMeta: <ListItemMeta dueDate={assignment.dueDate} status={surveyStatus} hasExpired={respondent.assignment.status === 'EXPIRED'}/>,
              renderCta: (): JSX.Element => {
                return <ListItemCta status={surveyStatus} respondentId={id} assignmentId={assignment.id} hasExpired={respondent.assignment.status === 'EXPIRED'}/>
              }
            }
            return <ListItem color={color} {...item} />
          })}
        </>
      )
    }
  }

  return <List {...items} className="mb-10" />
}

export default RespondentAssignmentList
