import React from 'react'
import { FeatureCard, FeatureRow, SectionLeft } from '../components'
import gql from 'graphql-tag'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Color } from '../color.enum'
import { IconType } from '../components/Icon/Icon.options'
import { Content } from '../common/types'

interface SectionLeftProps {
  headline?: string
  subtitle?: string
  description?: Content
  color: Color
  childrenCollection?: Children
  className?: string
  sys: { id: string }
}

interface Children {
  items?: [Child]
}

interface Child {
  color: Color
  description?: Content
  iconName: IconType
  headline: string
  __typename: string
}

const SectionLeftWrapper: React.FC<SectionLeftProps> = ({
  childrenCollection,
  headline,
  subtitle,
  description,
  color,
  className,
  sys
}) => {
  return (
    <SectionLeft
      headline={headline && headline}
      subtitle={subtitle && subtitle}
      color={color}
      description={description && documentToReactComponents(description.json)}
      className={className}
      id={sys.id}
    >
      {(color: Color): JSX.Element => {
        return (
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-row sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {childrenCollection && childrenCollection.items && childrenCollection.items.length > 0
              ? childrenCollection.items.map((i) => {
                  if (i.__typename === 'LayoutFeatureIconCenter') {
                    return (
                      <FeatureCard
                        key={i.headline}
                        headline={i.headline}
                        icon={i.iconName}
                        description={i.description && documentToReactComponents(i.description.json)}
                        color={i.color.toLowerCase() as Color}
                      />
                    )
                  } else if (i.__typename === 'LayoutFeatureIconRight') {
                    return (
                      <FeatureRow
                        key={i.headline}
                        headline={i.headline}
                        icon={i.iconName}
                        description={i.description && documentToReactComponents(i.description.json)}
                        color={i.color.toLowerCase() as Color}
                      />
                    )
                  } else {
                    return null
                  }
                })
              : null}
          </dl>
        )
      }}
    </SectionLeft>
  )
}

export default SectionLeftWrapper

export const SECTION_LEFT_FIELDS = gql`
  fragment SectionLeftFields on LayoutSectionLeft {
    sys {
      id
    }
    name
    description {
      json
    }
    headline
    subtitle
    color
    childrenCollection(limit: 5) {
      items {
        __typename
        ... on LayoutFeatureIconRight {
          name
          description {
            json
          }
          headline
          iconName
          color
        }
      }
    }
    className
  }
`
