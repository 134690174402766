import React from 'react'
import gql from 'graphql-tag'
import { IconType } from '../components/Icon/Icon.options'
import Button, { ButtonSize, ButtonVariant } from '../components/Button'
import { Color } from '../color.enum'
import { Link } from '@reach/router'

export interface LinkProps {
  label: string
  to?: string | undefined
  variant: ButtonVariant
  icon?: IconType
  isExternal: boolean
  color: Color
  size: ButtonSize
  destination: { name: string; slug: string }
}

const ButtonComponent: React.FC<LinkProps> = ({ label, variant, icon, color, size }) => {
  return (
    <Button variant={variant} color={color} size={size} iconRight={icon}>
      {label}
    </Button>
  )
}

const LinkWrapper: React.FC<LinkProps> = (props) => {
  const { label, to, isExternal, destination } = props
  return isExternal ? (
    <a className="inline-block" href={to} title={label}>
      <ButtonComponent {...props} />
    </a>
  ) : to ? (
    <Link className="inline-block" to={to}>
      <ButtonComponent {...props} />
    </Link>
  ) : destination ? (
    <Link className="inline-block" to={`/${destination.slug}`}>
      <ButtonComponent {...props} />
    </Link>
  ) : null
}

export default LinkWrapper

export const LINK_FIELDS = gql`
  fragment LinkFields on Links {
    name
    label
    to
    variant
    icon
    size
    color
    destination {
      name
      slug
    }
    isExternal
  }
`
