import { RouteComponentProps, useParams, useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery, gql } from '@apollo/client'
import { Loader } from '@plusplusminus/plusplusdash'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { Color } from '../../color.enum'
import { formatDate } from '../../services/formatDate'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../common/i18n'
import Button, { ButtonSize, ButtonVariant } from '../../components/Button'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import { User } from '../../common/types'
interface SingleUserProps extends RouteComponentProps {
  user?: User
}

const SingleUserView: React.FC<SingleUserProps> = (props) => {
  const { id } = useParams()

  const { loading, data } = useQuery(GET_A_USER, { variables: { id } })
  const navigate = useNavigate()
  const { user } = props
  if (loading) return <Loader isActive={true} />

  const getBreadCrumbs = () => {
    return [
      { name: 'Dashboard', path: user?.isSuperAdmin ? '/dashboard/admin/' : '/dashboard/organisation', current: false },
      {
        name: 'Users',
        path: user?.isSuperAdmin ? '/dashboard/admin/users/' : '/dashboard/organisation/users',
        current: true
      }
    ]
  }

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.user.singleUserView.title}</title>
        <meta name="description" content={language.en.admin.user.singleUserView.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      {!loading && data && (
        <>
          <header>
            <BreadCrumbs className=" pb-10" pages={getBreadCrumbs()} />
            <HeadingPage
              headline={`${data?.user?.firstName} ${data?.user?.lastName}`}
              color={Color.GRAY}
              description="See user profile below."
            />
          </header>

          <HeadingSection
            headline="User Profile"
            color={Color.ORANGE}
            children={() => {
              return (
                <div className="flex items-center">
                  <Button
                    className="justify-self-center self-end"
                    variant={ButtonVariant.PRIMARY}
                    color={Color.PURPLE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      navigate(`/dashboard/organisation/${data.user.id}/edit-user`)
                    }}
                  >
                    Edit Profile
                  </Button>
                </div>
              )
            }}
          />
          <div className="mt-5 space-y-1 text-gray-50">
            <div>
              <span className="inline text-gray-50 font-medium">Joined on:</span>
              <span className="ml-3">{formatDate(data.user.createdAt)}</span>
            </div>
            <div className="mb-3">
              <p className="inline text-gray-50 font-medium">Email Address:</p>
              <span className="ml-3">{data.user.email}</span>
            </div>

            <div>
              <span className="inline text-gray-50 font-medium">Organisation:</span>
              <span className="ml-3">{data.user.organization ? data.user.organization.name : '-'}</span>
            </div>
            <div>
              <span className="inline text-gray-50 font-medium">Role:</span>
              <span className="ml-3 ">{data.user.role}</span>
            </div>
            <div>
              <span className="inline text-gray-50 font-medium">Peer:</span>
              <span className="ml-3 ">{data?.user?.organization?.peer ? data.user.organization.peer.name : '-'}</span>
            </div>
          </div>
          <Scans respondent={data?.user?.respondent} />
        </>
      )}
    </div>
  )
}

interface ScanProps {
  respondent?: any
}

const Scans: React.FC<ScanProps> = ({ respondent }) => {
  const navigate = useNavigate()

  return (
    <div className="mt-8">
      <HeadingSection headline="Scans" color={Color.ORANGE} />
      {respondent.length ? (
        respondent.map((res: any) => {
          const { assignment } = res
          return (
            <div>
              <span className="inline text-gray-50 font-medium">Scan Name:</span>
              <span className="ml-3 ">
                <Button
                  variant={ButtonVariant.PLAIN}
                  color={Color.ORANGE}
                  size={ButtonSize.LARGE}
                  onClick={() => navigate(`/dashboard/admin/scans/${assignment.id}`)}
                >
                  {assignment.name}
                </Button>
              </span>
            </div>
          )
        })
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            No scans assigned.
          </Alert>
        </div>
      )}
    </div>
  )
}

export default SingleUserView

const GET_A_USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      email
      firstName
      lastName
      role
      organization {
        name
        peer {
          name
        }
      }
      isSuperAdmin
      respondent {
        id
        assignment {
          id
          name
        }
      }
    }
  }
`
