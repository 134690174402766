import { RouteComponentProps, useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { Color } from '../../color.enum'
import { User } from '../../common/types'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import RespondentAssignmentList from './RespondentListItems'
import { language } from '../../common/i18n'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'

interface WelcomeContainerProps extends RouteComponentProps {
  user?: User
}

export const RespondentDashboard: React.FC<WelcomeContainerProps> = () => {
  const navigate = useNavigate()

  const { loading, data } = useQuery(GET_ALL_USERS_ASSIGNMENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.userAssignments.length === 1) {
        const respondent = res?.userAssignments[0]
        navigate(`/survey/${respondent.id}/${respondent.assignment.id}`)
      }
    }
  })

  if (loading) return <Loader color={Color.PURPLE} />

  const { userAssignments } = data

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.respondent.dashboard.title}</title>
        <meta name="description" content={language.en.respondent.dashboard.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <header>
        <HeadingPage
          headline={language.en.respondent.dashboard.title}
          color={Color.ORANGE}
          description={language.en.respondent.dashboard.description}
        />
      </header>
      <main>
        <HeadingSection headline="Scans" color={Color.ORANGE} />
        {userAssignments.length ? (
          <RespondentAssignmentList assignments={userAssignments} />
        ) : (
          <div className="w-full">
            <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
              You do not have any scans assigned. Please contact the organisation representative.
            </Alert>
          </div>
        )}
      </main>
    </div>
  )
}

const GET_ALL_USERS_ASSIGNMENTS = gql`
  query GetAllUserAssignments {
    userAssignments {
      id
      postSurveyStatus
      surveyStatus
      user {
        email
        firstName
      }
      assignment {
        id
        name
        dueDate
        status
        survey {
          id
        }
      }
    }
  }
`
