import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { Badge } from '@plusplusminus/plusplusdash'
import { formatDate } from '../../../services/formatDate'
import { navigate } from '@reach/router'
import { ASSIGNMENT_STATUS } from '../../../common/constants'

interface ItemMetaData {
  dueDate: Date
  status?: string
  hasExpired: boolean
}

interface ItemCTA {
  status: string
  respondentId?: string
  assignmentId?: string
  hasExpired: boolean
}

const getBadge = (status: any, hasExpired: boolean) => {

  if(hasExpired){
    return <Badge color="red">Expired</Badge>
  }

  switch (status) {
    case ASSIGNMENT_STATUS.PENDING:
      return <Badge color="blue">Pending</Badge>
    case ASSIGNMENT_STATUS.STARTED:
      return <Badge color="yellow">Continue</Badge>
    case ASSIGNMENT_STATUS.COMPLETED:
      return <Badge color="green">Results</Badge>
    default:
      return <Badge color="red">Error</Badge>
  }
}

const getAction = (status: any, hasExpired: boolean, respondentId?: string, assignmentId?: string, ) => {
  if(hasExpired){
    return null
  }
  switch (status) {
    case 'PENDING':
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/survey/${respondentId}/${assignmentId}`)}
            iconRight="arrowRight"
          >
            Start
          </Button>
        </div>
      )
    case 'STARTED':
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/survey/${respondentId}/${assignmentId}`)}
            iconRight="arrowRight"
          >
            Continue
          </Button>
        </div>
      )
    case 'COMPLETED':
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/survey/${assignmentId}/result`)}
            iconRight="arrowRight"
          >
            Results
          </Button>
        </div>
      )
  }
}

export const ListItemCta: React.FC<ItemCTA> = ({ status, respondentId, assignmentId, hasExpired }) => {
  return <div className="flex items-center space-x-2">{getAction(status, hasExpired, respondentId, assignmentId)}</div>
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ dueDate, status, hasExpired }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">
        Due Date: <time dateTime={'2020-01-07'}>{formatDate(dueDate)}</time>
      </p>
      <p className="mt-1 flex items-center text-sm text-gray-500">{getBadge(status, hasExpired)}</p>
    </div>
  )
}
