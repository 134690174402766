import { useContext, useEffect } from 'react'
import { Link, navigate, RouteComponentProps, useMatch } from '@reach/router'
import { Context } from '../../context/context'
import logo from '../../../public/OSP.png'
import { useUserQuery } from '../../hooks/useUserQuery'
import Button, { ButtonVariant, ButtonSize } from '../Button'
import { Color } from '../../color.enum'
import { timeBeforeExpiry } from '../../services/formatDate'
import Header from '../Header/Header'
import ButtonNotice from '../ButtonNotice'
import { useCheckLogin } from '../../hooks/useCheckLogin'
import HeaderNavItemWrapper from '../HeaderNavItem/HeaderNavItemWrapper'

export const roleMenuItems = (user?: any, assignmentExpire?: string, timeOfExpiration?: string) => {
  const items: any = {
    admin: {
      buttonProps: {
        buttonType: 'standard',
        size: ButtonSize.SMALL,
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        iconLeft: 'signIn',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('/login')
        }
      },
      items: [
        { label: 'Dashboard', to: '/dashboard/admin', description: 'View a list of all users', hidden: true },
        {
          label: 'Users',
          to: '/dashboard/admin/users',
          description: 'View list of all registered users',
          hidden: false
        },
        {
          label: 'Organisations',
          to: '/dashboard/peer/organisations',
          description: 'View a list of all organisations',
          hidden: false
        },
        { label: 'Peers', to: '/dashboard/admin/peers', description: 'Add/Edit/View peers', hidden: false },
        { label: 'Surveys', to: '/dashboard/admin/surveys', description: 'Add/Edit/View surveys', hidden: false },
        { label: 'Reports', to: '/dashboard/admin/reports', description: 'Download csv reports', hidden: false },
        { label: 'Partner Scans', to: '/dashboard/peer/scans', description: '', hidden: true },
        { label: 'My Org Scans', to: '/dashboard/scans/organisation', description: '', hidden: true },
        { label: 'My Scans', to: '/dashboard/scans/my-scans', description: '', hidden: true }
      ],
      title: 'Admin Dashboard',
      heading: 'Dashboard'
    },
    peer: {
      buttonProps: {
        buttonType: 'standard',
        size: ButtonSize.SMALL,
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        iconLeft: 'signIn',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('/login')
        }
      },
      items: [
        { label: 'Dashboard', to: '/dashboard/peer', description: '', hidden: true },
        {
          label: 'Organisations',
          to: '/dashboard/peer/organisations',
          description: 'View a list of all organisations',
          hidden: false
        },
        { label: 'Partner Scans', to: '/dashboard/peer/scans', description: 'Add/Edit/View partner scans', hidden: false },
        { label: 'My Org Scans', to: '/dashboard/scans/organisation', description: `Complete setup of my organisation's scans`, hidden: false },
        { label: 'My Scans', to: '/dashboard/scans/my-scans', description: 'Participate in scans', hidden: false }
      ],
      title: 'Peer Dashboard',
      heading: 'Dashboard'
    },
    organization_owner: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: timeOfExpiration ? timeOfExpiration : '',
        description: assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('/login')
        }
      },
      items: [
        { label: 'Dashboard', to: '/dashboard/organisation', description: '', hidden: true },
        {
          label: 'Users',
          to: `/dashboard/organisation/users`,
          description: 'Add participants to scan',
          hidden: false
        },
        { label: 'My Scans', to: '/dashboard/scans/my-scans', description: '', hidden: true }
      ],
      title: 'Organisation Dashboard',
      heading: 'Dashboard'
    },
    respondent: {
      buttonProps: {
        buttonType: 'notice',
        icon: 'clock',
        color: Color.PURPLE,
        variant: ButtonVariant.PRIMARY,
        buttonLabel: 'Sign out',
        buttonIcon: 'signOut',
        headline: timeOfExpiration ? timeOfExpiration : '',
        description: assignmentExpire ? 'left to complete' : '',
        onClick: () => {
          localStorage.removeItem('osp-token')
          navigate('/login')
        }
      },
      items: []
    }
  }

  if (user.isSuperAdmin) {
    return items.admin
  } else if (user.isPeer) {
    return items.peer
  } else if (user.isOrgLeader) {
    return items.organization_owner
  } else {
    return items.respondent
  }
}

export const MainNav: React.FC<RouteComponentProps> = () => {
  const { contextValue } = useContext(Context)
  const { loading, data, refetch } = useUserQuery(false)
  const isLoggedIn = useCheckLogin() && data?.me
  const matchHome = useMatch('/')

  useEffect(() => {
    refetch()
  }, [])

  if (loading) return null

  const assignmentExpire = timeBeforeExpiry(contextValue?.dueDate)
  const timeOfExpiration = assignmentExpire
    ? `${assignmentExpire.day}d ${assignmentExpire.hour}h ${assignmentExpire.minute}m`
    : ''

  const { items = [], buttonProps = null } = data?.me ? roleMenuItems(data?.me, assignmentExpire, timeOfExpiration) : []

  return (
    <Header
      renderLogo={
        <Link to="/">
          <img className="block h-6 md:h-9 w-auto" src={logo} alt="OSP home page" />
        </Link>
      }
      renderQuickLinks={
        <>
          {buttonProps && isLoggedIn ? (
            <>
              {buttonProps.buttonType === 'standard' && <Button {...buttonProps}>{buttonProps.buttonLabel}</Button>}
              {buttonProps.buttonType === 'notice' && (
                <ButtonNotice {...buttonProps}>{buttonProps.buttonLabel}</ButtonNotice>
              )}
            </>
          ) : null}

          {matchHome && !isLoggedIn && (
            <Button
              size={ButtonSize.SMALL}
              color={Color.PURPLE}
              variant={ButtonVariant.PRIMARY}
              iconLeft="signIn"
              onClick={() => {
                navigate('/login')
              }}
            >
              Sign in
            </Button>
          )}
        </>
      }
      renderNavigation={
        items.length && isLoggedIn ? (
          <>
            {items.map((item: any) => (
              <HeaderNavItemWrapper to={item.path} {...item}>
                {item.label}
              </HeaderNavItemWrapper>
            ))}
          </>
        ) : null
      }
    />
  )
}
