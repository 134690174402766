import { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { closestCenter, DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import List from '../../../../components/List'
import { ListItemCta, ListItemMeta } from './demographicQuestionListItems'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import { useHandleDrag } from '../../../../hooks/useHandleDrag'
import { SortableItem } from '../factors/SortableItem'

interface DemographicItemProps {
  questions: any[]
  surveyId: string
  type: string
  demoId: string
  surveyStatus?: string
  refetch?: any
}

const DemographicQuestionList: React.FC<DemographicItemProps> = ({
  questions,
  surveyId,
  type,
  demoId,
  surveyStatus,
  refetch
}) => {
  const indices = questions.map((f: any, index: any) => String(index + 1))
  const [orderHasChanged, sensors, handleDragEnd, items] = useHandleDrag(indices)
  const [updateQuestionOrder] = useMutation(UPDATE_QUESTION_ORDER)
  const [updatePostQuestionOrder] = useMutation(UPDATE_POST_QUESTION_ORDER)

  useEffect(() => {
    if (orderHasChanged) {
      const newOrder = calculateQuestionOrder()

      if (type === 'participant') {
        updatePostQuestionOrder({
          variables: {
            orderInput: newOrder
          }
        })
      } else {
        updateQuestionOrder({
          variables: {
            orderInput: newOrder
          }
        })
      }
    }
  }, [items])

  const getQuestion = (index: any) => {
    const question = questions[index - 1]
    if (question) {
      const optionCount = type === 'org' ? question.preSurveyOption.length : question.postSurveyOption.length
      return (
        <ListItem
          color={Color.BLUE}
          headline={question.text}
          renderMeta={<ListItemMeta optionsNumber={optionCount} responseType={question.responseType}/>}
          renderCta={() => (
            <ListItemCta
              color={Color.BLUE}
              id={question.id}
              surveyId={surveyId}
              type={type}
              demoId={demoId}
              refetch={refetch}
              surveyStatus={surveyStatus}
            />
          )}
        />
      )
    } else {
      return null
    }
  }

  const calculateQuestionOrder = () => {
    return items
      .map((item: any, index: number) => {
        const question = questions[item - 1]
        if (question) {
          return { id: question.id, position: index + 1 }
        }
      })
      .filter((a: any) => a)
  }

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <List color={Color.BLUE} className="mb-10">
            {(): JSX.Element => {
              return (
                <>
                  {items.map((id: any) => (
                    <SortableItem key={id} id={id}>
                      <div>{getQuestion(id)}</div>
                    </SortableItem>
                  ))}
                </>
              )
            }}
          </List>
        </SortableContext>
      </DndContext>
    </>
  )
}

export default DemographicQuestionList

const UPDATE_QUESTION_ORDER = gql`
  mutation updatePreSurveyQuestionOrder($orderInput: [OrderInput!]!) {
    updatePreSurveyQuestionOrder(orderInput: $orderInput)
  }
`

const UPDATE_POST_QUESTION_ORDER = gql`
  mutation updatePostSurveyQuestionOrder($orderInput: [OrderInput!]!) {
    updatePostSurveyQuestionOrder(orderInput: $orderInput)
  }
`
