import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  surveyId: string
  type: string
  demoId: string
  surveyStatus?: string
  refetch?: any
}

interface ItemMetaData {
  optionsNumber: string
  responseType: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({ color, id, surveyId, type, demoId, surveyStatus, refetch }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deletePostSurveyQuestion, { loading: pLoading }] = useMutation(DELETE_POSTSURVEY_QUESTION, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      refetch()
      setIsOpen(false)
    }
  })

  const handleCloseRemoveQuestion = () => {
    setIsOpen(false)
  }

  const handleRemoveQuestion = (id: string) => {
    deletePostSurveyQuestion({
      variables: {
        id: id
      }
    })
  }
  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveQuestion}
        handleConfirm={() => handleRemoveQuestion(id)}
        loading={pLoading}
      />
      {surveyStatus !== 'PUBLISHED' && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          Remove Question
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        iconRight="arrowRight"
        onClick={() =>
          navigate(
            `/dashboard/admin/survey/${surveyId}/${
              type === 'org' ? 'preSurvey' : 'postSurvey'
            }/${demoId}/question/${id}`
          )
        }
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ optionsNumber, responseType }: ItemMetaData) => {
  const responseTypeMap: any = {
    options: `${optionsNumber} Options`,
    year: 'Year',
    country: 'Country',
    custom: 'Custom input'
  }

  return (
    <div>
      <p className="text-sm text-gray-900">
        {responseTypeMap[responseType] ? responseTypeMap[responseType] : `${optionsNumber} Options`}
      </p>
    </div>
  )
}

const DELETE_POSTSURVEY_QUESTION = gql`
  mutation deletePostSurveyQuestion($id: String!) {
    deletePostSurveyQuestion(id: $id) {
      status
      message
    }
  }
`
