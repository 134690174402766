import React from 'react'
import { RouteComponentProps,useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import { DemographicForm } from './DemographicSurveyForm'
import { User } from '../../../common/types'

interface DemographicSurveyProps extends RouteComponentProps {
  assignmentId: string
  user?: User
  setStep: (step: string) => void
  scanId?: string
}

const DemographicSurvey: React.FC<DemographicSurveyProps> = ({ setStep, scanId }) => {
  const navigate = useNavigate()
  const { loading, data } = useQuery(GET_POST_SURVEYS, {
    variables: { assignmentId: scanId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { questionnaire } = data
      if (questionnaire.status === 'COMPLETED') {
        navigate(`/dashboard/organisation/scan/${scanId}/confirmation`)
      }
    }
  })

  if (loading && !data) return <Loader color={Color.PURPLE} />

  const { questionnaire } = data

  return (
    <div className="page-container-lg py-20">
      <HeadingPageCenter
        className="text-center"
        headline={"Organisation's Demographic Details"}
        color={Color.ORANGE}
        description={"Please tell us more about your organisation"}
      />
      <div className="section-container-sm">
        <DemographicForm
          questions={questionnaire.questions}
          demographicSurveyId={questionnaire.id}
          assignmentId={scanId}
          setStep={setStep}
        />
      </div>
    </div>
  )
}

export { DemographicSurvey }

const GET_POST_SURVEYS = gql`
  query postSurveyQuery($assignmentId: String!) {
    questionnaire {
      id
      name
      description
      headline
      status(assignmentId: $assignmentId)
      questions {
        id
        text
        code
        responseType
        postSurveyOption {
          id
          archived
          text
          index
          code
        }
      }
    }
  }
`
