import List from '../../../../components/List'
import { ListItemCta, ListItemMeta } from './PeerScanListItem'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import Loader from '../../../../components/Loader/Loader'
interface Assignment {
  dueDate: Date
  id: string
  name: string
  status: string
  respondentCount: number
  preSurveyStatus: string
  respondentProfile: {
    id: string
    surveyStatus: string
  }
  isOwner: boolean
  organization: any
}

interface AssignmentItem {
  headline: string
  orgName?: string
  color?: Color
  renderMeta?: JSX.Element
  renderStatus?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

interface ScanListProps {
  assignments: Assignment[]
  loading: boolean
  organizationId: string | undefined
}

const ScanList: React.FC<ScanListProps> = ({ assignments, loading }) => {
  if (loading) return <Loader color={Color.PURPLE} />

  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return (
        <>
          {assignments?.map((assignment) => {
            const item: AssignmentItem = {
              headline: assignment.name,
              orgName: assignment?.organization?.name,
              renderMeta: <ListItemMeta dueDate={assignment.dueDate} status={assignment.status} />,
              renderCta: (color: Color): JSX.Element => {
                let step = 'add-participants'
                if (assignment.respondentCount > 0 && assignment.preSurveyStatus !== 'COMPLETED') {
                  step = 'pre-survey'
                } else if (assignment.respondentCount > 0 && assignment.status !== 'COMPLETED') {
                  step = 'confirmation'
                }

                return (
                  <ListItemCta
                    color={color}
                    assignmentStatus={assignment.status}
                    questionnaireStatus="no-status"
                    assignmentId={assignment.id}
                    respondentId={assignment?.respondentProfile?.id}
                    respondentSurveyStatus={assignment?.respondentProfile?.surveyStatus}
                  />
                )
              }
            }
            return <ListItem color={color} {...item} type="scan" />
          })}
        </>
      )
    }
  }

  return <List {...items} className="mb-10" />
}

export default ScanList
