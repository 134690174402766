import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import HeadingPage from '../../../components/HeadingPage'
import HeadingSection from '../../../components/HeadingSection'
import { Color } from '../../../color.enum'
import { formatDate } from '../../../services/formatDate'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../common/i18n'
import Button, { ButtonSize, ButtonVariant} from '../../../components/Button'
import { User } from '../../../common/types'

interface SingleScanProps extends RouteComponentProps {

  user?: User
}

const SingleScanView: React.FC<SingleScanProps> = (props) => {
  const { id } = useParams()
  const { loading, data } = useQuery(GET_A_SCAN, { variables: { id }, fetchPolicy: 'no-cache' })
  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard/admin/', current: false },
    { name: 'Scans', path: `/dashboard/admin/assessments`, current: true }
  ]

  const navigate = useNavigate()

  const editDueDate = () => {

    navigate(`/dashboard/peer/scans/${id}/edit`, {
      state: {
         currentDate: data?.assignment.dueDate,
      }
    });
  }


  if (loading) return <Loader color={Color.PURPLE} />
  return (
    <div className="page-container-lg lg:mb-40">
      <Helmet>
        <title>{language.en.admin.user.singleUserView.title}</title>
        <meta name="description" content={language.en.admin.user.singleUserView.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <header>
        <BreadCrumbs className=" pb-10" pages={breadCrumbPath} />
        <HeadingPage headline={`${data?.assignment?.name}`} color={Color.GRAY} description="See scan details below" />
      </header>

      <HeadingSection
        headline="Scan details"
        color={Color.ORANGE}
        children={() => {
          return data.assignment.status !== 'EXPIRED' && props?.user?.role === 'peer' ? (
            <Button variant={ButtonVariant.PRIMARY} color={Color.ORANGE} size={ButtonSize.SMALL} onClick={editDueDate}>
              Edit Due Date
            </Button>
          ) : (
            <span></span>
          )
        }}
      />
      <div className="mt-5 space-y-1">
        <div>
          <span className="inline text-white font-medium">Created on:</span>
          <span className="ml-4 text-white">{formatDate(data.assignment.createdAt)}</span>
        </div>
        <div className="mb-3">
          <p className="inline text-white font-medium">Due Date:</p>
          <span className="ml-4 text-white">{formatDate(data.assignment.dueDate)} </span>
        </div>
        <div className="mb-3">
          <p className="inline text-white font-medium">Status :</p>
          <span className="ml-4 text-white">{data.assignment.status}</span>
        </div>
        <div className="mb-3">
          <p className="inline text-white font-medium">Respondent Count :</p>
          <span className="ml-4 text-white">{data.assignment.respondentCount}</span>
        </div>
      </div>
    </div>
  )
}

export default SingleScanView

const GET_A_SCAN = gql`
  query Assignment($id: String!) {
    assignment(id: $id) {
      id
      name
      createdAt
      dueDate
      preSurveyStatus
      respondentCount
      status
    }
  }
`
