import React from 'react'
import gql from 'graphql-tag'
import { Content } from '../components'
import RichTextRenderer from './RichTextRenderer'
import { Content as ContentTypes } from '../common/types'
import { STYLE_TEXT_FIELDS } from './StyleTextWrapper'

export interface BlockquoteProps {
  headline?: string
  cite?: string
  content?: ContentTypes
}

const BlockquoteWrapper: React.FC<BlockquoteProps> = ({ headline, cite, content }) => {
  return (
    <blockquote>
      {headline && <span>{headline}</span>}
      {content && (
        <Content>
          <RichTextRenderer content={content} />
        </Content>
      )}
      {cite && <small>{cite}</small>}
    </blockquote>
  )
}

export default BlockquoteWrapper

export const BLOCKQUOTE_FIELDS = gql`
  ${STYLE_TEXT_FIELDS}
  fragment BlockquoteFields on LayoutBlockquote {
    sys {
      id
    }
    headline
    cite
    content {
      json
      links {
        entries {
          inline {
            sys {
              id
            }
            __typename
            ... on StyleText {
              ...StyleTextFields
            }
          }
          block {
            sys {
              id
            }
            __typename
          }
        }
      }
    }
  }
`
