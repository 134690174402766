import { Items } from '../pages/Users/UsersTable'

export const useFormatRoles: any = () => {
  const formattedRole = (user: Items) => {
    const roles = {
      admin: 'Admin',
      peer: 'Peer',
      organization_owner: 'Organisation Owner',
      respondent: 'Respondent'
    }

    const rolesArr = []

    if (user.role === 'respondent') {
      rolesArr.push(roles.respondent)
    } else {
      const { isSuperAdmin, isPeer, isOrgLeader } = user
      if (isSuperAdmin) {
        rolesArr.push(roles.admin)
      }
      if (isPeer) {
        rolesArr.push(roles.peer)
      }
      if (isOrgLeader) {
        rolesArr.push(roles.organization_owner)
      }
    }

    return rolesArr.toString()
  }

  return [formattedRole]
}
