import { Box } from '@plusplusminus/plusplusdash'
import { language } from '../../common/i18n'

interface SelectProps {
  name: string
  items?: OptionInterface[]
  register: any
  value?: string
  validations?: any
}

export interface OptionInterface {
  id: string | number
  name: string | number
}

export const Select: React.FC<SelectProps> = ({ name, items, register, value, validations }) => {
  return (
    <Box>
      <select
        className="w-full py-3 px-3 text-base text-white bg-brand-blue-light border-2 border-gray-200 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all"
        {...register(name, {...validations})}
        defaultValue={value}
      >
        <option value="" selected>
          {language.en.components.select.defaultText}
        </option>
        {items?.map((item: any) => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </Box>
  )
}