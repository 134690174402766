import { Link, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React from 'react'
import { EditorContent, EntityItem, EntityMap, InlineStyle } from './types'
import theme from '../../theme'

const styles: Record<string, Style> = {
  'header-one': {
    fontSize: 18
  },
  'header-two': {
    fontSize: 16
  },
  'header-three': {
    fontSize: 14
  },
  'header-four': {
    fontSize: 12
  }
}

function collectStyles(
  inlineStyleRanges: Array<InlineStyle>,
  entityRanges: Array<EntityItem>,
  entityMap: EntityMap
): Array<InlineStyle & EntityItem> {
  const collected = {} as Record<string, InlineStyle & EntityItem>

  for (const inlineStyle of inlineStyleRanges) {
    const { offset, length, style } = inlineStyle
    const key = `offset${offset}-length${length}`
    if (collected[key]) {
      collected[key].style = collected[key].style + ' ' + style
    } else {
      collected[key] = inlineStyle
    }
  }

  for (const e of entityRanges) {
    const { offset, length, key: entityKey } = e
    const key = `offset${offset}-length${length}`
    if (typeof entityKey === 'number') {
      const entity = entityMap[entityKey]
      if (collected[key]) {
        const props = collected[key].props
        if (entity.type === 'LINK') {
          collected[key].props = { ...props, src: entity.data.url }
        }
      } else {
        collected[key] = { ...e, props: { src: entity.data.url } }
      }
    }
  }

  return Object.values(collected)
}

export default function draftToPdf(editorContent: EditorContent): React.ReactNode {
  const { blocks, entityMap } = editorContent
  const nodes = []
  let listIndex = 1
  for (const block of blocks) {
    const lines = block.text
      .split('\n')
      .map((val) => ({ content: [{ text: val, style: {}, props: {} as Record<string, any> | undefined }] }))
    const inlineStyles = collectStyles(block.inlineStyleRanges, block.entityRanges, entityMap)
    for (const line of lines) {
      for (const inlineStyle of inlineStyles) {
        const { style, props } = inlineStyle
        const isBold = style && style.includes('BOLD')
        const isUnderline = style && style.includes('UNDERLINE')
        const styleObj = Object.assign(
          {},
          isBold && { fontWeight: 'medium' },
          isUnderline && { textDecoration: 'underline' }
        )
        const content = block.text.slice(inlineStyle.offset, inlineStyle.offset + inlineStyle.length)
        let idx = 0
        let newContent = [...line.content]
        for (const c of line.content) {
          if (c.text.includes(content)) {
            const start = c.text.indexOf(content)
            const beforeText = c.text.slice(0, start)
            const afterText = c.text.slice(start + inlineStyle.length)

            newContent = [
              ...newContent.slice(0, idx),
              ...(beforeText ? [{ text: beforeText, style: {}, props: {} }] : []),
              { text: content, style: styleObj, props },
              ...(afterText ? [{ text: afterText, style: {}, props: {} }] : [])
            ]
            break
          }
          idx++
        }
        line.content = newContent
      }
    }
    const view = []
    for (const line of lines) {
      const children = []
      for (const c of line.content) {
        if (c.props && c.props.src) {
          children.push(
            <Link src={c.props.src}>
              <Text style={[c.style, { color: theme.colors['brand-blue'], width: '100%' }]}>{c.text}</Text>
            </Link>
          )
        } else {
          children.push(<Text style={c.style}>{c.text}</Text>)
        }
      }

      let prefix
      if (block.type === 'ordered-list-item') {
        prefix = <Text style={{ fontSize: 10, marginRight: 8, lineHeight: 1.5 }}>{listIndex++}.</Text>
      } else if (block.type === 'unordered-list-item') {
        prefix = (
          <View
            style={{
              height: 4,
              width: 4,
              marginRight: 8,
              marginTop: 4,
              backgroundColor: 'black',
              borderRadius: '100%'
            }}
          />
        )
      }

      const baseStyle = { fontSize: 10, lineHeight: 1.5, width: '100%' }
      const additionalStyles = styles[block.type]

      view.push(
        <View style={{ flexDirection: 'row' }} wrap={false}>
          {prefix}
          <Text style={{ ...baseStyle, ...additionalStyles }}>{children}</Text>
        </View>
      )
    }
    nodes.push(<View style={{ marginBottom: 12 }}>{view}</View>)
  }
  return nodes
}
