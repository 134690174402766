export const useBreadcrumbs: any = () => {
  const getBreadCrumbs = (
    page: string,
    surveyId?: string,
    factorId?: string,
    demoType?: string,
    demoId?: string,
    questionId?: string
  ) => {
    const surveyBreadCrumbs = [
      { name: 'Dashboard', path: '/dashboard/admin', current: false },
      { name: 'All Surveys', path: '/dashboard/admin/surveys', current: false },
      { name: `Survey`, path: `/dashboard/admin/survey/${surveyId}`, current: true }
    ]

    switch (page) {
      case 'survey':
        return surveyBreadCrumbs
      case 'factor':
        return [...surveyBreadCrumbs, { name: 'Dimension', path: '#', current: false }]
      case 'statement':
        return [
          ...surveyBreadCrumbs,
          { name: 'Dimension', path: `/dashboard/admin/survey/${surveyId}/factor/${factorId}`, current: false },
          { name: 'Statement', path: '#', current: false }
        ]
      case 'factorType':
        return [...surveyBreadCrumbs, { name: 'Archetype', path: '#', current: false }]
      case 'demographic':
        return [...surveyBreadCrumbs, { name: demoType, path: '#', current: false }]
      case 'demographicQuestion':
        return [
          ...surveyBreadCrumbs,
          {
            name: demoType,
            path: `/dashboard/admin/survey/${surveyId}/demographic-survey/${demoId}/${
              demoType === 'Organisation Questionaires' ? 'org' : 'participant'
            }`,
            current: false
          },
          { name: 'Question', path: '#', current: false }
        ]
      case 'demographicQuestionOption':
        return [
          ...surveyBreadCrumbs,
          {
            name: demoType,
            path: `/dashboard/admin/survey/${surveyId}/demographic-survey/${demoId}/${
              demoType === 'Organisation Questionaires' ? 'org' : 'participant'
            }`,
            current: false
          },
          {
            name: 'Question',
            path: `/dashboard/admin/survey/${surveyId}/${
              demoType === 'Organisation Questionaires' ? 'preSurvey' : 'postSurvey'
            }/${demoId}/question/${questionId}`,
            current: false
          },
          { name: 'Option', path: '#', current: false }
        ]
      default:
        []
    }
  }

  return [getBreadCrumbs]
}
