import React from 'react'
import { useState } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import { Color } from '../color.enum'
import Bar from '../components/Bar'
import Button, { ButtonSize, ButtonVariant } from '../components/Button'
import Card, { CardVariant } from '../components/Card'
import Modal from '../components/Modal/Modal'
import Radar, { api as radarApi, DimensionProps } from '../components/Radar'

const Results: React.FC<RouteComponentProps> = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<DimensionProps>(radarApi[0].dimensions[0])

  const onClick = (activeItem: DimensionProps) => {
    setIsOpen(true)
    setActiveItem(activeItem)
  }

  return (
    <>
      <div className="py-20">
        <main>
          <div className="page-container-lg">
            <Card
              variant={CardVariant.STANDARD}
              subtitle="Proverbs 31 Ministries"
              headline="Thank you, David"
              media={<Radar archetypes={radarApi} />}
            >
              <div className="mt-10">
                <div className="prose">
                  <p>
                    You have completed the OSP. You can review and download your report below. The full OSP results for
                    your organisation will be emailed to you once all the participants have completed it.
                  </p>
                  <p>
                    <strong>Sustaining Factors</strong>
                    <br />
                    Sustaining factors are the "load bearing beams" of a highly effective organisation, required to
                    achieve exceptional levels of mission impact.
                  </p>
                </div>
                <div className="my-4">
                  {radarApi[1].dimensions.map((i) => {
                    return (
                      <>
                        <Bar
                          key={i.name}
                          headline={i.name}
                          scorePercentage={i.score}
                          color={radarApi[1].color}
                          className="mb-6"
                          onClick={() => onClick(i)}
                        />
                      </>
                    )
                  })}
                </div>
              </div>
              <div className="mt-10 mb-6">
                <div className="prose">
                  <p>
                    <strong>Supporting Factors</strong>
                    <br />
                    Supporting factors are those capacity elements required for execution against the mission.
                  </p>
                </div>
                <div className="my-4">
                  {radarApi[0].dimensions.map((i) => {
                    return (
                      <Bar
                        key={i.name}
                        headline={i.name}
                        scorePercentage={i.score}
                        color={radarApi[0].color}
                        className="mb-6"
                        onClick={() => onClick(i)}
                      />
                    )
                  })}
                </div>
                <Link to="/report" target="_blank">
                  <Button
                    size={ButtonSize.LARGE}
                    variant={ButtonVariant.PRIMARY}
                    color={Color.BLUE}
                    iconLeft="download"
                  >
                    Download report
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
        </main>
      </div>
      <Modal isModalOpen={isOpen} title={activeItem.name} onClose={() => setIsOpen(false)}>
        <div className="prose">{activeItem.description}</div>
      </Modal>
    </>
  )
}

export default Results
