import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { navigate } from '@reach/router'

const userQueryGQL = gql`
  query user {
    me {
      id
      firstName
      lastName
      email
      isPeer
      isOrgLeader
      isSuperAdmin
      isRespondent
      channel {
        id
      }
      organization {
        id
        name
      }
      peer {
        id
        name
      }
    }
  }
`

//TODO:add proper return type
// eslint-disable-next-line
export const useUserQuery = (redirect = true, location = '') => {
  return useQuery(userQueryGQL, {
    onError: (err) =>
      redirect && err?.graphQLErrors[0]?.message === 'Unauthorized'
        ? navigate(location ? `/login?redirectTo=${location}` : '/login')
        : console.log(err?.graphQLErrors[0])
  })
}
