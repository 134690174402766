import { RouteComponentProps, useParams, useNavigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import HeadingPage from '../../../components/HeadingPage'
import HeadingSection from '../../../components/HeadingSection'
import { Color } from '../../../color.enum'
import { formatDate } from '../../../services/formatDate'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import { language } from '../../../common/i18n'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import Loader from '../../../components/Loader/Loader'
import { GET_PEER_OWNER_DETAILS } from '../../../graphql/queries'

const SinglePeerView: React.FC<RouteComponentProps> = () => {
  const { id } = useParams()
  const { loading, data } = useQuery(GET_PEER_OWNER_DETAILS, { variables: { peerId: id }, fetchPolicy: 'no-cache' })
  const navigate = useNavigate()

  const breadCrumbPath = [
    { name: 'Dashboard', path: '/dashboard/admin/', current: false },
    { name: 'Peers', path: '/dashboard/admin/peers', current: true }
  ]

  if (loading) return <Loader color={Color.PURPLE} />

  const { getPeerOwner } = data

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.dashBoard.peers.singlePeerView.title}</title>
        <meta name="description" content={language.en.admin.dashBoard.peers.singlePeerView.description} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      {!loading && data && (
        <>
          <header>
            <BreadCrumbs className=" pb-10" pages={breadCrumbPath} />
            <HeadingPage
              headline={`${getPeerOwner.firstName} ${getPeerOwner.lastName}`}
              color={Color.GRAY}
              description={language.en.admin.dashBoard.peers.singlePeerView.title}
            />
          </header>

          <HeadingSection
            headline="Peer Profile"
            color={Color.ORANGE}
            children={() => {
              return (
                <div className="flex items-center">
                  <Button
                    className="justify-self-center self-end"
                    variant={ButtonVariant.PRIMARY}
                    color={Color.PURPLE}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      navigate(`/dashboard/admin/${getPeerOwner.peer.id}/edit-peer`)
                    }}
                  >
                    {language.en.admin.dashBoard.peers.singlePeerView.buttons.editPeer}
                  </Button>
                </div>
              )
            }}
          />
          <div className="mt-5 space-y-1">
            <div>
              <span className="inline text-white font-medium">
                {language.en.admin.dashBoard.peers.singlePeerView.labels.joinedOn}
              </span>
              <span className="ml-1 text-gray-50">{formatDate(getPeerOwner.peer.createdAt)}</span>
            </div>
            <div>
              <span className="inline text-white font-medium">
                {language.en.admin.dashBoard.peers.singlePeerView.labels.country}
              </span>
              <span className="ml-1 text-gray-50">{getPeerOwner.peer.country}</span>
            </div>
            <div className="mb-3">
              <p className="inline text-white font-medium">
                {language.en.admin.dashBoard.peers.singlePeerView.labels.email}
              </p>
              <span className="ml-1 text-gray-50">{getPeerOwner.email}</span>
            </div>

            <div>
              <span className="inline text-white font-medium">
                {language.en.admin.dashBoard.peers.singlePeerView.labels.organisation}
              </span>
              <span className="ml-1 text-gray-50">{getPeerOwner.peer.name}</span>
            </div>
            <div>
              <span className="inline text-white font-medium">
                {language.en.admin.dashBoard.peers.singlePeerView.labels.role}
              </span>  
              <span className="ml-1 text-gray-50">Peer owner</span>       
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SinglePeerView
