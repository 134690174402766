import List from '../../../components/List'
import { ListItemCta, ListItemMeta } from './surveyListItems'
import ListItem from '../../../components/ListItem'
import { Color } from '../../../color.enum'
import { SurveyStatus } from '../../../common/types'

interface Survey {
  createdAt: Date
  id: string
  name: string
  status: SurveyStatus
  locale: any
}

interface SurveyItem {
  headline: string
  color?: Color
  renderMeta?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

interface SurveyItemProps {
  surveys: Survey[],
  refetch?: any
}

const SurveyList: React.FC<SurveyItemProps> = ({ surveys, refetch }) => {
  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return (
        <>
          {surveys?.map((survey) => {                 
            const item: SurveyItem = {
              headline: survey.name,
              renderMeta: (
                <ListItemMeta createdAt={survey.createdAt} status={survey.status} locale={survey.locale.name} />
              ),
              renderCta: (color: Color): JSX.Element => {
                return <ListItemCta color={color} id={survey.id} refetch={refetch} status={survey.status}/>
              }
            }
            return <ListItem color={color} {...item} />
          })}
        </>
      )
    }
  }

  return <List {...items} className="mb-10" />
}

export default SurveyList
