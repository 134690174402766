import { gql } from '@apollo/client'
import { BLOCKQUOTE_FIELDS } from '../../../modules/BlockquoteWrapper'
import { STYLE_TEXT_FIELDS } from '../../../modules/StyleTextWrapper'

export const RESULTS_CONTENT_QUERY = gql`
  query ResultsContent($contentIdentifier: String!) {
    assetPdfCollection(where: { identifier: "report-worksheet" }) {
      items {
        name
        label
        file {
          url
        }
      }
    }

    contentCollection(where: { identifier: $contentIdentifier }) {
      items {
        headline
        content {
          json
        }
      }
    }
  }
`

export const REPORT_TEMPLATE_QUERY = gql`
  query ReportTemplateQuery($slug: String!) {
    reportCollection(limit: 1, where: { slug: $slug }) {
      items {
        name
        layoutCollection(limit: 20) {
          items {
            __typename
            ... on Content {
              sys {
                id
              }
              headline
              content {
                json
                links {
                  entries {
                    inline {
                      sys {
                        id
                      }
                      __typename
                      ... on StyleText {
                        ...StyleTextFields
                      }
                      ... on AssetPdf {
                        label
                        file {
                          url
                        }
                      }
                    }
                    block {
                      sys {
                        id
                      }
                      __typename
                      ... on LayoutBlockquote {
                        ...BlockquoteFields
                      }
                    }
                  }
                  assets {
                    block {
                      sys {
                        id
                      }
                      url
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on Results {
              sys {
                id
              }
              headline
              content {
                json
              }
            }
          }
        }
      }
    }
  }
  ${BLOCKQUOTE_FIELDS}
  ${STYLE_TEXT_FIELDS}
`
