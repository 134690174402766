import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RemoveItemModal from '../components/RemoveItemModal'

interface ItemCtaData {
  color: Color
  id: string
  surveyId?: string
  surveyStatus?: string
  refetch?: any
  hideRemoveButton?: boolean
}

interface ItemMetaData {
  statementCount: string
}

export const ListItemCta: React.FC<ItemCtaData> = ({
  color,
  id,
  surveyId,
  surveyStatus,
  refetch,
  hideRemoveButton = false
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteFactorType, { loading }] = useMutation(DELETE_FACTOR_TYPE, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: () => {
      refetch()
      setIsOpen(false)
    }
  })

  const handleCloseRemoveFactorType = () => {
    setIsOpen(false)
  }

  const handleRemoveFactorType = (id: string) => {
    deleteFactorType({
      variables: {
        id: id
      }
    })
  }
  return (
    <div className="flex space-x-2">
      <RemoveItemModal
        isOpen={isOpen}
        handleClose={handleCloseRemoveFactorType}
        handleConfirm={() => handleRemoveFactorType(id)}
        loading={loading}
      />
      {surveyStatus !== 'PUBLISHED' && !hideRemoveButton && (
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL} onClick={() => setIsOpen(true)}>
          {loading ? 'Removing Archetype...' : 'Remove Archetype'}
        </Button>
      )}
      <Button
        variant={ButtonVariant.PRIMARY}
        color={color}
        size={ButtonSize.SMALL}
        onClick={() => navigate(`/dashboard/admin/survey/${surveyId}/factorType/${id}`)}
        iconRight="arrowRight"
      >
        View details
      </Button>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ statementCount }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">{statementCount}</p>
    </div>
  )
}

const DELETE_FACTOR_TYPE = gql`
  mutation deleteFactorType($id: String!) {
    deleteFactorType(id: $id) {
      status
      message
    }
  }
`
