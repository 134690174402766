import { RouteComponentProps, useParams } from '@reach/router'
import UserList from './UsersTable'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import HeadingPage from '../../components/HeadingPage'
import { Color } from '../../color.enum'
import HeadingSection from '../../components/HeadingSection'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import {Helmet} from 'react-helmet';
import { language } from '../../common/i18n'

const PeerUsers: React.FC<RouteComponentProps> = () => {
  const {id} = useParams()
  const { loading, error, data, refetch } = useQuery(GET_PEER_USERS, { fetchPolicy: 'no-cache', variables:{id}})
  const breadCrumbPath = [{ name: 'Users', path: '#', current: true }]
  return (
    <div className="page-container-lg lg:mb-36">
      <BreadCrumbs pages={breadCrumbPath} className="pl-0 pb-10" />
      <header>
        <Helmet>
          <title>{language.en.admin.user.list.title}</title>
          <meta name="description" content={language.en.admin.user.list.description} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        <HeadingPage headline={'Users'} color={Color.GRAY} />
      </header>
      <HeadingSection headline={language.en.admin.dashBoard.users.headline} color={Color.ORANGE} />

      {loading && <Loader color={Color.PURPLE} className="h-20 flex flex-col items-center justify-center" />}
      {!loading && !error && <UserList items={data?.peerUsers} refetch={refetch} loading={loading} />}
    </div>
  )
}
export default PeerUsers

const GET_PEER_USERS = gql`
  query PeerUsers($id: String!) {
    peerUsers(peerId: $id) {
      id
      firstName
      lastName
      email
      role
      organization {
        name
      }
    }
  }
`
