import React from 'react'
import { Link, RouteComponentProps, Match } from '@reach/router'
import HeaderNavItem from './HeaderNavItem'

export interface HeaderNavItemWrapperProps extends RouteComponentProps {
  className?: string
  to: string
  label: string
  getProps?: any
}

const HeaderNavItemWrapper: React.FC<HeaderNavItemWrapperProps> = ({ label, to, className, ...rest }) => {
  return (
    <Match path={to}>
      {({ match }) => (
        <Link to={to}>
          <HeaderNavItem className={className} isActive={match ? true : false} label={label} />
        </Link>
      )}
    </Match>
  )
}

export default HeaderNavItemWrapper
