import { useEffect } from 'react'
import { Box, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import Button, { ButtonSize, ButtonVariant } from '../components/Button'
import { Color } from '../color.enum'
import RichTextInput from '../components/RichTextInput'
import { Select } from '../components/Select/Select'
import { Form } from '../common/types'
import { FormTypes } from '../common/constants'
import FormLabel from '../components/FormLabel/FormLabel'
import DatePicker from '../components/DatePicker/DatePicker'

interface FormProps extends Form {
  onSubmit: (data: any, type: FormTypes) => void
  loading: boolean
  buttonLabel: string
  formType: FormTypes
  data?: any
  customDorpDownValues?: {
    parent: string
    values: FieldValues[]
    defaultValue?: any
  }[]
}

interface FieldValues {
  id: string
  name: string
}

export const FormWrapper: React.FC<FormProps> = ({
  form,
  loading,
  onSubmit,
  buttonLabel,
  formType,
  data,
  customDorpDownValues
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (formType === FormTypes.edit && data) {
      Object.keys(data).forEach((field) => {
        if (data[field]?.__typename) {
          setValue(field, data[field].id)
        } else {
          setValue(field, data[field])
        }
      })
    }
  }, [])

  const onRegister = async (data: any) => {
    if (data) {
      await onSubmit(data, formType)
    }
  }

  return (
    <div className="flex flex-col">
      <form
        action="#"
        autoComplete="no"
        onSubmit={handleSubmit(onRegister)}
        className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
      >
        {form.map((field) => {
          const { className } = field

          if (field.type === 'datePicker') {
            return (
              <div className={className ? className : 'col-span-2 sm:col-span-2'} key={field.name}>
                <FormLabel className="text-base font-medium text-brand-purple my-1 inline-block">
                  {field.label}
                </FormLabel>
                <DatePicker
                  selectedDate={field.date?.value}
                  selectDate={field.date?.value}
                  setDateError={field.date?.setDateError}
                />
                {field.description && <div className="text-xs py-1 text-white">{field.description}</div>}
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </div>
            )
          }

          if (field.type === 'text') {
            return (
              <Box className={className ? className : 'col-span-2 sm:col-span-2'} key={field.name}>
                <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                <Input
                  className="py-3 px-3 text-base text-white bg-brand-blue-light border border-gray-500 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all"
                  as="input"
                  variant="standard"
                  min={1}
                  width="full"
                  type={field.type}
                  {...register(field.name, { ...field.options })}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'number') {
            return (
              <Box className={className ? className : 'col-span-2 sm:col-span-2'} key={field.name}>
                <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                <Input
                  className="py-3 px-3 text-base text-white bg-brand-blue-light border border-gray-500 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all"
                  as="input"
                  variant="standard"
                  min={1}
                  width="full"
                  type={field.type}
                  {...register(field.name, { ...field.options })}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'checkBox') {
            return (
              <div hidden={field.hidden}>
                <Box className={className ? className : 'flex items-center col-span-2'}>
                  <input
                    id={field.name}
                    type="checkbox"
                    className="h-4 w-4 text-brand-purple focus:ring-blue-900 border-gray-300 rounded"
                    checked={field?.checked}
                    {...register(field.name, { ...field.options })}
                  />
                  <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </Box>
              </div>
            )
          }

          if (field.type === 'textarea') {
            return (
              <Box className={className ? className : 'col-span-2 sm:col-span-2 mt-4'}>
                <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                <Input
                  as="textarea"          
               
                  variant="standard"
                  width="full"
                  {...register(field.name, { ...field.options })}
                  className="py-3 px-3 text-base text-white bg-brand-blue-light border border-gray-500 rounded-sm focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all h-36"
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'select') {
            const options = customDorpDownValues?.find((fv: any) => fv.parent === field.name)
            const items = options ? options.values : field.items
            return (
              <Box className={className ? className : 'col-span-2 sm:col-span-2'} key={field.name}>
                <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                <Select
                  items={items}
                  name={field.name}
                  validations={field.options}
                  register={register}
                  value={options?.defaultValue}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }

          if (field.type === 'richText') {
            
            return (
              <Box className={className ? className : 'col-span-2 sm:col-span-2'} key={field.name}>
                <FormLabel className="text-base font-medium my-1 inline-block">{field.label}</FormLabel>
                <RichTextInput
                  setValue={setValue}
                  defaultValue={data && data[field.name]}
                  {...register(field.name, { ...field.options })}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          }
        })}
        <div className="col-span-2">
          <Button
            className="justify-center mb-2"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.ORANGE}
            style={{ width: '100%' }}
            type="submit"
          >
            {loading ? 'Loading' : buttonLabel}
          </Button>
        </div>
      </form>
    </div>
  )
}
