import React from 'react'
import Button, { ButtonSize, ButtonVariant } from '../Button'
import { Color } from '../../color.enum'
import { language } from '../../common/i18n'
import './styles.css'

interface TablePaginationProps {
  page?: number
  limit?: number
  nextPage?: () => void
  prevPage?: () => void
  totalPages?: number
  hasPrevPage?: boolean
  hasNextPage?: boolean
}

const TablePagination: React.FC<TablePaginationProps> = ({
  page,
  nextPage,
  prevPage,
  totalPages,
  hasNextPage,
  hasPrevPage
}) => {
  return (
    <div className="grid grid-cols-3 gap-3" hidden={!hasPrevPage}>
      <Button
        iconLeft="arrowLeft"
        className="justify-center"
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.SMALL}
        color={!hasPrevPage ? Color.GRAY: Color.ORANGE}
        style={{ width: '50%' }}
        onClick={prevPage}
        disabled={!hasPrevPage}
      >
        {language.en.admin.dashBoard.buttonText.previous}
      </Button>

      <div className="flex justify-center">
        <p className="text-brand-orange">
          {language.en.admin.dashBoard.buttonText.page}
          <span className="font-medium text-brand-orange">{` ${page} `}</span>
          {language.en.admin.dashBoard.buttonText.of} {totalPages}
        </p>
      </div>
      <div className="flex justify-end">
        <Button
          iconRight="arrowRight"
          className="justify-center"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          color={!hasNextPage ? Color.GRAY: Color.ORANGE}
          style={{ width: '50%' }}
          onClick={nextPage}
          disabled={!hasNextPage}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default TablePagination
