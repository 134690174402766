export const formatDate = (dateString: Date) => {
    const options: Intl.DateTimeFormatOptions = { month: "long", day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString));
  }

export const timeBeforeExpiry = (expiryDate: any) => {

  if(!expiryDate){
    return 
  }

  let timeDifference = Math.abs((<any>new Date(expiryDate) - <any>(new Date())))

  const timeToExpiry:any = {}

  const seconds: any = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,


  }

  Object.keys(seconds).forEach(function(key){
    timeToExpiry[key] = Math.floor(timeDifference / seconds[key]);
    timeDifference -= timeToExpiry[key] * seconds[key];
  });
  console.log(timeToExpiry)
  return timeToExpiry
}
