import { Color } from '../../color.enum'

export const api = [
  {
    name: 'Supporting Factors',
    dimensions: [
      {
        id: 1,
        name: 'Impactful Strategy',
        score: 51,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              In the MCI, “Competent Leadership” focuses on the attitudes, competencies, and spiritual character of the
              chief executive, but also addresses the broader area of ministry leadership (i.e., presence of a
              leadership team, leaders at every level accountable to lead as servant leaders, etc.).
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              Most organisational effectiveness experts agree that the single most important variable for organisational
              impact and success is competent leadership.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jesus speaks directly to this topic only a few times, but those instances contain His view of the
              non-negotiables of leadership, and they set the tone for the rest of the Bible’s emphasis on the subject.
              One of the most striking examples was during the last supper (Luke 22:24-30) at which He declares: “…the
              greatest among you become as the youngest, and the leader as one who serves.”
            </p>
          </>
        )
      },
      {
        id: 2,
        name: 'Sound Resourcing Plan',
        score: 85,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              Effective ministry governance starts with the fact that there is an established governance board in place,
              and that it is staffed with capable and competent members who are totally aligned with organisation’s
              intended mission. The board understands its role and demonstrates a mindset of stewardship and engagement.
              It demonstrates the willingness and ability to hold the Chief Executive accountable for organisational
              performance, personal character, and spiritual leadership, and has established a thoughtful and
              comprehensive succession strategy for both planned and unplanned leadership transitions.
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              It is important that ministries see governance as an act of biblical stewardship rather than merely a
              legal obligation. Stewardship requires accountability. Key stakeholders cannot—and don’t want to—monitor
              every major decision a ministry makes. Thus, they rely on a group of committed directors who can do so
              with discernment and wisdom.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jerusalem, 48 AD. In Acts 15, we find Paul and Barnabas in Antioch, having just completed their first
              missionary journey. While there, some men arrived from Judea and “began teaching the young converts that
              unless they were circumcised, according to the custom of Moses, [they] could not be saved.” Paul and
              Barnabas did not stand idly, by ignoring the challenge. The text explains that there was “great dissention
              and debate” with these interlopers. Paul and Barnabas were sent by the Antioch church to Jerusalem to see
              the “apostles and elders” about this issue. It is clear that, although not called a board, these men to
              whom they were sent were considered the ultimate authority in this young organisation called the Church.
              In Galatians 2, Paul described them as “men of reputation” and “pillars.” This sounds very much like a
              board to us.
            </p>
          </>
        )
      },
      {
        id: 3,
        name: 'Relevant Measures',
        score: 66,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              In the MCI, “Competent Leadership” focuses on the attitudes, competencies, and spiritual character of the
              chief executive, but also addresses the broader area of ministry leadership (i.e., presence of a
              leadership team, leaders at every level accountable to lead as servant leaders, etc.).
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              Most organisational effectiveness experts agree that the single most important variable for organisational
              impact and success is competent leadership.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jesus speaks directly to this topic only a few times, but those instances contain His view of the
              non-negotiables of leadership, and they set the tone for the rest of the Bible’s emphasis on the subject.
              One of the most striking examples was during the last supper (Luke 22:24-30) at which He declares: “…the
              greatest among you become as the youngest, and the leader as one who serves.”
            </p>
          </>
        )
      },
      {
        id: 4,
        name: 'Reliable Operations',
        score: 87,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              Effective ministry governance starts with the fact that there is an established governance board in place,
              and that it is staffed with capable and competent members who are totally aligned with organisation’s
              intended mission. The board understands its role and demonstrates a mindset of stewardship and engagement.
              It demonstrates the willingness and ability to hold the Chief Executive accountable for organisational
              performance, personal character, and spiritual leadership, and has established a thoughtful and
              comprehensive succession strategy for both planned and unplanned leadership transitions.
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              It is important that ministries see governance as an act of biblical stewardship rather than merely a
              legal obligation. Stewardship requires accountability. Key stakeholders cannot—and don’t want to—monitor
              every major decision a ministry makes. Thus, they rely on a group of committed directors who can do so
              with discernment and wisdom.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jerusalem, 48 AD. In Acts 15, we find Paul and Barnabas in Antioch, having just completed their first
              missionary journey. While there, some men arrived from Judea and “began teaching the young converts that
              unless they were circumcised, according to the custom of Moses, [they] could not be saved.” Paul and
              Barnabas did not stand idly, by ignoring the challenge. The text explains that there was “great dissention
              and debate” with these interlopers. Paul and Barnabas were sent by the Antioch church to Jerusalem to see
              the “apostles and elders” about this issue. It is clear that, although not called a board, these men to
              whom they were sent were considered the ultimate authority in this young organisation called the Church.
              In Galatians 2, Paul described them as “men of reputation” and “pillars.” This sounds very much like a
              board to us.
            </p>
          </>
        )
      }
    ],
    color: Color.PURPLE
  },
  {
    name: 'Sustaining Factors',
    dimensions: [
      {
        id: 5,
        name: 'Competent Leadership',
        score: 73,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              In the MCI, “Competent Leadership” focuses on the attitudes, competencies, and spiritual character of the
              chief executive, but also addresses the broader area of ministry leadership (i.e., presence of a
              leadership team, leaders at every level accountable to lead as servant leaders, etc.).
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              Most organisational effectiveness experts agree that the single most important variable for organisational
              impact and success is competent leadership.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jesus speaks directly to this topic only a few times, but those instances contain His view of the
              non-negotiables of leadership, and they set the tone for the rest of the Bible’s emphasis on the subject.
              One of the most striking examples was during the last supper (Luke 22:24-30) at which He declares: “…the
              greatest among you become as the youngest, and the leader as one who serves.”
            </p>
          </>
        )
      },
      {
        id: 6,
        name: 'Effective Governance',
        score: 51,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              Effective ministry governance starts with the fact that there is an established governance board in place,
              and that it is staffed with capable and competent members who are totally aligned with organisation’s
              intended mission. The board understands its role and demonstrates a mindset of stewardship and engagement.
              It demonstrates the willingness and ability to hold the Chief Executive accountable for organisational
              performance, personal character, and spiritual leadership, and has established a thoughtful and
              comprehensive succession strategy for both planned and unplanned leadership transitions.
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              It is important that ministries see governance as an act of biblical stewardship rather than merely a
              legal obligation. Stewardship requires accountability. Key stakeholders cannot—and don’t want to—monitor
              every major decision a ministry makes. Thus, they rely on a group of committed directors who can do so
              with discernment and wisdom.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jerusalem, 48 AD. In Acts 15, we find Paul and Barnabas in Antioch, having just completed their first
              missionary journey. While there, some men arrived from Judea and “began teaching the young converts that
              unless they were circumcised, according to the custom of Moses, [they] could not be saved.” Paul and
              Barnabas did not stand idly, by ignoring the challenge. The text explains that there was “great dissention
              and debate” with these interlopers. Paul and Barnabas were sent by the Antioch church to Jerusalem to see
              the “apostles and elders” about this issue. It is clear that, although not called a board, these men to
              whom they were sent were considered the ultimate authority in this young organisation called the Church.
              In Galatians 2, Paul described them as “men of reputation” and “pillars.” This sounds very much like a
              board to us.
            </p>
          </>
        )
      },
      {
        id: 7,
        name: 'Clear Mission',
        score: 87,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              In the MCI, “Competent Leadership” focuses on the attitudes, competencies, and spiritual character of the
              chief executive, but also addresses the broader area of ministry leadership (i.e., presence of a
              leadership team, leaders at every level accountable to lead as servant leaders, etc.).
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              Most organisational effectiveness experts agree that the single most important variable for organisational
              impact and success is competent leadership.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jesus speaks directly to this topic only a few times, but those instances contain His view of the
              non-negotiables of leadership, and they set the tone for the rest of the Bible’s emphasis on the subject.
              One of the most striking examples was during the last supper (Luke 22:24-30) at which He declares: “…the
              greatest among you become as the youngest, and the leader as one who serves.”
            </p>
          </>
        )
      },
      {
        id: 8,
        name: 'Healthy Culture',
        score: 91,
        description: (
          <>
            <p>
              <strong>What it is</strong>
              <br />
              Effective ministry governance starts with the fact that there is an established governance board in place,
              and that it is staffed with capable and competent members who are totally aligned with organisation’s
              intended mission. The board understands its role and demonstrates a mindset of stewardship and engagement.
              It demonstrates the willingness and ability to hold the Chief Executive accountable for organisational
              performance, personal character, and spiritual leadership, and has established a thoughtful and
              comprehensive succession strategy for both planned and unplanned leadership transitions.
            </p>
            <p>
              <strong>Why it matters</strong>
              <br />
              It is important that ministries see governance as an act of biblical stewardship rather than merely a
              legal obligation. Stewardship requires accountability. Key stakeholders cannot—and don’t want to—monitor
              every major decision a ministry makes. Thus, they rely on a group of committed directors who can do so
              with discernment and wisdom.
            </p>
            <p>
              <strong>Where it is in Scripture</strong>
              <br />
              Jerusalem, 48 AD. In Acts 15, we find Paul and Barnabas in Antioch, having just completed their first
              missionary journey. While there, some men arrived from Judea and “began teaching the young converts that
              unless they were circumcised, according to the custom of Moses, [they] could not be saved.” Paul and
              Barnabas did not stand idly, by ignoring the challenge. The text explains that there was “great dissention
              and debate” with these interlopers. Paul and Barnabas were sent by the Antioch church to Jerusalem to see
              the “apostles and elders” about this issue. It is clear that, although not called a board, these men to
              whom they were sent were considered the ultimate authority in this young organisation called the Church.
              In Galatians 2, Paul described them as “men of reputation” and “pillars.” This sounds very much like a
              board to us.
            </p>
          </>
        )
      }
    ],
    color: Color.BLUE
  }
]
