import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import Icon from '../Icon/Icon'

interface StepsProps {
  /** Headline for the component */
  headline?: string
  /** The steps involved */
  steps: Array<StepProps>
  /** What is the active dimension/factor? */
  activeDimension: number
  /** Aditional Styles for the container */
  className?: string
}

export const COLOR_MAPS: Record<Color, StepVariantProps> = {
  [Color.BLUE]: {
    step: 'bg-brand-orange border-brand-orange after:border-brand-orange',
    active: 'bg-brand-orange, text-white',
    icon: 'text-brand-orange'
  },
  [Color.PURPLE]: {
    step: 'bg-brand-purple border-brand-purple after:border-brand-purple',
    active: 'bg-brand-purple text-white',
    icon: 'text-white'
  },
  [Color.GRAY]: {
    step: 'bg-gray-500 border-gray-500 after:border-gray-500',
    active: 'bg-gray-500 text-white',
    icon: 'text-gray-800'
  },
  [Color.ORANGE]: {
    step: 'bg-brand-orange border-brand-orange after:border-brand-orange',
    active: 'bg-brand-orange, text-white',
    icon: 'text-white'
  },
  [Color.RED]: {
    step: 'bg-brand-red border-brand-red after:border-brand-orange',
    active: 'bg-brand-red text-white',
    icon: 'text-white'
  },
}

interface StepProps {
  title: string
  isComplete: boolean
  color: Color
}
interface StepVariantProps {
  step: string
  active: string
  icon: string
}

/**
 *  Component to show Steps and progress
 */

const Steps: React.FC<StepsProps> = ({ headline, className, steps = [], activeDimension }: StepsProps) => {
  
  return (
    <div className={classNames(className, 'Steps flex flex-col')}>
      {headline && <p className="font-medium tracking-widest uppercase text-brand-orange">{headline}</p>}
      <div className=" flex items-center">
        {steps.map(({ title, isComplete, color }, index) => {
          const isActive = index === activeDimension
          const isFutureStep = index > activeDimension
          return (
            <>
              <div
                className={classNames(
                  'Step flex flex-none relative box-content border w-8 h-8 rounded-full justify-center items-center after:absolute after:w-4 after:h-[1px] after:transition-all after:max-w-4 after:right-8 after:bg-brand-blue first-of-type:after:hidden after:md:block after:hidden',
                  isFutureStep && 'opacity-50',
                  isActive ? (COLOR_MAPS[color].active, 'opacity-70 mr-2 ') : 'opacity-100 mr-4 bg-transparent',
                  isComplete || !isActive ? 'hidden md:flex' : '',
                  COLOR_MAPS[color].step
                )}
              >
                <span
                  className={classNames(
                    `NumberIcon block ${COLOR_MAPS[color].icon}`,
                    isActive && COLOR_MAPS[color].active
                  )}
                >
                  <p className={classNames('text-sm')}>
                    {isComplete ? <Icon name="checkSolid" fontAwesomeIconProps={{ size: '1x' }} /> : index + 1}
                  </p>
                </span>
              </div>

              <div
                className={classNames(
                  'StepNameContainer flex items-center justify-center overflow-hidden transition-all',
                  isActive ? 'mr-4 max-w-full' : 'mr-0 max-w-0 '
                )}
              >
                <p
                  className={classNames(
                    `StepName text-sm font-medium whitespace-normal leading-4 ${COLOR_MAPS[color].icon}`,
                    isActive && 'mr-4'
                  )}
                >
                  {title}
                </p>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Steps
