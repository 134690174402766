import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { useLocation } from '@reach/router'
import Icon from '../../../../components/Icon/Icon'
import { Badge } from '@plusplusminus/plusplusdash'
import { formatDate } from '../../../../services/formatDate'
import { navigate } from '@reach/router'
import { User } from '../../../../common/types'

interface ItemCtaData {
  color: Color
  assignmentStatus: string
  assignmentId: string
  questionnaireStatus: string
  respondentId?: string
  respondentSurveyStatus?: string
  respondentCount?: number
  user?: User
}

interface ItemMetaData {
  dueDate: Date
  status?: string
  questionnaireStatus?: any
}

export const ListItemCta: React.FC<ItemCtaData> = ({
  color,
  assignmentStatus,
  assignmentId,
  respondentCount
}: ItemCtaData) => {

  const { pathname } = useLocation()
  return (
    <div className="flex flex-col">   
      {getRespondentAssignmentAction(assignmentStatus, assignmentId, color)}
      {respondentCount === 0 && pathname.endsWith('organisation') && assignmentStatus !== 'EXPIRED' && (
        <div>
          <Button
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/dashboard/organisation/scan/${assignmentId}/add-participants`)}
            className="mr-3"
            iconRight="arrowRight"
          >
            Add Participants
          </Button>
        </div>
      )}
      <div>
        {!['COMPLETED', 'EXPIRED', 'CANCELLED'].includes(assignmentStatus) && (
          <Button
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/dashboard/organisation/scan/${assignmentId}/add-participants`)}
            className="mr-3"
            iconRight="arrowRight"
          >
            Edit participants
          </Button>
        )}
      </div>   
    </div>
  )
}

export const ListItemStatus: React.FC<ItemMetaData> = ({ dueDate }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">
        Status <time dateTime={'2020-01-07'}>{formatDate(dueDate)}</time>
      </p>
      <p className="mt-1 flex items-center text-sm text-gray-500">
        <Badge color="green">
          <Icon name="checkSolid" className="mr-2" /> Completed
        </Badge>
      </p>
    </div>
  )
}

export const ListItemMeta: React.FC<ItemMetaData> = ({ dueDate, status, questionnaireStatus }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">
        Due date: <time dateTime={'2020-01-07'}>{formatDate(dueDate)}</time>
      </p>
      <p className="mt-1 flex items-center text-sm text-gray-500">{getBadge(status, questionnaireStatus)}</p>
    </div>
  )
}

const getBadge = (status: any, questionnaireStatus?: any) => {
  switch (status) {
    case 'COMPLETED':
      return <Badge color="green">COMPLETED</Badge>
    case 'PUBLISHED':
      if (questionnaireStatus === 'PENDING') {
        return <Badge color="blue">DRAFT</Badge>
      } else {
        return <Badge color="blue">PUBLISHED</Badge>
      }

    case 'PENDING':
      return <Badge color="gray">DRAFT</Badge>
    case 'CANCELED':
      return <Badge color="red">CANCELED</Badge>
    case 'STARTED':
      return <Badge color="green">IN PROGRESS</Badge>
    case 'EXPIRED':
      return <Badge color="yellow">EXPIRED</Badge>
    default:
      return <Badge color="blue">{status}</Badge>
  }
}


const getRespondentAssignmentAction = (status: any, assignmentId?: string, color?: Color) => {
  switch (status) {
    case 'COMPLETED':
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.PLAIN}
            color={color || Color.BLUE}
            size={ButtonSize.SMALL}
            onClick={() => navigate(`/dashboard/organisation/${assignmentId}/result`)}
            className="mr-3"
            iconRight="arrowRight"
          >
            Assignment Results
          </Button>
        </div>
      )
  }
}
