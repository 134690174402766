export enum ButtonSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg'
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  PLAIN = 'plain'
}

export interface ButtonColorProps {
  primary: string
  secondary: string
  plain: string
}
