import { RouteComponentProps } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import OrganisationList from './index'

const AllOrganisations: React.FC<RouteComponentProps> = () => {
  const { loading, data, refetch } = useQuery(GET_ALL_ORGANISATIONS, { fetchPolicy: 'no-cache' })

  return (
    <div className="page-container-lg">
      <OrganisationList loading={loading} data={data} refetch={refetch}/>
    </div>
  )
}

export default AllOrganisations

const GET_ALL_ORGANISATIONS = gql`
  query Organisations($sort: SortArgs, $pagination: PaginationArgs, $input: QueryOrgsInput) {
    organizations(sort: $sort, pagination: $pagination, input: $input) {
      docs {
      name
      createdAt
      assignment {
        id
      }
      owner {
        id
        firstName
        lastName
      }
      user {
        id
      }
    }
    limit
      totalPages
      page
      hasPrevPage
      hasNextPage
  }
  }
`
