import { Box } from '@plusplusminus/plusplusdash'
import { useForm, Controller } from 'react-hook-form'
import Input from '../../../../../components/Input/Input'
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/Button'
import { Color } from '../../../../../color.enum'
import { gql, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import RichTextInput from '../../../../../components/RichTextInput'
import Loader from '../../../../../components/Loader/Loader'
import FormLabel from '../../../../../components/FormLabel/FormLabel'

const form = [
  {
    label: 'Question Code',
    name: 'code',
    placeholder: 'Question Code',
    options: {
      required: 'Question code is required'
    },
    type: 'text'
  },

  {
    label: 'Position',
    name: 'position',
    placeholder: 'Label',
    options: {
      required: 'Position is required'
    },
    type: 'number'
  },
  {
    label: 'Question',
    name: 'text',
    placeholder: 'Question',
    options: {
      required: 'Question is required'
    },

    type: 'textarea'
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Enter factor description here....',
    type: 'textarea'
  }
]

interface Props {
  onSubmit?: (data: any, type: string) => void
  loading?: boolean
  preSurveyQuestionId?: string
  formType: string
  createError?: any
}

export const CreateQuestionForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState:{errors}
  } = useForm()

  const [loadPreSurveyQuestionData, { loading, data }] = useLazyQuery(GET_SINGLE_PRE_SURVEY_QUESTION, {
    variables: { id: props.preSurveyQuestionId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { preSurveyQuestion } = data
      Object.keys(preSurveyQuestion).forEach((pq) => {
        setValue(pq, preSurveyQuestion[pq])
      })
    }
  })

  useEffect(() => {
    if (props?.preSurveyQuestionId) {
      loadPreSurveyQuestionData()
    }
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (loading) return <Loader color={Color.PURPLE} />

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        if (field.type === 'text' || field.type === 'number') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <FormLabel className="text-base font-medium  my-1 inline-block">
                {field.label}
              </FormLabel>
              <Controller
                rules={{ ...field.options }}
                name={field.name}
                control={control}
                render={({ field: f }) => <Input as="input" width="full" {...f} type={field.type} min={1}/>}
              />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }

        if (field.type === 'textarea') {
          return (
            <Box className="col-span-2 sm:col-span-2 mt-4">
               <FormLabel className="text-base font-medium  my-1 inline-block">
                {field.label}
              </FormLabel>
              <Controller
                rules={{ ...field.options }}
                name={field.name}
                control={control}
                render={({ field: f }) => <Input as="textarea" width="full" {...f} />}
              />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }

        if (field.type === 'richText') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
               <FormLabel className="text-base font-medium  my-1 inline-block">
                {field.label}
              </FormLabel>
              <RichTextInput
                placeholder={field.placeholder}
                name={field.name}
                setValue={setValue}
                defaultValue={data?.preSurveyQuestion[field.name]}
              />
            </Box>
          )
        }
      })}
      <div className="col-span-2">
        <Button
          className="justify-center mb-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          color={Color.ORANGE}
          style={{ width: '100%' }}
          type="submit"
        >
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}

const GET_SINGLE_PRE_SURVEY_QUESTION = gql`
  query preSurveyQuestion($id: String!) {
    preSurveyQuestion(id: $id) {
      text
      code
      position
      description
      id
      preSurveyOption {
        id
        text
        code
      }
    }
  }
`
