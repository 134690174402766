import { RouteComponentProps, useNavigate } from '@reach/router'
import UserList from './UsersTable'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../components/Loader/Loader'
import { Color } from '../../color.enum'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import { language } from '../../common/i18n'
import { PageWrapper } from '../../modules/PageWrapper'

const Users: React.FC<RouteComponentProps> = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_USERS, { fetchPolicy: 'no-cache' })
  const navigate = useNavigate()
  const breadCrumbPath = [{ name: 'Users', path: '#', current: true }]

  return (
    <PageWrapper
      headline={language.en.admin.dashBoard.users.title}
      sectionHeading={language.en.admin.dashBoard.users.headline}
      description={language.en.admin.user.list.description}
      metaTitle={language.en.admin.user.list.title}
      metaDesc={language.en.admin.user.list.description}
      breadCrumbs={breadCrumbPath}
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.PURPLE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/admin/add-user')}
              iconRight="plus"
            >
              {language.en.admin.dashBoard.buttonText.addUser}
            </Button>
          </div>
        )
      }}
    >
      {loading && <Loader color={Color.PURPLE} className="h-20 flex flex-col items-center justify-center" />}
      {!loading && !error && (
        <UserList
          items={data?.users?.docs}
          refetch={refetch}
          pagination={{
            currentPage: data.users.page,
            totalPages: data.users.totalPages,
            hasNextPage: data.users.hasNextPage,
            hasPrevPage: data.users.hasPrevPage
          }}
          loading={loading}
        />
      )}
    </PageWrapper>
  )
}
export default Users

const GET_ALL_USERS = gql`
  query Users($sort: SortArgs, $pagination: PaginationArgs, $input: QueryUsersInput) {
    users(sort: $sort, pagination: $pagination, input: $input) {
      docs {
        id
        firstName
        lastName
        email
        role
        isSuperAdmin
        isPeer
        isOrgLeader
        organization {
          name
        }
        organizations {
          name
        }
      }
      limit
      totalPages
      page
      hasPrevPage
      hasNextPage
    }
  }
`
