import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types'
import { Text, StyleSheet, View, Image, Link } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React, { PropsWithChildren } from 'react'
import theme from '../../../theme'
import Blockquote from '../Blockquote'

export interface RichTextProps {
  content: { links?: any; json: Document }
  styleOverrides?: Record<string, Style>
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.colors['brand-blue']
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
    width: '100%'
  },
  bottomSpace: {
    marginBottom: 18
  },
  bold: {
    fontWeight: 'medium'
  },
  superscript: {
    fontSize: 6,
    color: theme.colors.gray[600]
  }
})

type ListItemProps = { index?: number; isOrdered?: boolean }
const ListItem = (props: PropsWithChildren<ListItemProps>) => {
  const { index, children, isOrdered } = props
  return (
    <View style={{ flexDirection: 'row', alignItems: 'flex-start', width: '100%', position: 'relative' }} wrap={false}>
      {isOrdered ? <Text style={[styles.text, { flexBasis: 15 }]}>{index}.</Text> : <Dot />}
      <View style={{ flexGrow: 1, position: 'relative', width: '100%' }}>{children}</View>
    </View>
  )
}

const Dot = () => (
  <View style={{ height: 4, width: 4, marginRight: 8, marginTop: 4, backgroundColor: 'black', borderRadius: '100%' }} />
)

const options = (links: any, styleOverrides?: RichTextProps['styleOverrides']): Options => {
  const assetMap = new Map()
  const entryMap = new Map()

  if (links && links.entries) {
    if (links.assets) {
      for (const asset of links.assets.block) {
        assetMap.set(asset.sys.id, asset)
      }
    }
    for (const entry of links.entries.block) {
      entryMap.set(entry.sys.id, entry)
    }

    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry)
    }
  }

  return {
    renderMark: {
      [MARKS.BOLD]: (text: any) => <Text style={[styles.text, styles.bold]}>{text}</Text>
    },
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.OL_LIST]: (node, children) => {
        let currentIndex = 1
        return (
          <View style={styles.bottomSpace}>
            {React.Children.map(children, (child) =>
              React.isValidElement(child) ? React.cloneElement(child, { index: currentIndex++, isOrdered: true }) : null
            )}
          </View>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <View style={styles.bottomSpace}>
            {React.Children.map(children, (child) =>
              React.isValidElement(child) ? React.cloneElement(child, { isOrdered: false }) : null
            )}
          </View>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => <Text style={[styles.bold, { fontSize: 20 }]}>{children}</Text>,
      [BLOCKS.HEADING_2]: (node, children) => <Text style={[styles.bold, { fontSize: 18 }]}>{children}</Text>,
      [BLOCKS.HEADING_3]: (node, children) => <Text style={[styles.bold, { fontSize: 16 }]}>{children}</Text>,
      [BLOCKS.HEADING_4]: (node, children) => <Text style={[styles.bold, { fontSize: 14 }]}>{children}</Text>,
      [BLOCKS.HEADING_5]: (node, children) => <Text style={[styles.bold, { fontSize: 12 }]}>{children}</Text>,
      [BLOCKS.HEADING_6]: (node, children) => <Text style={[styles.bold, { fontSize: 10 }]}>{children}</Text>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text style={[styles.text, { marginBottom: 18 }, styleOverrides?.text ?? {}]}>{children}</Text>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const entry = entryMap.get(node.data.target.sys.id)

        if (entry.__typename === 'LayoutBlockquote') {
          return (
            <View style={{ marginBottom: 18 }}>
              <Blockquote {...entry} />
            </View>
          )
        }

        return null
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        const entry = entryMap.get(node.data.target.sys.id)

        if (entry.__typename === 'StyleText') {
          return <Text style={styles.superscript}>{entry.text}</Text>
        }

        if (entry.__typename === 'AssetPdf') {
          return (
            <Link src={entry.file.url} style={{ color: theme.colors['brand-blue'], textDecoration: 'underline' }}>
              <Text style={[styles.text]}>{entry.label}</Text>
            </Link>
          )
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const asset = assetMap.get(node.data.target.sys.id)
        const aspectRatio = asset.width / asset.height
        return (
          <View style={{ position: 'relative', alignItems: 'center', width: '100%' }}>
            <Image src={asset.url} style={{ height: 300, width: aspectRatio * 300 }} />
          </View>
        )
      }
    },

    renderText: (text: any) => text.replace('!', '?')
  }
}

const PDFRichTextRenderer = ({ content, styleOverrides }: RichTextProps) => {
  return <>{documentToReactComponents(content.json, options(content.links, styleOverrides))}</>
}

export default PDFRichTextRenderer
