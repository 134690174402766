import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql } from '@apollo/client'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import Button from '../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../components/Button'
import { language } from '../../../common/i18n'
import PeersList from './PeersTable'
import { PageWrapper } from '../../../modules/PageWrapper'

const Peers: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(GET_ALL_PEERS, { fetchPolicy: 'no-cache' })
  const breadCrumbPath = [{ name: 'Peers', path: '#', current: true }]

  return (
    <PageWrapper
      headline={language.en.admin.dashBoard.peers.headline}
      description={language.en.admin.peer.list.description}
      sectionHeading={language.en.admin.dashBoard.peers.title}
      metaTitle={language.en.admin.peer.list.title}
      metaDesc={language.en.admin.peer.list.description}
      breadCrumbs={breadCrumbPath}
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.PURPLE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/admin/add-peer')}
              iconRight="plus"
            >
              {language.en.admin.dashBoard.buttonText.addPeer}
            </Button>
          </div>
        )
      }}
    >
      {loading && <Loader color={Color.PURPLE} className="h-20 flex flex-col items-center justify-center" />}
      {!loading && !error && <PeersList items={data?.allPeerUsers} />}
    </PageWrapper>
  )
}
export default Peers

const GET_ALL_PEERS = gql`
  query Peers {
    allPeerUsers {
      firstName
      lastName
      role
      email
      organization {
        name
      }
      peer {
        id
        name
        country
      }
    }
  }
`
