import React from 'react'
import { Hero } from '../components'
import gql from 'graphql-tag'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Content } from '../common/types'
import LinkWrapper, { LINK_FIELDS, LinkProps } from './LinkWrapper'
import { Color } from '../color.enum'

interface HeroWrapperProps {
  headline?: string
  description?: Content
  content?: Content
  className?: string
  callToAction?: callToAction
  sys: { id: string }
}

interface callToAction {
  className?: string
  linksCollection?: { items: [LinkProps] }
}

const HeroWrapper: React.FC<HeroWrapperProps> = ({ headline, description, content, callToAction, className, sys }) => {
  return (
    <Hero
      className={className}
      headline1={headline}
      description={description && documentToReactComponents(description.json)}
      id={sys.id}
    >
      <>
        {content && documentToReactComponents(content.json)}
        {callToAction && callToAction.linksCollection && callToAction.linksCollection.items.length > 0 ? (
          <div className={callToAction.className}>
            {callToAction.linksCollection.items.map((i, index) => {
              return <LinkWrapper key={index} {...i} color={Color.ORANGE}/>
            })}
          </div>
        ) : null}
      </>
    </Hero>
  )
}

export default HeroWrapper

export const HERO_FIELDS = gql`
  ${LINK_FIELDS}
  fragment HeroFields on LayoutHero {
    sys {
      id
    }
    name
    description {
      json
    }
    headline
    content {
      json
    }
    className
    callToAction {
      className
      linksCollection(limit: 4) {
        items {
          ...LinkFields
        }
      }
    }
  }
`
