import { Color } from '../../color.enum'
import { ButtonSize, ButtonVariant, ButtonColorProps } from './types'

const defaultClassName = `inline-flex rounded-full transition-all items-center  font-regular hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue-light`

export const BUTTON_SIZE_MAPS: Record<ButtonSize, string> = {
  sm: 'px-4 py-1.5 text-xs',
  md: 'px-6 py-2 text-sm',
  lg: 'px-8 py-4 text-sm'
}

export const BUTTON_COLOR_MAPS: Record<Color, ButtonColorProps> = {

  [Color.ORANGE]: {
    [ButtonVariant.PRIMARY]: 'bg-brand-orange',
    [ButtonVariant.SECONDARY]: 'bg-white text-brand-orange hover:bg-brand-orange hover:text-white',
    [ButtonVariant.PLAIN]: 'text-brand-orange'
  },
  [Color.PURPLE]: {
    [ButtonVariant.PRIMARY]: 'bg-brand-purple',
    [ButtonVariant.SECONDARY]: 'text-brand-purple hover:bg-brand-purple hover:text-white',
    [ButtonVariant.PLAIN]: 'text-brand-purple'
  },
  // TODO
  [Color.GRAY]: {
    [ButtonVariant.PRIMARY]: 'bg-gray-500',
    [ButtonVariant.SECONDARY]: 'hover:bg-gray-50',
    [ButtonVariant.PLAIN]: 'text-gray-500'
  },
  [Color.BLUE]: {
    [ButtonVariant.PRIMARY]: 'bg-brand-blue',
    [ButtonVariant.SECONDARY]: 'text-brand-blue hover:bg-brand-blue hover:text-white',
    [ButtonVariant.PLAIN]: 'text-brand-blue'
  },
  [Color.RED]: {
    [ButtonVariant.PRIMARY]: 'bg-brand-red',
    [ButtonVariant.SECONDARY]: 'text-brand-red hover:bg-brand-red hover:text-white',
    [ButtonVariant.PLAIN]: 'text-brand-red'
  },
}

export const BUTTON_VARIANT_MAPS: Record<ButtonVariant, string> = {
  primary: 'text-white text-white shadow-sm border border-transparent',
  secondary: 'bg-white border border-gray-200 shadow-sm',
  plain: 'px-1 py-1 border-transparent bg-transparent rounded-none'
}

const disabled = 'opacity-60'

export const styles = {
  default: defaultClassName,
  disabled
}
