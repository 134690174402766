import { useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

interface DatePickerProps {
  selectedDate: any
  selectDate: (date: any) => void
  setDateError?: (error: string | null) => void
}

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, selectDate, setDateError }) => {
  const [focusedInput, setFocusedInput] = useState<any>(null)
  return (
    <div>
      <SingleDatePicker
        hideKeyboardShortcutsPanel={true}
        date={selectedDate}
        onDateChange={(date) => {
          setDateError && setDateError(null)
          selectDate(date)
        }}
        focused={focusedInput}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
        id="mci-single-date-picker"
        numberOfMonths={1}
        block={true}
      />
    </div>
  )
}

export default DatePicker
