import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import AssignmentList from './PeerAssignmentsList'
import Loader from '../../../components/Loader/Loader'
import Alert, { AlertVariant, AlertSize } from '../../../components/Alert'
import { language } from '../../../common/i18n'
import Modal from '../../../components/Modal/Modal'
import { Box, Input } from '@plusplusminus/plusplusdash'
import { PageWrapper } from '../../../modules/PageWrapper'

interface OwnerProps extends RouteComponentProps {
  user?: User
  test?: string
}

export const PeerAssignments: React.FC<OwnerProps> = (props) => {
  return <Assignments {...props} />
}

interface ModalProps extends RouteComponentProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  setShowCreateButton: (showModal: boolean) => void
}

const CreateOrgModal: React.FC<ModalProps> = ({ showModal, setShowModal, setShowCreateButton }) => {
  const {
    register,
    handleSubmit
  } = useForm()

  const [createOrg, { loading }] = useMutation(CREATE_ORG)

  const onRegister = async (data: any) => {
    createOrg({
      variables: {
        name: data.org
      }
    }).then(() => {
      setShowCreateButton(false)
      setShowModal(false)
    })
  }

  return (
    <Modal isModalOpen={showModal} title="Add new Organisation" onClose={() => setShowModal(false)}>
      <div className="flex-1  flex flex-col">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8"
        >
          <Box className="col-span-2 sm:col-span-2" key="org">
            <label htmlFor="org" className="text-base font-medium text-brand-purple my-1 inline-block">
              Organisation name
            </label>
            <Input as="input" variant="standard" width="full" {...register('org')} />
          </Box>

          <div className="col-span-2">
            <Button
              className="justify-center mb-2 mt-3"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              color={Color.ORANGE}
              type="submit"
              iconRight="arrowRight"
            >
              {loading ? 'Loading' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export const Assignments: React.FC<OwnerProps> = (props) => {
  const navigate = useNavigate()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showCreateOrgButton, setShowCreateOrgButton] = useState(false)
  const { loading: peerscansLoading, data: peerData } = useQuery(GET_PEER_SCANS, {
    variables: { peerId: props.user?.peer?.id },
    fetchPolicy: 'no-cache'
  })
  const { loading, data } = useQuery(GET_MY_ASSIGNMENTS, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!props?.user?.peer?.id) {
      setShowCreateOrgButton(true)
    }
  }, [])

  if (loading || peerscansLoading) return <Loader color={Color.PURPLE} />

  return (
    <PageWrapper
      metaDesc={language.en.orgOwner.dashboard.title}
      metaTitle={language.en.orgOwner.dashboard.description}
      headline="Scans"
      sectionHeading="Current Scans"
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.PURPLE}
              size={ButtonSize.SMALL}
              onClick={() => navigate('/dashboard/peer/create-scan')}
              iconRight="plus"
            >
              Create Scan
            </Button>
          </div>
        )
      }}
    >
      {peerData?.peerAssignments.length ? (
        <AssignmentList
          assignments={peerData?.peerAssignments}
          loading={loading}
          organizationId={props?.user?.peer?.id}
        />
      ) : (
        <div className="w-full">
          <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
            Please create your first scan. To set up a new scan and assign organisation for your Scan, click on "Create
            Scan."
          </Alert>
        </div>
      )}
      <CreateOrgModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        setShowCreateButton={setShowCreateOrgButton}
      />
    </PageWrapper>
  )
}

const GET_PEER_SCANS = gql`
  query PeerAssignments($peerId: String) {
    peerAssignments(peerId: $peerId) {
      id
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      organization {
        name
      }
      respondentProfile {
        id
        surveyStatus
      }
    }
  }
`
const CREATE_ORG = gql`
  mutation createOrg($name: String!, $country: String!) {
    createOrg(name: $name, country: $country) {
      id
    }
  }
`
const GET_MY_ASSIGNMENTS = gql`
  query _getAllUserAssignments {
    myAssignments {
      id
      dueDate
      status
      name
      respondentCount
      preSurveyStatus
      isOwner
      respondentProfile {
        id
        surveyStatus
      }
      organization {
        id
      }
    }
  }
`
