import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Input } from '../../components/Input/Input'
import { gql, useLazyQuery } from '@apollo/client'
import { Box } from '@plusplusminus/plusplusdash'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from '@reach/router'
import { InputValidator } from '../../services/validate'
import { Color } from '../../color.enum'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import FormLabel from '../../components/FormLabel/FormLabel'
import Button from '../../components/Button'
import Icon from '../../components/Icon/Icon'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../components/HeadingPageCenter'
import Loader from '../../components/Loader/Loader'
import { useUserQuery } from '../../hooks/useUserQuery'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid first name'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required',
      validate: (input: string) =>
        new InputValidator(input).validateAlphaNumericWithSpace() || 'Enter a valid last name'
    },

    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    },
    type: 'email'
  }
]

interface Props {
  onSubmit?: (data: any, reset: any, formType: string) => void
  loading: boolean
  success: any
  userId: string
  formType: string
}

export const AddUserForm: React.FC<Props> = (props) => {
  const { loading: userLoading, data: user } = useUserQuery()
  const navigate = useNavigate()
  const [updateUser, { loading: updateLoading }] = useMutation(SET_USER_AS_ADMIN)
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, reset, props.formType)
    }
  }

  const [loadUserData, { loading, data }] = useLazyQuery(GET_A_USER, {
    variables: { id: props.userId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { user } = data
      Object.keys(user).forEach((u) => {
        setValue(u, user[u])
      })
    }
  })

  useEffect(() => {
    if (props?.userId) {
      loadUserData()
    }
  }, [])

  if (loading || userLoading) return <Loader color={Color.PURPLE} />

  return (
    <div>
      <HeadingPageCenter
        headline={props?.formType === 'edit' ? 'Edit Profile' : 'Add New Admin'}
        color={Color.GRAY}
        description={props?.formType === 'create' && parse('Please fill in the details below to add a new Superadmin.')}
      />
      <div className="section-container-sm">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
        >
          {form.map((field) => {
            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <FormLabel className="text-white mb-2">{field.label}</FormLabel>
                <Controller
                  rules={{ ...field.options }}
                  name={field.name}
                  control={control}
                  render={({ field }) => <Input as="input" width="full" {...field} />}
                />
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          })}
          {user.me.isSuperAdmin && props.formType === 'edit' && (
            <div className="flex items-center col-span-2">
              <input
                id="canViewResults"
                type="checkbox"
                className="h-4 w-4 text-brand-orange focus:ring-purple-800F border-gray-300 rounded mt-2 mb-2"
                onChange={(e) => {
                  updateUser({
                    variables: {
                      userId: props.userId,
                      isSuperAdmin: e.target.checked
                    }
                  })
                }}
                defaultChecked={data?.user.isSuperAdmin}
                disabled={updateLoading}
              />
              <label className="font-medium text-white my-1 inline-block form-label ml-2" htmlFor="canViewResults">
                Super Admin
              </label>
            </div>
          )}
          <div className="flex space-x-2 col-span-2">
            <Button
              className="flex-1 justify-center align-middle mb-2"
              variant={ButtonVariant.PLAIN}
              color={Color.ORANGE}
              size={ButtonSize.LARGE}
              type="button"
              style={{ width: '100%' }}
              onClick={() => navigate(-1)}
            >
              <Icon className="pr-1" name="arrowLeft" />
              Back
            </Button>
            <Button
              className="flex-1 justify-center align-middle mb-2"
              color={Color.ORANGE}
              size={ButtonSize.LARGE}
              type="submit"
              variant={ButtonVariant.PRIMARY}
            >
              {props.loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const GET_A_USER = gql`
  query UserDetails($id: String!) {
    user(id: $id) {
      email
      firstName
      lastName
      isSuperAdmin
    }
  }
`

const SET_USER_AS_ADMIN = gql`
  mutation setUserAsAdmin($userId: String!, $isSuperAdmin: Boolean!) {
    setUserAsAdmin(userId: $userId, isSuperAdmin: $isSuperAdmin) {
      id
    }
  }
`
