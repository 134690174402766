import { Box } from '@plusplusminus/plusplusdash'
import Input from '../Input/Input'
import Button, { ButtonVariant, ButtonSize } from '../Button'
import { useForm, Controller } from 'react-hook-form'
import { Color } from '../../color.enum'
import FormLabel from '../FormLabel/FormLabel'
import { Select } from '../Select/Select'
import { language } from '../../common/i18n'
interface SearchProps {
  searchParams: Array<{ name: string; label: string; type: string; options: Array<{ id: string; name: string }> }>
  onSearch: (value: any) => void
  onClear: () => void
}

export const Search: React.FC<SearchProps> = ({ onSearch, searchParams, onClear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  const onRegister = async (data: any) => {
    onSearch(data)
  }

  const onReset = () => {
    const clearFieldsObj: any = {}

    searchParams.forEach((params) => {
      clearFieldsObj[params.name] = ''
    })

    reset(clearFieldsObj)
    onClear()
  }

  return (
    <div className="pb-8">
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <div className="pt-4 grid grid-cols-4 gap-4">
          {searchParams.map((params) => {
            if (params.type === 'select') {
              return (
                <Box className=" pb-4" key={params.name}>
                  <FormLabel>{params.label}</FormLabel>
                  <Select
                    items={params.options}
                    name={params.name}
                    register={register}
                    value={getValues(params.name) || ''}
                  />
                </Box>
              )
            }

            return (
              <Box className="w-full pb-4" key={params.name}>
                <FormLabel>{params.label}</FormLabel>
                <Controller
                  {...register(params.name)}
                  control={control}
                  render={({ field }) => <Input as="input" {...field} className="w-full" />}
                />
              </Box>
            )
          })}
        </div>
        <div className="flex space-x-4">
          <Button variant={ButtonVariant.PRIMARY} color={Color.PURPLE} size={ButtonSize.SMALL} iconRight="search">
            {language.en.admin.dashBoard.buttonText.search}
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.PLAIN}
            color={Color.ORANGE}
            size={ButtonSize.SMALL}
            onClick={onReset}
          >
            {language.en.admin.dashBoard.buttonText.clearReset}
          </Button>
        </div>
      </form>
    </div>
  )
}
