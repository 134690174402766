export const language = {
    en: {
        components:{
            select: {
                defaultText: 'Please Select'
            }
        },

        site: {
            title: 'OSP - Organisational Self-Perception Scan',
            description: "Understanding Your Capacity is Key to the Growth and Longevity of Your Organisation"
        },
        error: {
            requiredField: 'This field is required',
            otp: 'Enter a valid OTP'
        },
        auth: {
            title: {
                register: 'OSP - Organisational Self-Perception Scan',
                login: 'Welcome to your Organisational Self-Perception Scan.',
                otp: 'Please enter your OTP (One time password) here to log in:',
                otpSuccess: 'Check your email for your OTP'
            },
            description: {
                register: "Let's get started Welcome to the Organisational Self-Perception Scan (OSP)",
                login: 'Please enter your email here to log in'
            },
        },
    
        admin: {
            dashBoard: {
                title: "OSP - Admin Dashboard",
                description: "View and Manage; Users, Organisations and Surveys",
                heading: "Dashboard",

                navItems: {
                    heading:{
                        users: 'View a list of all Users',
                        survey: 'Edit Surveys',
                        organisations: 'View a list of all organisations',
                        assessments: 'View a list of all scans',
                        reports: 'Reports'
                    }
                },
                buttonText: {
                    view: 'View',
                    addUser: 'Add Superadmin',
                    search: 'Search',
                    clearReset: 'Clear/reset',
                    next: 'Next',
                    previous: 'Previous',
                    page: 'Page',
                    of: 'of',
                    viewUser: 'View User',
                    addPeer: 'Add Peer',
                    viewPeer: 'View Peer'
                },
                users: {
                    title: "All Users",
                    headline: "Current Users",
                },
                peers: {
                    headline: "Peers",
                    title: "Current Peers",
                    addPeerForm: {
                        firstName: 'First Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        foundation: 'Foundation',
                        country: 'Country'
                    },
                    singlePeerView:{
                        description: 'See peer details below',
                        title: 'View Peer Details',
                        buttons:{
                            editPeer: 'Edit Peer'
                        },
                        labels: {
                            joinedOn: 'Joined on:',
                            country: 'Country:',
                            email: 'Email Address:',
                            organisation: 'Organisation:',
                            role: 'Role:'

                        }
                    }
                }
            },

            survey: {
                watchVideoAlert: 'Please watch at least 50% of the video before proceeding.',
                edit: {
                    title: "OSP - Edit Survey",
                    description: "Edit the form to change the details of the survey"
                },
                create: {
                    title: "OSP - Create Survey",
                    description: "Fill the form to create a new survey "
                },
                list: {
                    title: "OSP- Surveys",
                    description: "You can use this page to view and edit the details of the survey."
                },
                emptyList: "You currently do not have any surveys",

                singleSurvey: {
                    title: "View single Survey",
                    description: "Single survey"
                },

                questionnaire: {
                    add: {
                        title: "MI - Add new  Questionnaire",
                        description: "Add new  Questionnaire"
                    },
                    edit: {
                        title: "MI - Edit new  Questionnaire",
                        description: "Edit new  Questionnaire"
                    }
                }

            },
            user: {
                singleUserView: {
                    title: 'View user details',
                    description: 'View user details'

                },
                add: {
                    title: "OSP - Add New Superadmin",
                    description: "Add New Superadmin"
                },
                edit: {
                    title: "OSP - Edit new user",
                    description: "Edit new user"
                },
                list: {
                    title: "OSP - Users",
                    description: "You can use this view to see users you have registered"
                }
            },
            peer: {
                dashBoard: {
                    title: "OSP - Peer Dashboard",
                    description: "View and Manage; Users, Organisations and Scans"
                },
                singleUserView: {
                    title: 'View peer details',
                    description: 'View peer details'

                },
                add: {
                    title: "OSP - Add new peer",
                    description: "Add new peer"
                },
                edit: {
                    title: "OSP - Edit new peer",
                    description: "Edit new peer"
                },
                list: {
                    title: "OSP - Peers",
                    description: "You can use this view to add new peers, or view current peers."
                },
                organisations: {
                    title: "Organisations",
                    description: "You can use this view to add new organisations, or view current organisations.",
                    headline: "Current Organisations"
                }
            },
            factor: {
                add: {
                    title: "OSP - Add factor",
                    description: "OSP Add new factor"
                },
                edit: {
                    title: "OSP - Edit factor",
                    description: "OSP Edit ne factor"
                }
            },

            option: {
                title: "Survey Option",
                description: "Survey Option detaits"
            },

            question: {
                create: {
                    title: 'Create New Question',
                    description: 'Fill form to add new question to questionare'
                },
                edit: {
                    title: 'Edit Question',
                    description: 'Change form fields values to edit'
                }
            },

            statement: {
                title: 'View statement details',
                description: 'View statement details',
                create: {
                    title: 'Create New Statement',
                    description: 'Fill the form to add new statement'
                },
                edit: {
                    title: 'Edit Statement',
                    description: 'Change form fields values to edit'
                }

            },

            factorType: {
                title: 'View factor type details',
                description: 'View factor type details',
                create: {
                    title: 'Create New Archetype',
                    description: 'Fill the form to add new factor type'
                },
                edit: {
                    title: 'Edit Archetype',
                    description: 'Change form fields values to edit'
                }

            },
            assignments: {
                title: " OSP - Assignments",
                description: 'List of all assignments from belonging to different organisations'
            },
            organisations: {
                title: "OSP - Organisations",
                description: 'List of all organisations'
            }


        },
        orgOwner: {
            dashboard: {
                title: 'Organisation Dashboard',
                description: "Welcome to your dashboard where you can view the status and results of all of your current and past assessments."
            },
            assignment: {
                title: 'Respondent assesment',
                description: 'Create assessment, add repondents and fill pre-survey',

                results:{
                    title: 'OSP - Respondent assesment results',
                    description: 'OSP - Respondent assesment results',
                }
            }

        },
        respondent: {
            dashboard: {
                title: 'Assessment Dashboard',
                description: "Welcome to your dashboard. You can use this view to participate in the assessments you have been invited to."
            }
        },


    peers:{
        dashboard:{
        navItems: {
            heading:{
                users: 'View a list of all users',
                organisations: 'View a list of all Organisations',
                scans: 'View a list of all scans',
            }

        }
        }
    },

    buttonText: {
        notApplicable: 'Not applicable',
        cancel: 'Cancel',
        confirm: 'Confirm',
        loading: 'Loading...',
        next: 'Next'
    }

    }
}