import { Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import PDFRichText from '../RichText'

interface ContentProps {
  id: string
  styles: Record<string, Style>
  headline?: string
  content?: any
}

const PDFContent: React.FC<ContentProps> = (props) => {
  const { styles, headline, content } = props

  return (
    <View>
      <Text style={styles.heading}>{headline}</Text>
      <PDFRichText content={content} />
    </View>
  )
}

export default PDFContent
