import { useState } from 'react'
import { RouteComponentProps, useNavigate, useParams } from '@reach/router'
import HeadingPage from '../../../../../components/HeadingPage'
import { Color } from '../../../../../color.enum'
import Button from '../../../../../components/Button'
import { ButtonVariant, ButtonSize } from '../../../../../components/Button'
import { gql, useQuery, useMutation } from '@apollo/client'
import Loader from '../../../../../components/Loader/Loader'
import { useBreadcrumbs } from '../../../../../hooks/useBreadcrumbs'
import BreadCrumbs from '../../../../../components/BreadCrumbs/BreadCrumbs'
import { Helmet } from 'react-helmet'
import Input from '../../../../../components/Input/Input'

interface SinglePostSurveyQuestionInterface extends RouteComponentProps<any> {
  history: any
}

interface DetailsInterface {
  code: string
  type: 'options'
}

const ResponseTypeMap: any = {
  options: 'Selection of options',
  custom: 'Custom Response',
  country: 'Country Selection',
  year: 'Year Selection'
}

const Details: React.FC<DetailsInterface> = ({ code, type }) => {
  return (
    <div className="mt-5 space-y-1">
      {code && (
        <div>
          <span className="inline text-white font-extrabold">Code:</span>
          <span className="ml-1">{code}</span>
        </div>
      )}
      {type && (
        <div>
          <span className="inline text-white font-extrabold">Question Type:</span>
          <span className="ml-1">{ResponseTypeMap[type]}</span>
        </div>
      )}
    </div>
  )
}


const SinglePostSurveyQuestion: React.FC<SinglePostSurveyQuestionInterface> = () => {
  const [fields, setFields] = useState([{ text: '', code: '', index: '', id: '' }])
  const [errors, setErrors] = useState(false)
  const navigate = useNavigate()
  const { questionId, surveyId, postSurveyId } = useParams()
  const [getBreadCrumbs] = useBreadcrumbs()
  const { loading, data } = useQuery(GET_SINGLE_POST_SURVEY_QUESTION, {
    fetchPolicy: 'no-cache',
    variables: { id: questionId },
    onCompleted: (optionData) => {
      const {
        postSurveyQuestion: { responseType, postSurveyOption }
      } = optionData

      if (responseType === 'options') {
        const newDataSet: Array<{ text: string; code: string; index: string; id: string }> = [...fields]
        postSurveyOption.forEach((option: any, index: number) => {
          const newOption = {
            text: option.text,
            code: option.code,
            index: option.index,
            id: option.id
          }
          newDataSet[index] = newOption
        })

        setFields(newDataSet)
      }
    }
  })
  const [updateOptions, { loading: updateOptionsLoading }] = useMutation(UPDATE_OPTIONS, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      const { updateQuestionOptions } = data

      const updateStateDate: any = [...updateQuestionOptions]

      updateQuestionOptions.forEach((option: any, index: number) => {
        const newOption = {
          text: option.text,
          code: option.code,
          index: option.index,
          id: option.id
        }
        updateStateDate[index] = newOption
      })

      setFields(updateStateDate)
    }
  })

  const getCrumbs = () => {
    return getBreadCrumbs('demographicQuestion', surveyId, '', 'Participant Questionnaire', postSurveyId)
  }

  const handleChange = (event: any, index: number) => {
    const data: any = [...fields]
    data[index][event.target.name] = event.target.value
    setFields(data)
  }

  const submit = () => {
    setErrors(false)
    let errors = false

    fields.forEach((f) => {
      if (!f.code || !f.index || !f.text) {
        errors = true
      }
    })

    if (errors) {
      setErrors(errors)
      return
    }

    updateOptions({
      variables: {
        options: fields,
        questionId
      }
    })
  }

  const addField = () => {
    const newField = {
      text: '',
      code: '',
      index: '',
      id: ''
    }
    setFields([...fields, newField])
  }

  const removeField = (index: number) => {
    const data = [...fields]
    data.splice(index, 1)
    setFields(data)
  }

  if (loading) return <Loader color={Color.PURPLE} />
  const { postSurveyQuestion } = data

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{postSurveyQuestion?.text}</title>
        <meta name={postSurveyQuestion?.text} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <main>
        <BreadCrumbs pages={getCrumbs()} className="py-0 pb-10" />
        <HeadingPage
          headline={postSurveyQuestion?.text}
          color={Color.GRAY}
          description={<Details code={postSurveyQuestion?.code} type={postSurveyQuestion?.responseType} />}
          children={() => {
            return (
              <div className="flex">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    navigate(
                      `/dashboard/admin/survey/${surveyId}/postSurvey/${postSurveyId}/question/${questionId}/edit`
                    )
                  }}
                >
                  Edit Question
                </Button>
              </div>
            )
          }}
        />
        {postSurveyQuestion?.responseType === 'options' && (
          <form onSubmit={submit}>
            {fields.map((field, index) => {
              return (
                <div className="pt-4 grid grid-cols-4 gap-4" key={index}>
                  <Input
                    name="text"
                    className="w-full"
                    as="input"
                    placeHolder="Text"
                    onChange={(event) => handleChange(event, index)}
                    value={field.text}
                  />
                  <Input
                    name="code"
                    className="w-full"
                    as="input"
                    placeHolder="Code"
                    onChange={(event) => handleChange(event, index)}
                    value={field.code}
                  />
                  <Input
                    name="index"
                    className="w-full"
                    as="input"
                    placeHolder="Index"
                    onChange={(event) => handleChange(event, index)}
                    value={field.index}
                  />
                  <Button
                    className="flex justify-center"
                    variant={ButtonVariant.PLAIN}
                    color={Color.RED}
                    size={ButtonSize.SMALL}
                    onClick={(e) => {
                      e.preventDefault()
                      removeField(index)
                    }}
                    iconRight="close"
                  >
                    Remove Item
                  </Button>
                </div>
              )
            })}
            <div className="pt-4 flex space-x-4">
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.ORANGE}
                size={ButtonSize.MEDIUM}
                iconRight="plus"
                onClick={(e) => {
                  e.preventDefault()
                  addField()
                }}
              >
                Add field
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                color={Color.PURPLE}
                size={ButtonSize.MEDIUM}
                onClick={(e) => {
                  e.preventDefault()
                  submit()
                }}
              >
                {updateOptionsLoading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </form>
        )}
        {errors && <p className="text-sm text-red-500 pt-4">Please make sure all fields are filled in</p>}
      </main>
    </div>
  )
}

export default SinglePostSurveyQuestion

const GET_SINGLE_POST_SURVEY_QUESTION = gql`
  query postSurveyQuestion($id: String!) {
    postSurveyQuestion(id: $id) {
      text
      code
      position
      id
      description
      responseType
      postSurveyOption {
        id
        text
        code
        index
        archived
      }
    }
  }
`
const UPDATE_OPTIONS = gql`
  mutation updateQuestionOptions($options: [OptionInput!]!, $questionId: String!) {
    updateQuestionOptions(options: $options, questionId: $questionId) {
      id
      code
      text
      index
    }
  }
`
