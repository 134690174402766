import { Color } from "../../color.enum"

export const apiSupporting = [
  {
    headline: 'Competent Leadership',
    scorePercentage: 93,
    color: Color.PURPLE
  },
  {
    headline: 'Effective Governance',
    scorePercentage: 69,
    color: Color.PURPLE
  },
  {
    headline: 'Relevant Measures',
    scorePercentage: 80,
    color: Color.PURPLE
  },
  {
    headline: 'Reliable Operations',
    scorePercentage: 50,
    color: Color.PURPLE
  }
]

export const apiSustaining = [
  {
    headline: 'Clear Mission',
    scorePercentage: 77,
    color: Color.BLUE
  },
  {
    headline: 'Healthy Culture',
    scorePercentage: 94,
    color: Color.BLUE
  },
  {
    headline: 'Impactful Strategy',
    scorePercentage: 60,
    color: Color.BLUE
  },
  {
    headline: 'Sound Resourcing Plan',
    scorePercentage: 55,
    color: Color.BLUE
  }
]
