import Button, { ButtonVariant, ButtonSize } from '../Button'
import Icon from '../Icon/Icon'
import { Badge } from '@plusplusminus/plusplusdash'
import { Color } from '../../color.enum'

interface ItemCtaData {
  color: Color
}
export const ListItemCta: React.FC<ItemCtaData> = ({ color }: ItemCtaData) => {
  return (
    <div className="flex items-center">
      <div className="mr-2">
        <Button variant={ButtonVariant.PLAIN} color={color} size={ButtonSize.SMALL}>
          More Info
        </Button>
      </div>

      <Button variant={ButtonVariant.PRIMARY} color={color} size={ButtonSize.SMALL}>
        Start Now
      </Button>
    </div>
  )
}

interface ItemMetaData {
  headline?: string
}
export const ListItemMeta: React.FC<ItemMetaData> = ({ headline }: ItemMetaData) => {
  return (
    <div>
      <p className="text-sm text-gray-900">
        {headline}
        Applied on <time dateTime={'2020-01-07'}>{'January 7, 2020'}</time>
      </p>
      <p className="mt-1 flex items-center text-sm text-gray-500">
        <Badge color="green">
          <Icon name="checkSolid" className="mr-2" /> Completed
        </Badge>
      </p>
    </div>
  )
}

export const api = {
  headline: 'Headline',
  description: 'description',
  type: 'scan',
  color: Color.PURPLE,
  renderMeta: (
    <div>
      <p className="text-sm text-gray-900">Applied on</p>
      <p className="mt-1 flex items-center text-sm text-gray-500">
        <Icon name="checkSolid" />
        {'application.stage'}
      </p>
    </div>
  ),
  renderCta: (color: Color): JSX.Element => {
    return <ListItemCta color={color} />
  }
}
