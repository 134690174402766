import { useEffect } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery } from '@apollo/client'
import HeadingSection from '../../components/HeadingSection'
import OrganisationTable from './OrganisationTable'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'
import { Color } from '../../color.enum'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import HeadingPage from '../../components/HeadingPage'
import { User } from '../../common/types'
import Alert, { AlertVariant, AlertSize } from '../../components/Alert'
import { GET_PEER_ORGANISATIONS } from '../../graphql/queries'
import Loader from '../../components/Loader/Loader'

interface PeerOrganisationProps extends RouteComponentProps {
  user?: User
}

const PeerOrganisations: React.FC<PeerOrganisationProps> = (props) => {
  const { loading, data, refetch } = useQuery(GET_PEER_ORGANISATIONS, {
    fetchPolicy: 'no-cache',
    variables: { peerId: props?.user?.peer?.id }
  })
  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  if (loading) return <Loader className="h-100 my-10" color={Color.PURPLE} />

  return (
    <div className="page-container-lg">
      <header>
        <Helmet>
          <title>{language.en.admin.peer.organisations.title}</title>
          <meta name="description" content={language.en.admin.peer.organisations.description} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        <HeadingPage
          headline={language.en.admin.peer.organisations.title}
          className=""
          color={Color.ORANGE}
          description={language.en.admin.peer.organisations.description}
        />
        <HeadingSection
          headline={language.en.admin.peer.organisations.headline}
          color={Color.ORANGE}
          children={() => {
            return (
              <div className="flex items-center">
                <Button
                  variant={ButtonVariant.PRIMARY}
                  color={Color.PURPLE}
                  size={ButtonSize.SMALL}
                  onClick={() => navigate('/dashboard/peer/add-organisation')}
                  iconRight="plus"
                >
                  Add Organisation
                </Button>
              </div>
            )
          }}
        />
      </header>
      <OrganisationTable
        items={data?.organizationsByName || []}
        loading={loading}
        refetch={refetch}
        peerId={props?.user?.peer?.id}
      />
      {!data && (
        <Alert size={AlertSize.LARGE} variant={AlertVariant.WARNING} className="w-full">
          No organisations exists. Click Add Organisation to create your first organisation.
        </Alert>
      )}
    </div>
  )
}

export default PeerOrganisations
