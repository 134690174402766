import React from 'react'
import gql from 'graphql-tag'

export interface StyleTextProps {
  text?: string
  style?: TextStyles
}

export enum TextStyles {
  SUPERSCRIPT = 'superscript',
  SUBSCRIPT = 'subscript'
}

const StyleTextWrapper: React.FC<StyleTextProps> = ({ text, style }) => {
  return style === 'superscript' ? <sup>{text}</sup> : <sub>{text}</sub>
}

export default StyleTextWrapper

export const STYLE_TEXT_FIELDS = gql`
  fragment StyleTextFields on StyleText {
    text
    style
  }
`
