import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export interface ListItemProps {
  /** Headline of the component */
  headline?: string
  /** Headline of the component */
  description?: string
  /** Aditional Styles for the container */
  className?: string
  /** Add a CTA to the component */
  renderCta?: (color: Color) => JSX.Element
  /** Add a meta info to the component */
  renderMeta?: JSX.Element
  /** Status of the Item */
  renderStatus?: JSX.Element
  /** What color is the componet? */
  color: Color
  orgName?: string,
  type?: string,
  code?: string
}

interface SectionProps {
  text: string
}

export const LIST_ITEM_COLOR_MAPS: Record<Color, SectionProps> = {
  [Color.BLUE]: {
    text: 'text-brand-blue'
  },
  [Color.PURPLE]: {
    text: 'text-brand-purple'
  },
  [Color.GRAY]: {
    text: 'text-gray-500'
  },
  [Color.RED]: {
    text: 'text-brand-red'
  },
  [Color.ORANGE]: {
    text: 'text-brand-orange'
  }
}

/**
 *  Component to show a ListItem - Should appear inside of the List
 */

const ListItem: React.FC<ListItemProps> = ({
  className,
  headline,
  description,
  color,
  renderCta,
  renderMeta,
  orgName,
  type,
  code
}: ListItemProps) => {
  return (
    <li className={classNames('ListItem bg-white hover:bg-gray-50', className)}>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className={`min-w-0 flex-1 md:grid md:grid-cols-${type === 'scan' || type === 'statement' ? 4 : 3} md:gap-4 items-center`}>
          <div>
            {headline && (
              <p className={`text-base font-medium truncate ${LIST_ITEM_COLOR_MAPS[color].text}`}>{headline}</p>
            )}
            {description && (
              <p className="mt-1 flex items-center text-sm text-gray-500">
              <span className="truncate">{description}</span>
              </p>
            )}
          </div>

         {

           type === 'statement' && code && <p className='text-center'>{code}</p>

         } 
          {type === 'scan' && (
            <div>
              {orgName && (
                <>
                  <p className="text-sm text-gray-900">Organisation</p>
                  <p className={`text-base font-medium truncate ${LIST_ITEM_COLOR_MAPS[color].text}`}>
                    {orgName ? orgName : '-'}
                  </p>
                </>
              )}
            </div>
          )}

          <div className="hidden md:flex ">{renderMeta && renderMeta}</div>
          {renderCta && <div className={`pl-12 text-left mt-2 md:mt-0 ${!type && 'justify-end'}`}>{renderCta(color)}</div>}
        </div>
      </div>
    </li>
  )
}

ListItem.defaultProps = {
  color: Color.PURPLE
}

export default ListItem
