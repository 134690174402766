import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useNavigate } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import Question from './Question'
import Factor from './Factor'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import Loader from '../../../components/Loader/Loader'
import Modal from '../../../components/Modal/Modal'
import { language } from '../../../common/i18n'

interface ResponseProps {
  name: string
  value: number
  code: string
  id: string
}
interface SurveyWrapperProps extends RouteComponentProps {
  respondentId: string
  surveyId: string
  assignmentId: string
  setStep: (step: string) => void
}

export const SurveyWrapper: React.FC<SurveyWrapperProps> = (props) => {
  const { loading: optionsLoading, data: options } = useQuery(STATEMENT_OPTIONS)
  const { loading, data } = useQuery(SURVEY_BY_ASSIGNMENT, {
    variables: {
      assignmentId: props.assignmentId,
      respondentId: props.respondentId
    },
    fetchPolicy: 'no-cache'
  })

  if (loading || optionsLoading) return <Loader color={Color.PURPLE} />

  return <Survey {...props} surveyData={data} options={options} />
}

interface SurveyProps extends SurveyWrapperProps {
  surveyData: any
  options: any
}

export const Survey: React.FC<SurveyProps> = ({ options, surveyData, surveyId, respondentId, assignmentId }) => {
  const [loading, setLoading] = useState(false)
  const [factor, setFactor] = useState(0)
  const [question, setQuestion] = useState(-1)
  const [errors, setErrors] = useState<string | null>('')
  const [response, setResponse] = useState<ResponseProps | undefined>(undefined)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const [createResponse, { loading: createResLoading }] = useMutation(CREATE_RESPONSE)
  const [completeSurvey, { loading: completeSurveyLoading }] = useMutation(COMPLETE_SURVEY, {
    onCompleted: () => {
      navigate(`/survey/${assignmentId}/result`)
    }
  })

  useEffect(() => {
    setLoading(true)
    const { surveyByAssignmentId } = surveyData

    let completedFactorCount = 0
    surveyByAssignmentId.forEach((f: any) => {
      if (f?.isFactorCompleted) {
        completedFactorCount += 1
      }
    })

    setFactor(completedFactorCount)

    const { statements } = surveyByAssignmentId[completedFactorCount]

    let completedStatementCount = 0
    statements.forEach((s: any) => {
      if (s.isCompleted) {
        completedStatementCount += 1
      }
    })

    if (completedStatementCount) {
      setQuestion(completedStatementCount)
    }
    setLoading(false)
  }, [])

  const handleOnChange = (e: any) => {
    setErrors('')
    const o = options?.statementOptions
    const x = e
    setResponse(o[x])
  }

  const setNextStep = () => {
    setErrors('')
    setResponse(undefined)
    setQuestion(question + 1)
  }

  const onClose = () => {
    setOpen(false)
    setResponse(undefined)
  }

  const saveResponse = (
    isOnLastQuestionOfFactor: boolean,
    isOnFactorScreen: boolean,
    isOnLastQuestionOfSurvey: boolean,
    statementId?: string,
    notApplicable?: boolean
  ) => {
    if (isOnFactorScreen) {
      setNextStep()
    } else {
      let input: any = {}

      if (notApplicable) {
        input = {
          respondentId: respondentId,
          assignmentId: assignmentId,
          statementId: statementId,
          surveyId: surveyId
        }
      } else {
        input = {
          respondentId: respondentId,
          responseId: response?.id,
          assignmentId: assignmentId,
          statementId: statementId,
          surveyId: surveyId
        }
      }
      createResponse({
        variables: {
          input
        }
      }).then(() => {
        if (isOnLastQuestionOfSurvey) {
          completeSurvey({ variables: { respondentId: respondentId } })
        } else if (isOnLastQuestionOfFactor) {
          setQuestion(-1)
          setFactor(factor + 1)
        } else {
          setNextStep()
        }
      })
    }
  }

  const onNextQuestion = (length: number, statementId?: string, notApplicable?: boolean) => {
   
    if (question !== -1 && !response && !notApplicable) {
      setOpen(true)
      const o = options?.statementOptions
      setResponse(o[0])
      setErrors('Please select an answer to continue')
      return
    }

    const isOnLastQuestionOfSurvey = factor === length - 1 && question === currentFactor.statements.length - 1
    const isOnLastQuestionOfFactor = question === currentFactor.statements.length - 1
    const isOnFactorScreen = question === -1
    saveResponse(isOnLastQuestionOfFactor, isOnFactorScreen, isOnLastQuestionOfSurvey, statementId, notApplicable)
  }

  const { surveyByAssignmentId: factors } = surveyData
  const currentFactor = factors[factor]
  const currentQuestion = currentFactor.statements[question]

  const onConfirmDefault = async () => {
    onNextQuestion(factors.length, currentQuestion.id)
    if (!createResLoading || !completeSurveyLoading) {
      setOpen(false)
    }
  }

  if (loading) return <Loader color={Color.PURPLE} />

  if (question === -1) {
    return (
      <Factor
        key={Number(factor)}
        headline={currentFactor.description}
        subtitle={currentFactor.headline}
        image={currentFactor.image}
        renderCta={
          <div className="mt-10 mb-6">
            <Button
              size={ButtonSize.LARGE}
              variant={ButtonVariant.PRIMARY}
              color={Color.ORANGE}
              iconRight="arrowRight"
              onClick={() => onNextQuestion(factors.length)}
            >
              Continue
            </Button>
          </div>
        }
      />
    )
  }

  const steps = factors.map((f: any, index: any) => {
    const isComplete = index < factor
    return {
      title: f.headline,
      isComplete,
      color: f?.factorType?.color
    }
  })

  return (
    <>
      <Modal onClose={onClose} isModalOpen={open} title="Please confirm your answer.">
        <p className='my-3 text-white'>You have not changed your response to this statement.</p>
        <div className="flex gap-4">
          <Button
            variant={ButtonVariant.PRIMARY}
            color={Color.ORANGE}
            size={ButtonSize.MEDIUM}
            onClick={onConfirmDefault}
          >
            {createResLoading || completeSurveyLoading
              ? language.en.buttonText.loading
              : language.en.buttonText.confirm}
          </Button>
          <Button variant={ButtonVariant.PRIMARY} color={Color.RED} size={ButtonSize.MEDIUM} onClick={onClose}>
            {language.en.buttonText.cancel}
          </Button>
        </div>
      </Modal>
      <Question
        scale={options?.statementOptions}
        steps={steps}
        activeDimension={factor}
        questionNumber={question + 1}
        totalQuestions={currentFactor.statements.length}
        descriptor={currentQuestion.statement}
        handleOnChange={handleOnChange}
        value={response?.value}
        error={errors}
        renderCta={
          <div className="flex justify-between">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.BLUE}
              size={ButtonSize.LARGE}
              onClick={() => {
                onNextQuestion(factors.length, currentQuestion.id, true)
              }}
              disabled={createResLoading || completeSurveyLoading ? true : false}
            >
              {language.en.buttonText.notApplicable}
            </Button>
            <Button
              iconRight="arrowRight"
              size={ButtonSize.LARGE}
              variant={ButtonVariant.PRIMARY}
              color={Color.ORANGE}
              onClick={() => onNextQuestion(factors.length, currentQuestion.id)}
              disabled={createResLoading || completeSurveyLoading ? true : false}
            >
              {createResLoading || completeSurveyLoading ? language.en.buttonText.loading : language.en.buttonText.next}
            </Button>
          </div>
        }
      />
    </>
  )
}

const SURVEY_BY_ASSIGNMENT = gql`
  query survey($respondentId: String!, $assignmentId: String!) {
    surveyByAssignmentId(assignmentId: $assignmentId, respondentId: $respondentId) {
      id
      name
      image
      description
      headline
      isFactorCompleted
      factorType {
        name
        color
      }
      statements {
        id
        code
        statement
        isCompleted
        position
      }
    }
  }
`

const STATEMENT_OPTIONS = gql`
  query statementOptions {
    statementOptions {
      id
      code
      name
      title
      value
    }
  }
`
const CREATE_RESPONSE = gql`
  mutation createSurveyResponse($input: CreateSurveyResponseInput!) {
    createSurveyResponse(input: $input) {
      id
    }
  }
`
const COMPLETE_SURVEY = gql`
  mutation completeSurvey($respondentId: String!) {
    completeSurvey(respondentId: $respondentId) {
      id
    }
  }
`
