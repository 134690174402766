import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormLabel from '../FormLabel/FormLabel'
import Button from '../../components/Button'
import { ButtonVariant, ButtonSize } from '../../components/Button'
import { Color } from '../../color.enum'

interface FileInputProps {
  onSubmit: (data: any) => void
  loading: boolean
  success: boolean
}

export const FileInput: React.FC<FileInputProps> = ({ onSubmit, success, loading }) => {
  const [error, setError] = useState('')
  const { register, handleSubmit, reset } = useForm()

  useEffect(() => {
    if (success) {
      reset()
    }
  }, [success])

  const onRegister = async (data: any) => {
    setError('')
    if (!data.file.length) {
      setError('Please choose a file')
    } else {
      onSubmit(data.file)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onRegister)}>
        <FormLabel className="text-sm font-medium block mb-2">Upload Image</FormLabel>
        <div className="flex">
          <input
            className="block w-full cursor-pointer bg-brand-blue-light border border-gray-100 border-opacity-50 text-gray-200 focus:outline-none focus:border-transparent text-sm rounded-lg"
            id="factor_image"
            type="file"
            {...register('file')}
          />
          <Button
            type="submit"
            variant={ButtonVariant.SECONDARY}
            color={Color.ORANGE}
            size={ButtonSize.MEDIUM}
            iconRight="plus"
            className="mx-2"
          >
            {loading ? 'Uploading' : 'Upload'}
          </Button>
        </div>
        {error ? (
          <div className="mt-1 text-sm text-red-500">{error}</div>
        ) : (
          <div className="mt-1 text-sm text-gray-50">Add a new image here</div>
        )}
      </form>
    </div>
  )
}
