import React from 'react'
import { classNames } from '../../utils/class-names'

export interface ContentProps {
  /** Aditional Styles for the container */
  className?: string
  /** Children to be displayd as the Content */
  children: React.ReactElement | React.ReactNode
}

/**
 *  Component to show and style content with various HTML elements. This compnent only takes `children` and provides for a `className` prop that can format the wrapper as needed. It makes use of the `prose` class as provided by TailwindCSS
 * https://github.com/tailwindlabs/tailwindcss-typography
 */

const Content: React.FC<ContentProps> = ({ className, children }) => {
  return <div className={classNames(className, 'prose')}>{children}</div>
}

Content.defaultProps = {}

export default Content
