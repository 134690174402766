import React from 'react'
import { Input as BaseInput } from '@plusplusminus/plusplusdash'
import { classNames } from '../../utils/class-names'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  /** Aditional Styles for the container */
  className?: string
  /** Children are displayed as the text of the label */
  children?: React.ReactElement | string
  /** Type of input */
  as: 'input' | 'textarea'
  /** Width of input */
  width?: keyof Width
  /** Number of rows */
  rows?: number
  placeHolder?: string
}

interface Width {
  sm: string
  md: string
  lg: string
  full: string
}

/**
 * Form Labels
 */

export const Input: React.FC<InputProps> = ({ className, as, ...props }: InputProps) => {
  return (
    <BaseInput
      variant={'standard'}
      as={as}
      className={classNames(
        'py-3 px-3 text-base text-white bg-brand-blue-light border border-gray-500 rounded-sm focus:border-opacity-20 placeholder-gray-200 placeholder-opacity-75 transition-all',
        className
      )}
      {...props}
    />
  )
}

Input.defaultProps = {
  as: 'input'
}

export default Input
