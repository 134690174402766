import { StyleSheet } from '@react-pdf/renderer'
import theme from '../../theme'

export default StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 50,
    fontFamily: 'Montserrat'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  radarContainer: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  radar: {
    width: '400px',
    height: '400px'
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.colors['brand-blue']
  },
  subheading: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 16,
    color: theme.colors['brand-blue']
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5
  },
  footer: {
    height: '40px',
    fontSize: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors['brand-blue'],
    paddingHorizontal: 1,
    borderTop: '10px solid red',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderImage: 'linear-gradient(to right, red, violet) 1 stretch'
  },
  layout: {
    paddingVertical: 2,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.gray[100]
  },
  logo: { 
    marginBottom: 50,
    height: '200px',
    width: '500px',
  },
  footerLogo: {
    height: '20px',
    width: '90px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.colors.gray[100],
    paddingVertical: 10
  },
  column: {
    flexBasis: '50%'
  },
  bold: {
    fontWeight: 'medium'
  },
  center: {
    textAlign: 'center'
  },
  blue: {
    color: theme.colors['brand-blue']
  },
  black: {
    color: 'black'
  },
  grey: {
    color: theme.colors.gray[500]
  },
  purple: {
    color: theme.colors['brand-purple']
  },
  factorContentHeading: {
    fontSize: 14,
    color: 'black',
    fontWeight: 'medium',
    marginBottom: 10
  },
  body: {
    padding: 10
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 2,
    fontSize: 10,
    overflow: "hidden",
    alignItems: "center",
    
  }
})
