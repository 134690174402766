import React from 'react'
import { classNames } from '../../utils/class-names'
import ReactPlayer from 'react-player'

export interface VideoProps {
  /** Aditional Styles for the container */
  className?: string
  /** ID of the Video */
  videoUrl: string | null
  setSecondsPlayed?: (seconds: number) => void
}

/**
 *  Component to show a Video
 */

const Video: React.FC<VideoProps> = ({ className, videoUrl, setSecondsPlayed }: VideoProps) => {
  return (
    <div className={classNames(className)}>
      <div className={classNames('aspect-h-9 aspect-w-16')}>
        <ReactPlayer
          width="100%"
          height="100%"
          url={`${videoUrl}`}
          playing={false}
          controls={true}
          onProgress={(state) => {
            setSecondsPlayed && setSecondsPlayed(state.playedSeconds)
          }}
        />
      </div>
    </div>
  )
}

Video.defaultProps = {
  videoUrl: null
}

export default Video
