import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

export interface CardProps {
  /** What is the variant of the component */
  variant?: CardVariant
  /** What is the headline of the component */
  headline?: string
  /** What is the subtitle of the component */
  subtitle?: string
  /** Add an media componet here */
  media?: React.ReactElement
  /** Aditional Styles for the container */
  className?: string
}

interface CardVariantProps {
  headline: string
  bg: Color
}

export enum CardVariant {
  PRIMARY = 'primary',
  STANDARD = 'standard'
}

export const VARIANT_MAPS: Record<CardVariant, CardVariantProps> = {
  [CardVariant.PRIMARY]: {
    headline: 'text-5xl',
    bg:Color.BLUE
  },

  [CardVariant.STANDARD]: {
    headline: 'text-3xl',
    bg:Color.BLUE
  }
}

const Card: React.FC<CardProps> = ({ variant, headline, subtitle, media, children, className }) => {
  return (
    <div className={classNames('bg-brand-blue-dark py-28',className)}>
      <div className={'flex justify-between flex-col md:flex-row'}>
        {media && <div className={'w-full md:w-1/2 text-center items-center px-0 md:px-8 mb-4 md:mb-0'}>{media}</div>}
        <div className={classNames(`items-center px-0 md:px-8 w-full ${media ? 'md:w-1/2' : null}`)}>
          {subtitle && (
            <div className="mb-4 sm:mt-0 uppercase tracking-widest">
              <h3 className="text-sm text-brand-orange">{subtitle}</h3>
            </div>
          )}
          {headline && (
            <h2 className={classNames('text-white', variant && VARIANT_MAPS[variant].headline)}>{headline}</h2>
          )}
          {children && <div className={'mt-6'}>{children}</div>}
        </div>
      </div>
    </div>
  )
}

Card.defaultProps = {
  variant: CardVariant.PRIMARY
}

export default Card
