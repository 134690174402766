import React, { useEffect } from 'react'
import { Table } from '@plusplusminus/plusplusdash'
import { useSort } from '../../../hooks/useSort'
import TablePagination from '../../../components/TablePagination'
import { usePagination } from '../../../hooks/usePagaination'
import { formatDate } from '../../../services/formatDate'
import Loader from '../../../components/Loader/Loader'
import { Color } from '../../../color.enum'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { useNavigate } from '@reach/router'
import { Search } from '../../../components/Search/Search'

const searchParams = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    options: []
  },
  {
    name: 'status',
    label: 'Scan Status',
    type: 'select',
    options: [
      {
        id: 'PENDING',
        name: 'Draft'
      },
      {
        id: 'STARTED',
        name: 'Started'
      },
      {
        id: 'COMPLETED',
        name: 'Compelted'
      },
      {
        id: 'EXPIRED',
        name: 'Expired'
      },
      {
        id: 'PUBLISHED',
        name: 'Published'
      }
    ]
  },
  // {
  //   name: 'preSurveyStatus',
  //   label: 'Organization Demographic Survey Status',
  //   type: 'select',
  //   options: [
  //     {
  //       id: 'PENDING',
  //       name: 'Draft'
  //     },
  //     {
  //       id: 'STARTED',
  //       name: 'Started'
  //     },
  //     {
  //       id: 'COMPLETED',
  //       name: 'Compelted'
  //     },
  //     {
  //       id: 'EXPIRED',
  //       name: 'Expired'
  //     },
  //     {
  //       id: 'PUBLISHED',
  //       name: 'Published'
  //     }
  //   ]
  // }
]

interface Items {
  id: string
  name: string
  dueDate: Date
  status: string
  organization: any
  respondentCount: number
  peer: any
}

interface PaginationArgs {
  currentPage: number
  totalPages: number
  hasNextPage: boolean
  hasPrevPage: boolean
}

interface ListItems {
  items: Array<Items>
  refetch: (value: any) => void
  pagination: PaginationArgs
  loading: boolean
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Due Date', key: 'dueDate', isSortable: true },
  { label: 'Respondent Count', key: 'respondentCount', isSortable: true },
  {label: 'Organisation', key: 'organisation', isSortable: true},
  {label: 'Peer', key: 'peer', isSortable: true},
  { label: 'Status', key: 'status', isSortable: true },
  { label: '', key: 'id', isSortable: false }
]

const AssignmenList: React.FC<ListItems> = ({
  items,
  refetch,
  pagination: { totalPages, currentPage, hasNextPage, hasPrevPage },
  loading
}) => {
  const [sortField, direction, sortCallback] = useSort()
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)

  const navigate = useNavigate()

  useEffect(() => {
    refetch({ pagination: { limit, sort: 'ACS', page } })
  }, [page])

  useEffect(() => {
    if (sortField && direction) {
      refetch({ sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') } })
    }
  }, [sortField, direction])

  const onSearch = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === '') {
        delete data[key]
      }
    })

    if (sortField && direction) {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        sort: { direction: direction.toUpperCase(), sortField: sortField.replace('-', '') },
        input: data
      })
    } else {
      refetch({
        pagination: { limit, sort: 'ACS', page: 1 },
        input: data
      })
    }
  }

  const onClear = () => {
    refetch({ pagination: { limit, sort: 'ACS', page: 1 }, input: {} })
  }

  if (loading) return <Loader color={Color.PURPLE} />
  return (
    <div>
      <Search onSearch={onSearch} searchParams={searchParams} onClear={onClear} />
      <Table
        shape={TABLE_SHAPE}
        activeSort={direction === 'desc' ? sortField?.slice(1) : sortField}
        sortDirection={direction}
        sortCallback={sortCallback}
      >
        {items?.map((item) => {
          return (
            <Table.Row
              key={item.id}
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/admin/scans/${item.id}`)}
            >
              <Table.Cell className="font-medium text-gray-900">{item.name}</Table.Cell>
              <Table.Cell className="text-gray-900">{formatDate(item.dueDate)}</Table.Cell>
              <Table.Cell className="text-gray-900">{item?.respondentCount}</Table.Cell>
              <Table.Cell className="text-gray-900">{item?.organization?.name}</Table.Cell>
              <Table.Cell className="text-gray-900">{item?.peer?.name}</Table.Cell>
              <Table.Cell className="text-gray-900">{item?.status}</Table.Cell>
              <Table.Cell className="text-gray-900">
                <Button
                  iconRight="arrowRight"
                  color={Color.ORANGE}
                  variant={ButtonVariant.PLAIN}
                  size={ButtonSize.MEDIUM}
                  onClick={() => navigate(`/dashboard/peer/edit-scan/${item.id}`)}
                >
                  View Scan 
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      <TablePagination
        page={currentPage}
        limit={limit}
        nextPage={nextPage}
        prevPage={prevPage}
        totalPages={totalPages}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      />
    </div>
  )
}

export default AssignmenList
