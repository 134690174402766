import { Tab } from '@headlessui/react'

import React from 'react'

type SingleTabProps = {
  name: string
  content: any
}

interface TabsProps {
  tabs: SingleTabProps[]
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  return (
    <div className="pt-2 pb-4">
      <Tab.Group>
        <Tab.List>
          {tabs.map((tab) => {
            return (
              <Tab
                className={({ selected }: any) =>
                  selected
                    ? 'border-b-2 outline-none border-brand-orange text-brand-orange mr-4 pb-2 my-3'
                    : 'mr-4 my-3 text-brand-orange'
                }
              >
                {tab.name}
              </Tab>
            )
          })}
        </Tab.List>
        <Tab.Panels className="pt-4">
          {tabs.map((tab) => {
            return <Tab.Panel>{tab.content}</Tab.Panel>
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Tabs
