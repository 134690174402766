import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { LocationProvider } from '@reach/router'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import client from './apollo'

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DNS ||
    'https://3e4134e6b3b04c40bdb48984345f865f@o438697.ingest.sentry.io/4504003858399232',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production' ? true : false
})

const tagManagerArgs = {
  gtmId: 'GTM-PXQ7V5N'
}

// Add React-GTM https://www.npmjs.com/package/react-gtm-module
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <LocationProvider>
        <App />
      </LocationProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
