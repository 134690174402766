import List from '../../../../components/List'
import { ListItemCta, ListItemMeta } from './OrgScanListItem'
import ListItem from '../../../../components/ListItem'
import { Color } from '../../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../../components/Button'
import { navigate } from '@reach/router'
import Loader from '../../../../components/Loader/Loader'
interface Assignment {
  dueDate: Date
  id: string
  name: string
  status: string
  respondentCount: number
  preSurveyStatus: string
  organization: any
  questionnaireStatus: any
  respondentProfile: {
    id: string
    surveyStatus: string
  }
  isOwner: boolean
}

interface AssignmentItem {
  headline: string
  orgName?: string
  color?: Color
  renderMeta?: JSX.Element
  renderStatus?: JSX.Element
  renderCta?: (color: Color) => JSX.Element
}

interface AssignmentListProps {
  assignments: Assignment[]
  loading: boolean
  organizationId: string | undefined
}

const OrgScanList: React.FC<AssignmentListProps> = ({ assignments, loading }) => {
  if (loading) return <Loader color={Color.PURPLE} />

  const myAssignments = assignments.filter((assignment: Assignment) => {
    if (assignment.isOwner) {
      return true
    } else {
      if (assignment.status !== 'PENDING') {
        return true
      }
    }
  })

  const items = {
    color: Color.BLUE,
    children: (color: Color): JSX.Element => {
      return (
        <>
          {myAssignments?.map((assignment) => {
            const item: AssignmentItem = {
              headline: assignment.name,
              orgName: assignment?.organization?.name,
              renderMeta: <ListItemMeta dueDate={assignment.dueDate} status={assignment.status} questionnaireStatus={assignment.questionnaireStatus}/>,
              renderCta: (color: Color): JSX.Element => {
                let step = 'add-participants'
                if (assignment.respondentCount > 0 && assignment.preSurveyStatus !== 'COMPLETED') {
                  step = 'pre-survey'
                } else if (assignment.respondentCount > 0 && assignment.status !== 'COMPLETED') {
                  step = 'confirmation'
                }
                if (assignment.status === 'PENDING' || assignment.questionnaireStatus === 'PENDING') {
                  return (
                    <div className="flex items-center">
                      <Button
                        iconRight="arrowRight"
                        variant={ButtonVariant.PLAIN}
                        color={color}
                        size={ButtonSize.SMALL}
                        className="mr-3"
                        onClick={() => navigate(`/dashboard/organisation/scan/${assignment.id}/add-participants`)}
                      >
                        Continue setup
                      </Button>
                    </div>
                  )
                } else {
                  return (
                    <ListItemCta
                      color={color}
                      assignmentStatus={assignment.status}
                      questionnaireStatus={assignment.questionnaireStatus}
                      assignmentId={assignment.id}
                      respondentId={assignment?.respondentProfile?.id}
                      respondentSurveyStatus={assignment?.respondentProfile?.surveyStatus}
                      respondentCount={assignment?.respondentCount}
                    />
                  )
                }
              }
            }
            return <ListItem type="scan" color={color} {...item} />
          })}
        </>
      )
    }
  }

  return <List {...items} className="mb-10" />
}

export default OrgScanList
