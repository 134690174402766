import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation } from '@apollo/client'
import { CreateDemographicSurveyForm } from './createDemographicSurveyFormOrg'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { User } from '../../../../common/types'
import {Helmet} from 'react-helmet';
import { language } from '../../../../common/i18n'

interface CreateDemographicSurveyInterface extends RouteComponentProps<any> {
  location: any
  user?: User
}

const CreateDemographicSurvey: React.FC<CreateDemographicSurveyInterface> = (props) => {
  const navigate = useNavigate()
  const { preSurveyId, surveyId } = useParams()
  const { pathname } = useLocation()

  const [createPreSurvey, { loading: createFactorLoading}] = useMutation(CREATE_PRE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${data?.createPreSurvey?.id}/org`)
    }
  })

  const [updatePreSurvey, { loading: updateLoading }] = useMutation(UPDATE_PRE_SURVEY, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/demographic-survey/${data?.updatePreSurvey?.id}/org`)
    }
  })

  const type = pathname.endsWith('edit') ? 'edit' : 'create'

  const onCreatePreSurvey = async (data: any, type: string) => {
    if (type === 'create') {
      createPreSurvey({
        variables: {
          input: { ...data, channelId: props?.user?.channel.id, surveyId: surveyId }
        }
      })
    } else {      
      updatePreSurvey({
        variables: {
          preSurveyId,
          input: { name: data.name, description: data.description, headline: data.headline, localeId: data.localeId }
        }
      })
    }
  }

  return (
    <div className="page-container-lg">
      <Helmet>
        <title>{language.en.admin.survey.create.title}</title>
        <meta name="description" content={language.en.admin.survey.create.title} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <main>
        <HeadingPageCenter
          className="text-center"
          headline={pathname.endsWith('edit') ? 'Edit Demographic Questionaire' : 'Create Demographic Questionaire'}
          color={Color.GRAY}
        />
        <div className="section-container-sm">
          <CreateDemographicSurveyForm
            loading={createFactorLoading || updateLoading}
            onSubmit={onCreatePreSurvey}
            formType={type}
            preSurveyId={preSurveyId}
          />
        </div>
      </main>
    </div>
  )
}

export default CreateDemographicSurvey

const CREATE_PRE_SURVEY = gql`
  mutation createPreSurvey($input: CreatePreSurveyInput!) {
    createPreSurvey(input: $input) {
      name
      id
      description
      headline
    }
  }
`
export const UPDATE_PRE_SURVEY = gql`
  mutation updatePreSurvey($input: UpdatePreSurveyInput!, $preSurveyId: String!) {
    updatePreSurvey(input: $input, preSurveyId: $preSurveyId) {
      id
      name
    }
  }
`
