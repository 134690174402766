import React, { useEffect, useState } from 'react'
import { Redirect } from '@reach/router'
export const Unauthorized: React.FC = () => {
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    setTimeout(() => {
        setRedirect(true)
    }, 1000)
  }, [])

  if (redirect) {
    return <Redirect noThrow to={'/login'} />
  }
  
  return null
}
