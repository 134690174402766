import { RouteComponentProps } from '@reach/router'
import { Color } from '../../../color.enum'
import { User } from '../../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import { useDownloadCsv } from '../../../hooks/useDownloadCsv'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { PageWrapper } from '../../../modules/PageWrapper'

interface AdminProps extends RouteComponentProps {
  user?: User
}

export const Reports: React.FC<AdminProps> = () => {
  const [download, { loading, error }] = useDownloadCsv()

  return (
    <PageWrapper
      metaTitle="Reports"
      metaDesc="Assignment details per participant"
      headline="Reports"
      sectionHeading="Scan details per participant"
      renderCta={() => {
        return (
          <div className="flex items-center">
            <Button
              variant={ButtonVariant.PRIMARY}
              color={Color.PURPLE}
              size={ButtonSize.SMALL}
              onClick={download}
              iconRight="arrowRight"
            >
              {loading ? 'Downloading..' : 'Download'}
            </Button>
          </div>
        )
      }}
    >
      {error && (
        <Alert className="page-container mt-4" size={AlertSize.SMALL} variant={AlertVariant.ERROR}>
          {error}
        </Alert>
      )}
    </PageWrapper>
  )
}
