export function canvasToBuffer(canvas: any, bufferCallback: (buffer: string | ArrayBuffer | HTMLImageElement) => void): void {
  const img = new Image()
  canvas.toBlob(async (blob: any) => {
    if (blob) {
      const reader = new FileReader()
      reader.addEventListener('loadend', () => {
        const arrayBuffer = reader.result
        //display Blop contn=ent in an Image
        if (arrayBuffer) {
          const blob = new Blob([arrayBuffer], { type: 'image/png' })
          img.src = URL.createObjectURL(blob)
          bufferCallback(img)
        }
      })
      reader.readAsArrayBuffer(blob)
    }
  }, 'image/png')
}
