import { Color } from "../../color.enum"

export const stepsSupporting = [
  { title: 'Competent Leadership', isComplete: true, color: Color.BLUE },
  { title: 'Effective Governance', isComplete: true, color: Color.BLUE },
  { title: 'Relevant Measures', isComplete: true, color: Color.BLUE },
  { title: 'Reliable Operations', isComplete: false, color: Color.BLUE },
  { title: 'Clear Mission', isComplete: false, color: Color.PURPLE },
  { title: 'Healthy Culture', isComplete: false, color: Color.PURPLE },
  { title: 'Impactful Strategy', isComplete: false, color: Color.PURPLE },
  { title: 'Sound Resourcing Plan', isComplete: false, color: Color.PURPLE }
]

export const stepsSustaining = [
  { title: 'Competent Leadership', isComplete: true, color: Color.BLUE },
  { title: 'Effective Governance', isComplete: true, color: Color.BLUE },
  { title: 'Relevant Measures', isComplete: true, color: Color.BLUE },
  { title: 'Reliable Operations', isComplete: true, color: Color.BLUE },
  { title: 'Clear Mission', isComplete: true, color: Color.PURPLE },
  { title: 'Healthy Culture', isComplete: false, color: Color.PURPLE },
  { title: 'Impactful Strategy', isComplete: false, color: Color.PURPLE },
  { title: 'Sound Resourcing Plan', isComplete: false, color: Color.PURPLE }
]
