import { Input } from '../../../components/Input/Input'
import { Controller, useForm } from 'react-hook-form'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import { Color } from '../../../color.enum'
import { InputValidator } from '../../../services/validate'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import { language } from '../../../common/i18n'
interface Props {
  onSubmit?: (data: any) => void
  loading?: boolean
  resendOtp?: (data: any) => void
  setUser?: (data: any) => void
  setError?: (data: any) => void
  onResendOtp: () => void
  resendOtpLoading: boolean
  resendOtpResponse?: any
  resendOtpSuccess: boolean
  delayOtp: boolean
  error?: string
  headline?: string
}
const otpFieldOptions = {
  required: language.en.error.requiredField,
  validate: (input: string) => new InputValidator(input).validateOtp() || language.en.error.otp
}
export const OtpForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm()

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data.otp)
    }
  }
  return (
    <div className="mx-auto p-4 mt-8">
      {props.headline && <h3 className="text-white font-normal leading-none text-3xl md:text-5xl">{props.headline}</h3>}
      <h3 className="text-gray-400 my-5 font-normal ">{language.en.auth.title.login}</h3>
      <Alert className={'mb-2 w-full'} size={AlertSize.SMALL} variant={AlertVariant.INFO}>
        {language.en.auth.title.otpSuccess}
      </Alert>
      <FormLabel>{language.en.auth.title.otp}</FormLabel>
      <form action="#" autoComplete="no" onSubmit={handleSubmit(onRegister)}>
        <Controller
          rules={{ ...otpFieldOptions }}
          name="otp"
          control={control}
          render={({ field }) => <Input as="input" width="full" {...field} />}
        />
        {errors?.otp?.message && <p className="text-sm text-red-500 mt-2">{errors.otp.message}</p>}
        {errors.otp && !errors.otp.message && <p className="text-sm text-red-500 mt-2">{language.en.error.requiredField}</p>}
        {props?.error ? (
          <Alert className={'mb-2 mt-2 w-full'} size={AlertSize.SMALL} variant={AlertVariant.ERROR}>
            {props.error}
          </Alert>
        ) : null}
        <div className="py-4">
          <Button
            className="justify-center"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            color={Color.ORANGE}
            style={{ width: '100%' }}
            type="submit"
          >
            {props.loading ? 'Loading...' : 'Log in'}
          </Button>
          <Button
            className="justify-center mt-3"
            variant={ButtonVariant.PLAIN}
            size={ButtonSize.LARGE}
            color={Color.ORANGE}
            style={{ width: '100%' }}
            onClick={(e) => {
              e.preventDefault()
              props.onResendOtp()
            }}
            disabled={props.delayOtp}
            iconRight="sync"
          >
            {props.resendOtpLoading ? 'Sending...' : 'Resend OTP'}
          </Button>
          {props.resendOtpSuccess ? (
            <Alert
              className="w-full"
              variant={AlertVariant.SUCCESS}
              size={AlertSize.SMALL}
              children={<div>{props?.resendOtpResponse?.resendOtp?.message}</div>}
            />
          ) : null}
        </div>
      </form>
    </div>
  )
}
