import { RouteComponentProps, useNavigate } from '@reach/router'
import { Color } from '../../color.enum'
import HeadingPage from '../../components/HeadingPage'
import HeadingSection from '../../components/HeadingSection'
import { User } from '../../common/types'
import Button, { ButtonVariant, ButtonSize } from '../../components/Button'
import { Helmet } from 'react-helmet'
import { language } from '../../common/i18n'
import { roleMenuItems } from '../../components/Nav/MainNav'

interface AdminProps extends RouteComponentProps {
  user?: User
}

export const Dashboard: React.FC<AdminProps> = ({ user }) => {
  const navigate = useNavigate()
  const { items, title, heading } = roleMenuItems(user)

  return (
    <div className="page-container-lg lg:mb-28">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <header>
        <HeadingPage headline={heading} color={Color.ORANGE} />
      </header>
      <main>
        {items.map((sec: any, i: any) => {
          if (sec.hidden) return null
          return (
            <HeadingSection
              headline={sec.description}
              color={Color.ORANGE}
              children={() => {
                return (
                  <div className="flex items-center" key={i}>
                    <Button
                      variant={ButtonVariant.PRIMARY}
                      color={Color.PURPLE}
                      size={ButtonSize.SMALL}
                      onClick={() => navigate(sec.to)}
                      iconRight="arrowRight"
                    >
                      {language.en.admin.dashBoard.buttonText.view}
                    </Button>
                  </div>
                )
              }}
            />
          )
        })}
      </main>
    </div>
  )
}
