import React from 'react'
import { Color } from '../../color.enum'
import { classNames } from '../../utils/class-names'

interface ProgressProps {
  /** Headline for the component */
  headline: string
  /** How many questions are there? */
  totalQuestions: number
  /** What is the current question? */
  questionNumber: number
  /** What color to use? */
  color?: Color
  /** Aditional Styles for the container */
  className?: string
}

export const COLOR_MAPS: Record<Color, string> = {
  [Color.BLUE]: 'text-brand-blue',
  [Color.PURPLE]: 'text-brand-purple',
  [Color.GRAY]: 'text-gray-500',
  [Color.RED]: 'text-brand-red',
  [Color.ORANGE]: 'text-brand-orange'
}

/**
 *  Component to show Progress
 */

const Progress: React.FC<ProgressProps> = ({
  className,
  headline,
  questionNumber,
  totalQuestions,
  color
}: ProgressProps) => {
  return (
    <div className={classNames(className)}>
      <p className={classNames('font-medium tracking-widest uppercase text-white', color && COLOR_MAPS[color])}>
        {headline} {questionNumber}/{totalQuestions}
      </p>
    </div>
  )
}

Progress.defaultProps = {
  color: Color.ORANGE
}

export default Progress
