import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { Content } from '../../../common/types'
import PDFRichText from '../RichText'
import theme from '../../../theme'

export interface BlockquoteProps {
  headline?: string
  cite?: string
  content?: Content
}

const styles = StyleSheet.create({
  container: {
    borderLeftWidth: 3,
    borderLeftColor: theme.colors['brand-blue-light'],
    paddingLeft: 8
  },
  text: {
    color: theme.colors['brand-blue'],
    fontSize: 10,
  },
  cite: {
    fontSize: 8
  },
  headline: {
    fontWeight: 'medium'
  }
})

const Blockquote: React.FC<BlockquoteProps> = (props) => {
  const { headline, cite, content } = props
  return (
    <View style={styles.container}>
      {headline && <Text style={[styles.text, styles.headline]}>{headline}</Text>}
      {content && <PDFRichText content={content} styleOverrides={styles} />}
      {cite && <Text style={[styles.text, styles.cite]}>{cite}</Text>}
    </View>
  )
}

export default Blockquote
