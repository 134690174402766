import { useEffect } from 'react'
import { RouteComponentProps, useNavigate, useParams, useLocation } from '@reach/router'
import { Color } from '../../../../color.enum'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import HeadingPageCenter from '../../../../components/HeadingPageCenter'
import { Helmet } from 'react-helmet'
import { language } from '../../../../common/i18n'
import { FormWrapper } from '../../../../modules/FormWrapper'
import { useFormObj } from '../../../../hooks/useForm'
import { FormTypes } from '../../../../common/constants'
import Loader from '../../../../components/Loader/Loader'

interface CreateFactorInterface extends RouteComponentProps<any> {
  location: any
}

const CreateFactor: React.FC<CreateFactorInterface> = () => {
  const navigate = useNavigate()
  const { surveyId, factorId } = useParams()
  const { pathname } = useLocation()
  const form = useFormObj('factor')
  const type = pathname.endsWith('edit-factor') ? FormTypes.edit : FormTypes.create
  const { data: factorTypes, loading: ftLoading } = useQuery(GET_ALL_FACTOR_TYPE)
  const [createFactor, { loading: createFactorLoading }] = useMutation(CREATE_FACTOR, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      navigate(`/dashboard/admin/survey/${surveyId}/dimension/${data?.createFactor?.id}`)
    }
  })

  const [updateFactor, { loading: updateLoading }] = useMutation(UPDATE_FACTOR, {
    onError: (error) => {
      console.log(error)
    },
    onCompleted: (data) => {
      console.log(data)
      navigate(`/dashboard/admin/survey/${surveyId}/dimension/${factorId}`)
    }
  })

  const [loadFactorData, { loading, data }] = useLazyQuery(GET_A_SINGLE_FACTOR, {
    variables: { id: factorId },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (type === FormTypes.edit) {
      loadFactorData()
    }
  }, [])

  const onSubmit = (data: any, type: FormTypes) => {
    if (type === FormTypes.create) {
      const { position, factorType, ...rest } = data

      createFactor({
        variables: {
          input: { ...rest, surveyId: surveyId, factorId: factorId, position: position, factorTypeId: factorType }
        }
      })
    } else {
      const { position, weight, factorType, ...rest } = data

      updateFactor({
        variables: {
          factorId,
          input: {
            position: Number(position),
            weight: Number(weight),
            name: rest.name,
            code: rest.code,
            description: rest.description,
            headline: rest.headline,
            content: rest.content,
            discussionQuestions: rest.discussionQuestions,
            factorTypeId: factorType,
            image: rest.image,
            coreFactor: rest.coreFactor
          }
        }
      })
    }
  }

  if (ftLoading || loading) return <Loader color={Color.PURPLE} />

  return (
    <main>
      <div className="page-container-lg">
        <Helmet>
          <title>{language.en.admin.factor.add.title}</title>
          <meta name="description" content={language.en.admin.factor.add.description} />
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>
        <HeadingPageCenter
          headline={type === FormTypes.edit ? 'Edit Dimension' : 'Create New Dimension'}
          color={Color.ORANGE}
        />
        <div className="section-container-sm">
          <FormWrapper
            buttonLabel="Submit"
            loading={createFactorLoading || updateLoading}
            form={form}
            onSubmit={onSubmit}
            formType={type}
            customDorpDownValues={[{ parent: 'factorType', values: factorTypes.allFactoryTypes }]}
            data={data?.factor}
          />
        </div>
      </div>
    </main>
  )
}

export default CreateFactor

const CREATE_FACTOR = gql`
  mutation createFactor($input: CreateFactorInput!) {
    createFactor(input: $input) {
      name
      id
    }
  }
`
const UPDATE_FACTOR = gql`
  mutation updateFactor($factorId: String!, $input: UpdateFactorInput!) {
    updateFactor(input: $input, factorId: $factorId) {
      name
      id
    }
  }
`

const GET_ALL_FACTOR_TYPE = gql`
  query allFactoryTypes {
    allFactoryTypes {
      id
      createdAt
      name
      code
      description
    }
  }
`

const GET_A_SINGLE_FACTOR = gql`
  query SingleFactor($id: String!) {
    factor(id: $id) {
      id
      name
      headline
      code
      description
      discussionQuestions
      content
      weight
      position
      image
      coreFactor
      factorType {
        id
      }
      statements {
        id
        statement
      }
      preSurveyOption {
        id
      }
    }
  }
`
