import { useState, useEffect } from 'react'
import { useNavigate, RouteComponentProps } from '@reach/router'
import { useQuery, gql, useMutation } from '@apollo/client'
import { User } from '../../../common/types'
import { Color } from '../../../color.enum'
import Button, { ButtonVariant, ButtonSize } from '../../../components/Button'
import HeadingSection from '../../../components/HeadingSection'
import { InputValidator } from '../../../services/validate'
import { useForm, Controller } from 'react-hook-form'
import { Box } from '@plusplusminus/plusplusdash'
import Input from '../../../components/Input/Input'
import parse from 'html-react-parser'
import HeadingPageCenter from '../../../components/HeadingPageCenter'
import Modal from '../../../components/Modal/Modal'
import Alert, { AlertSize, AlertVariant } from '../../../components/Alert'
import Notice, { NoticeVariant } from '../../../components/Notice'
import Loader from '../../../components/Loader/Loader'
import { useFormatApolloError } from '../../../hooks/useFormatApolloError'
import { Select } from '../../../components/Select/Select'
import FormLabel from '../../../components/FormLabel/FormLabel'
import Icon from '../../../components/Icon/Icon'
import { USER_QUERY } from '../../../graphql/queries'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: 'First name is required'
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
    placeholder: 'Last Name',
    options: {
      required: 'Last name is required'
    }
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    type: 'text',
    options: {
      required: 'Email is required',
      validate: (input: string) => new InputValidator(input).validateEmail() || 'Enter a valid email'
    }
  },
  {
    label: 'Current role in organisation',
    name: 'orgRole',
    placeholder: 'Role',
    options: {
      required: 'Role is required'
    },
    type: 'select',
    items: [
      { id: 'leader', name: 'Leader/CEO' },
      { id: 'boardMember', name: 'Board Member' },
      { id: 'seniorManagement', name: 'Senior Management' },
      { id: 'management', name: 'Management' },
      { id: 'teamMember', name: 'Team Member' }
    ]
  },
  {
    label: 'Branch in organisation',
    name: 'branch',
    placeholder: 'Branch',
    options: {
      required: 'Branch is required'
    },
    type: 'select',
    items: [
      { id: 'centralOffice', name: 'Central office' },
      { id: 'fieldOffice', name: 'Field office' },
      { id: 'other', name: 'Other' }
    ]
  },
  {
    label: 'Participant Department',
    name: 'department',
    placeholder: 'Department',
    options: {
      required: 'Department is required'
    },
    type: 'select',
    items: [
      { id: 'board', name: 'Board/Oversight' },
      { id: 'leadershipTeam', name: 'Leadership Team' },
      { id: 'finance', name: 'Finance' },
      { id: 'funding', name: 'Funding and Communication' },
      { id: 'operations', name: 'Operations and Admin' },
      { id: 'projects', name: 'Projects and Admin' },
      { id: 'other', name: 'Other' }
    ]
  }
]

interface Respondent {
  user: User
  id: string
}
interface RespondentListProps {
  respondents: Array<Respondent>
  loading: boolean
  handleDeleteRespondent: (userId: string) => void
  deleteRespLoading: boolean
}

export const RespondentList: React.FC<RespondentListProps> = ({
  respondents = [],
  loading,
  handleDeleteRespondent,
  deleteRespLoading
}) => {
  if (loading) return <Loader color={Color.PURPLE} className="h-full my-10" />

  const navigate = useNavigate()
  return (
    <>
      {!respondents.length ? (
        <Notice variant={NoticeVariant.SMALL} className="py-6 flex-1.5 mb-8" icon="info">
          Please add your first participant. Please note that there is a maximum number of 10 participants allowed per
          Scan.
        </Notice>
      ) : (
        <>
          <div className="mb-4">
            {respondents.map((respondent) => (
              <div className="mb-4 flex text-md text-white justify-between">
                <div className="flex flex-col">
                  <span className="block">
                    {respondent.user.firstName} {respondent.user.lastName}
                  </span>
                  <span className="block">{respondent.user.email}</span>
                </div>
                <div className="flex flex-col">
                  <Button
                    variant={ButtonVariant.PLAIN}
                    size={ButtonSize.SMALL}
                    color={Color.RED}
                    onClick={() => handleDeleteRespondent(respondent.id)}
                    disabled={deleteRespLoading}
                  >
                    <span className="mr-2">{deleteRespLoading ? 'Removing...' : 'Remove'}</span>
                    <Icon name="close" />
                  </Button>
                  <Button
                    variant={ButtonVariant.PLAIN}
                    size={ButtonSize.SMALL}
                    color={Color.ORANGE}
                    onClick={() => navigate(`/dashboard/organisation/${respondent.user.id}/edit-user`)}
                    disabled={deleteRespLoading}
                  >
                    <span className="mr-2"> Edit </span>
                    <Icon name="pen" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

interface AppParticipantProps extends RouteComponentProps {
  user?: User
}

export const AppParticipant: React.FC<AppParticipantProps & any> = ({ ...props }) => {
  const { loading, data, loading: userLoading } = useQuery(USER_QUERY, { fetchPolicy: 'no-cache' })
  const [maxErrors, setErrors] = useState<any>()
  const [showCreate, setShowCreate] = useState(false)
  const navigate = useNavigate()
  const {
    loading: resLoading,
    data: resData,
    refetch
  } = useQuery(GET_RESPONDENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      assignmentId: props.scanId
    }
  })

  useEffect(() => {
    if (resData?.respondents.length >= props.maxRespondents) {
      setErrors('You have reached maximum number of participants')
    }
  }, [resData])

  const [deleteRespondent, { loading: deleteRespLoading }] = useMutation(DELETE_RESPONDENT, {
    onCompleted: () => {
      setErrors('')
      refetch({
        assignmentId: props.scanId
      })
    }
  })

  const [updateAssignment] = useMutation(UPDATE_ASSIGNMENT_PARTICIPANTS)

  const handleDeleteRespondent = (userId: string) => {
    if (userId) {
      deleteRespondent({
        variables: {
          respondentId: userId,
          assignmentId: props.scanId
        }
      }).then((response) => {
        if (response?.data) {
          console.log(response?.data)
        }
      })
    }
  }

  if (loading || userLoading) return <Loader color={Color.PURPLE} />
  const { me: user } = data

  return (
    <div className="page-container-lg">
      <HeadingPageCenter
        className="text-center mb-10"
        headline="Scan Participants"
        color={Color.GRAY}
        description={parse(
          'Fill in the form below to add new participants to the scan. The scan will be asking questions about your organisation, so it is important to invite participants who know your organisation well.'
        )}
      />
      <div className="section-container-sm">
        <div className="flex flex-col">
          <div className="flex-1 flex flex-col">
            <AddParticipantsModal
              showCreate={showCreate}
              setShowCreate={setShowCreate}
              refetch={refetch}
              assignmentId={props.scanId}
              orgId={user.organization.id}
              respondentCount={resData?.respondents.length}
              maxRespondents={props.maxRespondents}
              user={props.user}
              inList={resData?.respondents.some((res: any) => res.user.id === user.id)}
            />
          </div>
          <div className="flex-1 flex flex-col py-2">
            <HeadingSection
              className="mt-10 sm:mb-4"
              headline="Participants"
              color={Color.ORANGE}
              children={() => {
                return (
                  <div className="flex items-center">
                    <Button
                      iconRight="plus"
                      className="justify-center"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      color={Color.PURPLE}
                      style={{ width: '100%' }}
                      type="submit"
                      onClick={() => {
                        setShowCreate(true)
                      }}
                    >
                      Add new
                    </Button>
                  </div>
                )
              }}
            />
            {resData?.respondents.length > 0 && (
              <RespondentList
                loading={resLoading}
                respondents={resData?.respondents}
                handleDeleteRespondent={handleDeleteRespondent}
                deleteRespLoading={deleteRespLoading}
              />
            )}
            <div className="flex items-center col-span-2">
              <input
                id="canViewResults"
                type="checkbox"
                className="h-4 w-4 text-brand-orange focus:ring-purple-800F border-gray-300 rounded mt-2 mb-2"
                onChange={(e) => {
                  updateAssignment({
                    variables: {
                      id: props.scanId,
                      input: {
                        canViewResults: e.target.checked
                      }
                    }
                  })
                }}
              />
              <label className="font-medium text-white my-1 inline-block form-label ml-2 mb-3" htmlFor="canViewResults">
                Respondents can view organisational (rolled up) results in addition to their own results
              </label>
            </div>
            <div className="flex space-x-2">
              <div className="flex-1">
                <Button
                  iconRight="arrowRight"
                  className="justify-center"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  color={Color.ORANGE}
                  style={{ width: '100%' }}
                  type="submit"
                  onClick={() => {
                    if (!resData?.respondents.length) {
                      setErrors('Please add a user before progressing')
                    } else {
                      navigate(`/dashboard/organisation/scan/${props.scanId}/questionnaire`)
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>

            {maxErrors && (
              <Alert variant={AlertVariant.ERROR} size={AlertSize.SMALL} children={maxErrors} className="w-100 mt-2" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface ModalProps extends RouteComponentProps {
  showCreate: boolean
  setShowCreate: (showCreate: boolean) => void
  refetch: any
  assignmentId: string
  orgId: string
  respondentCount: number
  maxRespondents: number
  user: User
  inList: boolean
}

const AddParticipantsModal: React.FC<ModalProps> = ({
  showCreate,
  setShowCreate,
  refetch,
  assignmentId,
  orgId,
  maxRespondents,
  respondentCount,
  user,
  inList
}) => {
  const [error, setErrors] = useState<any>()
  const [formatError] = useFormatApolloError()
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue
  } = useForm()

  const [createRespondent, { loading }] = useMutation(CREATE_RESPONDENT, {
    onError: (error) => {
      const err = formatError(error)
      //ugly hack of a solution but the createRespondent function on the api is being reused so error message is not relatable to adding a respondent
      if (err === 'User already exists') {
        setErrors('User already exists. Please use a different email address or add an existing user.')
      } else {
        setErrors(err)
      }
    },
    onCompleted: () => {
      setShowCreate(false)
      reset()
      refetch({
        assignmentId: assignmentId
      })
    }
  })

  useEffect(() => {
    if (showCreate) {
      setErrors('')
      reset()
    }
  }, [showCreate])

  const onRegister = async (data: any) => {
    createRespondent({
      variables: {
        input: { ...data, organizationId: orgId },
        assignmentId: assignmentId
      }
    }).then((response) => {
      if (response?.data?.createRespondent?.id) {
        reset()
      }
    })
  }

  return (
    <Modal isModalOpen={showCreate} onClose={() => setShowCreate(false)}>
      <HeadingSection
        headline="Add new participant"
        color={Color.GRAY}
        children={() => {
          if (!inList) {
            return (
              <Button
                variant={ButtonVariant.PLAIN}
                size={ButtonSize.MEDIUM}
                color={Color.ORANGE}
                disabled={respondentCount >= maxRespondents}
                iconRight="plus"
                onClick={(e) => {
                  e.preventDefault()
                  setValue('firstName', user.firstName)
                  setValue('lastName', user.lastName)
                  setValue('email', user.email)
                }}
              >
                Add myself
              </Button>
            )
          } else {
            return <></>
          }
        }}
      />

      <div className="flex-1  flex flex-col">
        <form
          action="#"
          autoComplete="no"
          onSubmit={handleSubmit(onRegister)}
          className="mt-0 grid grid-cols-2 gap-y-4  sm:gap-x-8"
        >
          {form.map((field) => {
            if (field.type === 'checkBox') {
              return (
                <Box className="flex items-center col-span-2">
                  <input
                    id={field.name}
                    type="checkbox"
                    className="h-4 w-4 text-white focus:ring-indigo-500 border-gray-300 rounded"
                    {...register(field.name)}
                  />
                  <FormLabel>{field.label}</FormLabel>
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </Box>
              )
            }

            if (field.type === 'select') {
              return (
                <Box className="col-span-2 sm:col-span-2" key={field.name}>
                  <FormLabel>{field.label}</FormLabel>
                  <Select items={field.items} name={field.name} register={register} validations={field.options} />
                  {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
                </Box>
              )
            }

            return (
              <Box className="col-span-2 sm:col-span-2" key={field.name}>
                <FormLabel className="my-1 inline-block">{field.label}</FormLabel>

                {field.type === 'text' && (
                  <Controller
                    rules={{ ...field.options }}
                    name={field.name}
                    control={control}
                    render={({ field }) => <Input as="input" width="full" {...field} />}
                  />
                )}
                {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
              </Box>
            )
          })}
          <div className="col-span-2">
            {respondentCount >= maxRespondents && (
              <Alert
                size={AlertSize.SMALL}
                variant={AlertVariant.ERROR}
                children="Maximum number of respondents reached"
              />
            )}

            {error && <Alert size={AlertSize.SMALL} variant={AlertVariant.ERROR} children={error} className="w-100" />}

            <Button
              style={{ width: '100%' }}
              className="mb-2 mt-3 justify-center"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              color={Color.ORANGE}
              type="submit"
              disabled={respondentCount >= maxRespondents}
              iconRight="arrowRight"
            >
              {loading ? 'Loading...' : 'Add'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export const CREATE_RESPONDENT = gql`
  mutation createRespondent($input: CreateRespondentInput!, $assignmentId: String!) {
    createRespondent(input: $input, assignmentId: $assignmentId) {
      id
    }
  }
`
export const CREATE_RESPONDENT_PROFILE = gql`
  mutation createRespondentProfile($userId: String!, $assignmentId: String!, $input: CreateRespondentProfileInput!) {
    createRespondentProfile(userId: $userId, assignmentId: $assignmentId, input: $input) {
      id
    }
  }
`
const GET_RESPONDENTS = gql`
  query respondents($assignmentId: String!) {
    respondents(assignmentId: $assignmentId) {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`

const DELETE_RESPONDENT = gql`
  mutation deleteRespondent($respondentId: String!, $assignmentId: String!) {
    deleteRespondent(respondentId: $respondentId, assignmentId: $assignmentId) {
      message
      status
    }
  }
`

const UPDATE_ASSIGNMENT_PARTICIPANTS = gql`
  mutation updateAssignmentResp($input: UpdateAssignmentInput!, $id: String!) {
    updateAssignment(input: $input, assignmentId: $id) {
      id
    }
  }
`
