import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Icon from '../../../../components/Icon/Icon'

export function SortableItem(props: any) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style} className="relative">
      <div
        className="bg-gray-100 w-3 h-full absolute top left border-b-1 border-gray-500 hover:bg-gray-200 transition-all
        active:bg-gray-300"
      >
        <button className="h-full w-full cursor-move focus:outline-none" {...listeners} {...attributes}>
          <div className="flex justify-center items-center">
            <Icon name="grip" className="opacity-40 transition-all" />
          </div>
        </button>
      </div>
      {props.children}
    </div>
  )
}
