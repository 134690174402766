import { Color } from '../../color.enum'

export const api = {
  headline:
    '“Because ends exist only in the imagination, they can be infinite… Means, though, are stubbornly finite; they’re the boots on the ground, the ships in the sea, and the bodies required to fill them. Ends and means have to connect if anything is to happen. They are never, however, interchangeable.”',
  cite: 'John Lewis Gaddis',
  description: 'On Grand Strategy',
  color: Color.BLUE,
  image: (
    <img
      src="https://live.staticflickr.com/7186/6988609249_9ab90240bf_b.jpg"
      alt=""
      className="w-full h-full object-cover"
    />
  )
}
