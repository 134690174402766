import { useCallback, useState } from 'react'

export type Direction = 'asc' | 'desc'
export type SortTuple = [string | undefined, Direction | undefined, (name: string) => void]

export const useSort = (): SortTuple => {
  const [sortField, setSortField] = useState<string>()
  const [direction, setDirection] = useState<Direction>()

  const sortCallback = useCallback(
    (name: string) => {
      const descRegEx = new RegExp(`^-${name}`)
      const ascRegEx = new RegExp(`^${name}`)
      let newSortField
      let direction
      if (!sortField) {
        newSortField = name
        direction = 'asc'
      } else if (sortField.match(descRegEx)) {
        newSortField = sortField.replace(`-${name}`, name)
        direction = 'asc'
      } else if (sortField.match(ascRegEx)) {
        newSortField = sortField.replace(name, `-${name}`)
        direction = 'desc'
      } else {
        newSortField = name
        direction = 'asc'
      }
      setSortField(newSortField)
      setDirection(direction as Direction)
    },
    
    [sortField, setSortField, setDirection]
  )

  return [sortField, direction, sortCallback]
}
